import styled, { css } from 'styled-components';
import App from './App';
import App2 from './App2';
import App3 from './App3';
import App4 from './App4';
import About from './About';
import Overworld from './Overworld';
import { useState, Suspense, useContext } from 'react';
import logo from './SageNetwhite.png';
import icon3 from './hudicons/sset3.png';
import icon1 from './hudicons/sset4.png';
import icon2 from './hudicons/sset5.png';
import arrow from './hudicons/arrow.png';
import { Circle, Text } from '@react-three/drei';
import * as THREE from 'three';
import futura from './Futura Bold.otf';
import toggle from './hudicons/toggle.jpg';
import threesixty from './hudicons/360.jpg';
import { Context } from './Context';

const Header = styled.div`
  position: fixed;
  width: 100%;
  background-color: #133a5f;
  display: flex;
  z-index: 3;
  padding-top: 1%;
  padding-bottom: 1%;
  @media (max-width: 1020px), (max-height: 720px) {
    padding: 0.4em 0;
  }
`;

const Item = styled.div`
  font-family: 'Arial';
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 1%;
  padding-right: 1%;
  font-size: 1.3rem;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  background-color: ${(props) => (props.active ? '#ff9a00' : 'initial')};
  &:hover {
    cursor: pointer;
    background-color: #ff9a00;
  }
  @media (max-width: 1020px), (max-height: 720px) {
    font-size: 1rem;
  }
`;
const Bar = styled.div`
  width: 2px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #51aab1;
`;
const Empty = styled.div`
  flex-grow: 1;
  background-color: 'red';
`;
const Footer = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #133a5f;
  z-index: 3;
  @media (max-height: 720px) {
    height: 5em;
  }
`;

const Simg = styled.img`
  margin-left: 2%;
  margin-right: 3%;
  height: 8rem;
  @media (max-height: 720px) {
    height: 5em;
  }
`;

const Sicon = styled.img`
  right: 0;
  height: 8rem;
  @media (max-height: 720px) {
    height: 4.5em;
  }
`;

const Br = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const Bbar = styled.div`
  position: fixed;
  bottom: 6rem;
  width: 100%;
  z-index: 1;
  height: 9rem;
  display: flex;
  padding-left: 20%;
  padding-right: 20%;
  @media (max-height: 720px) {
    bottom: 5rem;
    height: auto;
  }
`;

const Bimg = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  width: 5rem;
  ${(props) =>
    props.right &&
    css`
      transform: translateY(-8px) rotate(290deg);
    `}
  ${(props) =>
    props.drop &&
    css`
      filter: drop-shadow(3px 3px 5px black);
    `}
  @media (max-height: 720px) {
    width: 4rem;
  }
`;

export default function Hud() {
  const [zoom, set] = useState(true);
  const [index, setIndex] = useState(0);
  const [cam, setCam] = useState(false);
  const [scene, setScene] = useState(0);
  const { view, setView } = useContext(Context);
  const mat = new THREE.MeshBasicMaterial({
    color: '#f71d00',
    transparent: true,
    opacity: 0.8,
  });

  function Circ(props) {
    return (
      <>
        <group position={props.position} rotation={[0, Math.PI * 0.7, 0]}>
          <Circle
            material={mat}
            onClick={() => setScene(props.scene)}
            args={[0.7, 30]}
          >
            <Text font={futura} position={[0, 0, 0.1]} fontSize={0.25}>
              {props.text}
            </Text>
          </Circle>
          <Circle material={mat} position={[0, -1.1, 0]} args={[0.15, 20]} />
          <Circle material={mat} position={[0, -1.6, 0]} args={[0.15, 20]} />
          <Circle material={mat} position={[0, -2.1, 0]} args={[0.15, 20]} />
          <Circle material={mat} position={[0, -2.6, 0]} args={[0.15, 20]} />
        </group>
      </>
    );
  }

  return (
    <>
      {view === 'about' && <About />}
      <Header>
        {scene === 1 && (
          <>
            <Item
              onClick={() => {
                setIndex(0);
                setCam(false);
              }}
              active={index === 0}
            >
              C-Store
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(1);
                setCam(false);
              }}
              active={index === 1}
            >
              Forecourt
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(2);
                setCam(false);
              }}
              active={index === 2}
            >
              Food Service
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(3);
                setCam(false);
              }}
              active={index === 3}
            >
              POP
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(4);
                setCam(false);
              }}
              active={index === 4}
            >
              Drive Thru
            </Item>
          </>
        )}
        {scene === 2 && (
          <>
            <Item
              onClick={() => {
                setIndex(0);
                setCam(false);
              }}
              active={index === 0}
            >
              Overview
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(1);
                setCam(false);
              }}
              active={index === 1}
            >
              Curbside
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(2);
                setCam(false);
              }}
              active={index === 2}
            >
              Front of House
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(3);
                setCam(false);
              }}
              active={index === 3}
            >
              Drive Thru
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(4);
                setCam(false);
              }}
              active={index === 4}
            >
              Back of House
            </Item>
          </>
        )}
        {scene === 3 && (
          <>
            <Item
              onClick={() => {
                setIndex(0);
                setCam(false);
              }}
              active={index === 0}
            >
              Overview
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(1);
                setCam(false);
              }}
              active={index === 1}
            >
              Curbside
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(2);
                setCam(false);
              }}
              active={index === 2}
            >
              In Branch
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(3);
                setCam(false);
              }}
              active={index === 3}
            >
              Drive Thru
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(4);
                setCam(false);
              }}
              active={index === 4}
            >
              Back of House
            </Item>
          </>
        )}
        {scene === 4 && (
          <>
            <Item
              onClick={() => {
                setIndex(0);
                setCam(false);
              }}
              active={index === 0}
            >
              Overview
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(1);
                setCam(false);
              }}
              active={index === 1}
            >
              Curbside
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(2);
                setCam(false);
              }}
              active={index === 2}
            >
              Point of Sale
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(3);
                setCam(false);
              }}
              active={index === 3}
            >
              Merchandise
            </Item>
            <Bar />
            <Item
              onClick={() => {
                setIndex(4);
                setCam(false);
              }}
              active={index === 4}
            >
              Back of House
            </Item>
          </>
        )}

        <Empty />
        <Item
          onClick={() => {
            setScene(1);
            setIndex(0);
            setCam(false);
            setView('scene');
          }}
          active={scene === 1}
        >
          C-Store
        </Item>
        <Bar />
        <Item
          onClick={() => {
            setScene(2);
            setIndex(0);
            setCam(false);
            setView('scene');
          }}
          active={scene === 2}
        >
          QSR
        </Item>
        <Bar />
        <Item
          onClick={() => {
            setScene(3);
            setIndex(0);
            setCam(false);
            setView('scene');
          }}
          active={scene === 3}
        >
          Financial
        </Item>
        <Bar />
        <Item
          onClick={() => {
            setScene(4);
            setIndex(0);
            setCam(false);
            setView('scene');
          }}
          active={scene === 4}
        >
          Retail
        </Item>
      </Header>
      <Footer>
        <Simg src={logo} />

        <Item
          onClick={() => {
            setView('scene');
            setScene(0);
            setIndex(0);
          }}
        >
          Home
        </Item>
        <Bar />
        <Item
          onClick={() =>
            window.open('https://www.sagenet.com/marketing/virtual/', '_blank')
          }
        >
          Contact Us
        </Item>
        <Bar />
        <Item
          onClick={() => {
            setView('about');
            setScene(0);
          }}
        >
          About Us
        </Item>

        <Br>
          <Sicon src={icon1} />
          <Sicon src={icon2} />
          <Sicon src={icon3} />
        </Br>
      </Footer>

      {scene === 0 && (
        <Suspense fallback={null}>
          <Overworld
            obj={
              <group>
                <Circ scene={3} position={[2.5, 4, 1.5]} text={'FINANCE'} />
                <Circ scene={4} position={[0, 4, 1.5]} text={'RETAIL'} />
                <Circ scene={2} position={[0, 4, -1.5]} text={'QSR'} />
                <Circ scene={1} position={[0, 3.8, -5]} text={'C-STORE'} />
              </group>
            }
            zoom={zoom}
            cam={cam}
          />
        </Suspense>
      )}
      {scene === 1 && (
        <>
          <App index={index} zoom={zoom} cam={cam} />

          <Bbar>
            <Bimg
              src={arrow}
              onClick={() => {
                setIndex(index - 1 === -1 ? 4 : index - 1);
                setCam(false);
              }}
            />
            <Empty />
            <Bimg
              drop
              src={cam ? toggle : threesixty}
              onClick={() => setCam(!cam)}
            />
            <Empty />
            <Bimg
              src={arrow}
              onClick={() => {
                setIndex((index + 1) % 5);
                setCam(false);
              }}
            />
          </Bbar>
        </>
      )}
      {scene === 2 && (
        <>
          <App2 index={index} zoom={zoom} cam={cam} />

          <Bbar>
            <Bimg
              src={arrow}
              onClick={() => {
                setIndex(index - 1 === -1 ? 4 : index - 1);
                setCam(false);
              }}
            />
            <Empty />
            <Bimg
              drop
              src={cam ? toggle : threesixty}
              onClick={() => setCam(!cam)}
            />
            <Empty />
            <Bimg
              src={arrow}
              onClick={() => {
                setIndex((index + 1) % 5);
                setCam(false);
              }}
            />
          </Bbar>
        </>
      )}
      {scene === 3 && (
        <>
          <App3 index={index} zoom={zoom} cam={cam} />

          <Bbar>
            <Bimg
              src={arrow}
              onClick={() => {
                setIndex(index - 1 === -1 ? 4 : index - 1);
                setCam(false);
              }}
            />
            <Empty />
            <Bimg
              drop
              src={cam ? toggle : threesixty}
              onClick={() => setCam(!cam)}
            />
            <Empty />
            <Bimg
              src={arrow}
              onClick={() => {
                setIndex((index + 1) % 5);
                setCam(false);
              }}
            />
          </Bbar>
        </>
      )}
      {scene === 4 && (
        <>
          <App4 index={index} zoom={zoom} cam={cam} />

          <Bbar>
            <Bimg
              src={arrow}
              onClick={() => {
                setIndex(index - 1 === -1 ? 5 : index - 1);
                setCam(false);
              }}
            />
            <Empty />
            <Bimg
              drop
              src={cam ? toggle : threesixty}
              onClick={() => setCam(!cam)}
            />
            <Empty />
            <Bimg
              src={arrow}
              onClick={() => {
                setIndex((index + 1) % 6);
                setCam(false);
              }}
            />
          </Bbar>
        </>
      )}
    </>
  );
}
