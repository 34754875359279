import React, { useRef, Suspense, useState } from 'react';
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber';
import Base from './Floor';
import Worker from './GasModels/Dworker';
import './App.css';
import * as THREE from 'three';
import { Sky } from '@react-three/drei';
import Cata from './QSR/Cata_QSR';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Soda from './GasModels/Dsoda';
import Fries from './GasModels/Dfries';
import Burger from './GasModels/Dburger';
import Load from './LoadingAni';
import Qsr from './QSR/Dqsr';
import Server from './QSR/Serverrack';
import Lot from './QSR/Lot';

extend({ OrbitControls });
const CameraControls = (props) => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  let Look = [0, 3, -25];
  let targ = new THREE.Vector3();

  switch (props.index) {
    case 1:
      Look = [-3.82, 2.0, 3.87];

      break;
    case 2:
      Look = [-3.6, 2, -3.4];
      break;
    case 3:
      Look = [10, 1.8, -11.07];
      break;
    case 4:
      Look = [2, 1.6, 2.89];
      break;
    default:
      Look = [1.4, 3, -6.39];

      break;
  }

  targ.set(Look[0], Look[1], Look[2]);

  const {
    camera,
    gl: { domElement },
  } = useThree();
  // const targ = new THREE.Vector3(Look[0], Look[1], Look[2]);
  // Ref to the controls, so that we can update them on every frame with useFrame
  const controls = useRef();
  //camera.lookAt([0,0,0])
  //controls.current.update();

  useFrame(() => (controls.current ? controls.current.update() : null));
  //useFrame(() =>console.log(controls.current.object));
  return (
    <orbitControls
      target={targ}
      ref={controls}
      args={[camera, domElement]}
      //autoRotate={true}
      enableZoom={true}
      maxPolarAngle={Math.PI * 0.5}
      maxDistance={50}
      screenSpacePanning={false}
    />
  );
};

const dummy = new THREE.Vector3();
const ldummy = new THREE.Vector3();
const target = new THREE.Vector3(0, 0, 0);

function Dolly(props) {
  //props.gate ? 0 : 3, props.gate ? 3 : 2, props.gate ? 25 : 10
  //target.lerp(ldummy.set(props.gate ? 0 : 4, props.gate ? 3 : 2, props.gate ? -25 : 5), 0.1);
  let Pos = [0, 3, 25];
  let Look = [0, 3, -25];
  //console.log("Dolly: " + props.index)

  useFrame((state) => {
    switch (props.index) {
      case 1:
        Pos = [-16.59, 2.46, 18.8];
        Look = [-3.82, 2.0, 3.87];
        break;
      case 2:
        Pos = [-10.3, 3.88, -3.38];
        Look = [-3.6, 2, -3.4];
        break;
      case 3:
        Pos = [13.98, 1.8, -11.31];
        Look = [9.55, 1.8, -5.44];
        break;
      case 4:
        Pos = [11.18, 3.28, -5.316];
        Look = [2, 1.6, 2.89];
        break;
      default:
        Pos = [-29.84, 3.57, 18.11];

        Look = [1.4, 3, -6.39];

        break;
    }

    //console.log(Pos);

    state.camera.position.lerp(dummy.set(Pos[0], Pos[1], Pos[2]), 0.05);
    target.lerp(ldummy.set(Look[0], Look[1], Look[2]), 0.05);
    state.camera.lookAt(target);
    state.camera.updateProjectionMatrix();
  });
  return null;
}

function App(props) {
  //Scene();
  const [cam, setCam] = useState(false);
  const [visible, setVisible] = useState(true);

  setTimeout(() => {
    setVisible(false);
  }, 1000);

  return (
    <>
      {visible && <Load z={2} />}

      <Canvas
        colorManagement={false}
        gl={{ antialias: true }}
        shadowMap={true}
        camera={{ fov: 50, near: 2, position: [0, 3, 25] }}
        className="canvas"
      >
        <fog attach="fog" args={['#ffffff', 0, 90]} />

        {props.cam ? (
          <CameraControls index={props.index} cam={props.cam} />
        ) : (
          <Dolly index={props.index} gate={cam} />
        )}
        {/*<CameraControls2 index={props.index} cam={props.cam} /> */}

        <directionalLight
          castShadow
          shadowMap={true}
          shadowBias={-0.00005}
          shadow-mapSize-height={1024}
          shadow-mapSize-width={1024}
          intensity={1}
          position={[-20, 10, 20]}
        />
        <hemisphereLight
          color={'lightblue'}
          groundColor={'white'}
          intensity={0.7}
        />
        <pointLight
          shadowBias={-0.0001}
          position={[-5, 3.5, -2]}
          intensity={1}
          distance={17}
          decay={2}
        />
        <pointLight
          shadowBias={-0.0001}
          position={[-8, 2.8, 0]}
          intensity={0.7}
          distance={17}
          decay={2}
        />
        <pointLight
          shadowBias={-0.0001}
          position={[7, 3.5, -2]}
          intensity={1}
          distance={17}
          decay={2}
        />

        <mesh
          castShadow
          receiveShadow
          visible={false}
          rotation={[-3.14 / 2, 0, 0]}
          position={[0, 0, 0]}
          scale={[100, 100, 1]}
        >
          <planeBufferGeometry />
          <meshStandardMaterial color="grey" />
        </mesh>
        <Suspense fallback={null}>
          <Worker
            rotation={[0, -Math.PI * 0.5, 0]}
            position={[-1, 0.31, -3.9]}
            scale={[1.2, 1.2, 1.2]}
          />
          <Qsr
            scale={[0.014, 0.014, 0.014]}
            rotation={[0, Math.PI, 0]}
            position={[-2, -0.2, -1]}
          />
          <Base />
          <Lot
            scale={[0.4, 0.4, 0.4]}
            position={[1.4, 0, 1.8]}
            rotation={[0, Math.PI, 0]}
          />
          <Server
            rotation={[0, Math.PI, 0]}
            scale={[0.5, 0.5, 0.5]}
            position={[8.8, 0.3, 3.5]}
          />

          <group rotation={[0, -Math.PI, 0]} position={[-4, 0, -2]}>
            {[...Array(4)].map((d, i) => (
              <Fries
                key={i}
                position={[-3.9, 2.09, i * 0.3 - 1.5]}
                scale={[0.25, 0.25, 0.25]}
                rotation={[0, Math.PI + i * -0.1, 0]}
              />
            ))}
            {[...Array(4)].map((d, i) => (
              <Burger
                key={i}
                position={[-3.9, 2.6, i * 0.3 - 1.5]}
                scale={[0.2, 0.2, 0.2]}
                rotation={[0, i, 0]}
              />
            ))}

            <Soda
              position={[-3.7, 1.35, 0.4]}
              scale={[0.58, 0.58, 0.58]}
              rotation={[0, Math.PI * 0.5, 0]}
            />
          </group>
          {props.index === 1 && (
            <Cata
              index={props.index}
              position={[-14.9, 3.4, 14]}
              rotation={[0, Math.PI * 1.775, 0]}
              seen={props.index === 1}
            />
          )}
          {props.index === 2 && (
            <group rotation={[0, 0, -0.24]}>
              <Cata
                index={props.index}
                position={[-6.8, 2.2, -5.25]}
                rotation={[Math.PI * 2, -Math.PI * 0.5, Math.PI * 2]}
                seen={props.index === 2}
              />
            </group>
          )}
          {props.index === 3 && (
            <Cata
              index={props.index}
              position={[12.85, 2.8, -6.81]}
              rotation={[0, Math.PI * 0.79, 0]}
              seen={props.index === 3}
            />
          )}
          {props.index === 4 && (
            <group rotation={[0.09, 0, 0.1]}>
              <Cata
                index={props.index}
                position={[8.9, 2.7, -1]}
                rotation={[0, Math.PI * 0.73, 0]}
                seen={props.index === 4}
              />
            </group>
          )}
          <Sky
            sunPosition={[0, 10, 0]}
            turbidity={0.6}
            rayleigh={0.1}
            mieCoefficient={0.4}
            mieDirectionalG={0.75}
          />
        </Suspense>
      </Canvas>
    </>
  );
}

export default App;
