import React, { useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import Sv from '../sagelabel/sv.jpg';
import Ss from '../sagelabel/ss.jpg';
import Sc from '../sagelabel/sc.jpg';
import Siw from '../sagelabel/siw.jpg';
import { Vector2 } from 'three';
import POS from './POS';
import Merchandise from './Merchandise';
import Inventory from './Inventory';
import Curbside from './Curbside';

export default function Cata(props) {
  const group = useRef();
  const [active, setActive] = useState(0);
  const [bang, setBang] = useState(true);

  function Label(props) {
    const [hover, setHover] = useState(false);
    const tex = useTexture(props.file);
    tex.center = new Vector2(0.5, 0.5);
    tex.repeat = new Vector2(1.5, 1.5);

    return (
      <mesh
        visible={true}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        onClick={() => setActive(props.index)}
        scale={props.scale}
        position={props.position}
      >
        <planeBufferGeometry />
        <meshBasicMaterial
          map={tex}
          attach="material"
          color={hover ? 'orange' : 'white'}
          transparent={true}
          opacity={0.8}
        />
      </mesh>
    );
  }

  return (
    <group
      position={props.position}
      rotation={props.rotation}
      visible={props.seen}
      ref={group}
    >
      <group position={[0, -3, 0]}>
        {props.index === 1 && (
          <Curbside hide={(a) => setBang(a)} hidden={bang} index={active} />
        )}
        {props.index === 2 && (
          <POS hide={(a) => setBang(a)} hidden={bang} index={active} />
        )}
        {props.index === 3 && (
          <Merchandise hide={(a) => setBang(a)} hidden={bang} index={active} />
        )}
        {props.index === 4 && (
          <Inventory hide={(a) => setBang(a)} hidden={bang} index={active} />
        )}
      </group>
      <group visible={bang}>
        <Label
          index={0}
          file={Sv}
          scale={[1.1, 0.35, 1]}
          position={[-0.505, 0, 0.01]}
        />
        <Label
          index={1}
          file={Ss}
          scale={[1.4, 0.35, 1]}
          position={[0.74, 0, 0]}
        />
        <Label
          index={3}
          file={Siw}
          scale={[2.2, 0.35, 1]}
          position={[4.042, 0, 0]}
        />
        <Label
          index={2}
          file={Sc}
          scale={[1.5, 0.35, 1]}
          position={[2.19, 0, 0.01]}
        />
      </group>
    </group>
  );
}
