/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import cstore from './dnewcstore.glb';

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(cstore);
  for (const property in materials) {
    materials[property].metalness = 0.2;
    materials[property].roughness = 0.2;
    //console.log(materials[property].metalness);
  }
  materials['Glass.002'].transparent = true;
  materials['Glass.002'].opacity = 0.1;
  materials['Glass.002'].roughness = 0.2;
  materials['Glass.002'].metalness = 0.5;

  nodes.Mesh060_4.material.roughness = 1;
  nodes.Mesh060_4.material.metalness = 0.3;
  nodes.Mesh060_4.material.color.set('rgb(200,200,200)');

  materials['Plastic_Shiny.001'].color.set('rgb(100,100,100)');

  nodes.Mesh002_13.material.roughness = 1;

  nodes.Mesh002_13.material.metalness = 0.3;

  nodes.Mesh002_13.material.color.set('rgb(200,200,200)');
  nodes.Cube004_6.material.color.set('rgb(100,100,100)');
  nodes.Mesh060_3.material.color.set('rgb(100,100,100)');

  nodes.Mesh060_2.material.roughness = 0.4;
  nodes.Mesh060_2.material.metalness = 0.5;
  nodes.Cube004_5.material.color.set('#00378D');

  // materials['signs.002'].transparent = true;
  // materials['signs.002'].alphaMap = useTexture(icon);
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        position={[-7.0026, 0.6329, -11.1493]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.01, 0.01, 0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh289.geometry}
          material={materials['Plastic_Shiny.002']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh289_1.geometry}
          material={materials['plastic black.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh289_2.geometry}
          material={materials['Stainless_Steel.002']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh289_3.geometry}
          material={materials['orange.001']}
        />
      </group>
      <group
        position={[-7.2592, 2.402, -11.8342]}
        scale={[0.0398, 0.3579, 0.6362]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube026.geometry}
          material={nodes.Cube026.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube026_1.geometry}
          material={nodes.Cube026_1.material}
        />
      </group>
      <group
        position={[-7.2592, 2.402, -10.5742]}
        scale={[0.0398, 0.3579, 0.6362]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube027.geometry}
          material={nodes.Cube027.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube027_1.geometry}
          material={nodes.Cube027_1.material}
        />
      </group>
      <group
        position={[-7.2592, 2.402, -9.3142]}
        scale={[0.0398, 0.3579, 0.6362]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028.geometry}
          material={nodes.Cube028.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028_1.geometry}
          material={nodes.Cube028_1.material}
        />
      </group>
      <mesh
        geometry={nodes.Rectangle137.geometry}
        material={nodes.Rectangle137.material}
        position={[-1.7896, 0.0802, -10.2803]}
        scale={[0.0098, 0.0098, 0.0098]}
      />
      <mesh
        geometry={nodes.Shape001.geometry}
        material={materials['Material #1431659627']}
        position={[-1.7896, 0.0802, -10.2803]}
        scale={[0.0098, 0.0098, 0.0098]}
      />
      <group
        position={[25.0899, 0.0059, 31.6973]}
        rotation={[0, -1.5705, 0]}
        scale={[0.0093, 0.0094, 0.0093]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006.geometry}
          material={materials.CarWashDoors}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_1.geometry}
          material={nodes.Mesh006_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_2.geometry}
          material={nodes.Mesh006_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_3.geometry}
          material={nodes.Mesh006_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_4.geometry}
          material={materials.BrickWall}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_5.geometry}
          material={nodes.Mesh006_5.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_6.geometry}
          material={nodes.Mesh006_6.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_7.geometry}
          material={nodes.Mesh006_7.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_8.geometry}
          material={nodes.Mesh006_8.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh006_9.geometry}
          material={nodes.Mesh006_9.material}
        />
      </group>
      <group position={[-1.1368, 0.04, 1.7651]} scale={[0.01, 0.01, 0.01]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh037.geometry}
          material={nodes.Mesh037.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh037_1.geometry}
          material={nodes.Mesh037_1.material}
        />
      </group>
      <group position={[3.977, 0.1, -0.9996]} scale={[0.01, 0.01, 0.01]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh052.geometry}
          material={nodes.Mesh052.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh052_1.geometry}
          material={nodes.Mesh052_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh052_2.geometry}
          material={nodes.Mesh052_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh052_3.geometry}
          material={nodes.Mesh052_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh052_4.geometry}
          material={nodes.Mesh052_4.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh052_5.geometry}
          material={nodes.Mesh052_5.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh052_6.geometry}
          material={nodes.Mesh052_6.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh052_7.geometry}
          material={nodes.Mesh052_7.material}
        />
      </group>
      <group
        position={[-6.4368, 4.7, -1.5349]}
        rotation={[0, -1.5705, 0]}
        scale={[0.01, 0.01, 0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060.geometry}
          material={nodes.Mesh060.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_1.geometry}
          material={nodes.Mesh060_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_2.geometry}
          material={nodes.Mesh060_2.material}
        />{' '}
        {/*blue top*/}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_3.geometry}
          material={nodes.Mesh060_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_4.geometry}
          material={nodes.Mesh060_4.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_5.geometry}
          material={nodes.Mesh060_5.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_6.geometry}
          material={materials['Light frame']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_7.geometry}
          material={nodes.Mesh060_7.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_8.geometry}
          material={materials.metal2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_9.geometry}
          material={materials.Light}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh060_10.geometry}
          material={materials['Light.002']}
        />
      </group>
      <group position={[4.0059, 1.79, 4.5365]} scale={[0.38, 0.1, 0.14]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={nodes.Cube004.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_1.geometry}
          material={materials.GasPumpPromo}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_2.geometry}
          material={nodes.Cube004_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_3.geometry}
          material={nodes.Cube004_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_4.geometry}
          material={nodes.Cube004_4.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_5.geometry}
          material={nodes.Cube004_5.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_6.geometry}
          material={nodes.Cube004_6.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_7.geometry}
          material={materials.GasPumpKioskScreen}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_8.geometry}
          material={nodes.Cube004_8.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_9.geometry}
          material={nodes.Cube004_9.material}
        />
      </group>
      <group
        position={[5.9, 0.54, -13.58]}
        rotation={[Math.PI / 2, 0, 0.356]}
        scale={[0.02, 0.01, 0.02]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Order_menu.geometry}
          material={nodes.Order_menu.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Order_menu_1.geometry}
          material={materials.Speaker}
        />
      </group>
      <mesh
        geometry={nodes.portrait_promotional_stand.geometry}
        material={nodes.portrait_promotional_stand.material}
        position={[5.94, 0.54, -6.8]}
        rotation={[Math.PI / 2, 0, 1.0018]}
        scale={[0.02, 0.01, 0.02]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh003.geometry}
        material={nodes.Mesh003.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh003_1.geometry}
        material={nodes.Mesh003_1.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002.geometry}
        material={nodes.Mesh002.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_1.geometry}
        material={nodes.Mesh002_1.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_2.geometry}
        material={nodes.Mesh002_2.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_3.geometry}
        material={materials['Glass.002']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_4.geometry}
        material={nodes.Mesh002_4.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_5.geometry}
        material={nodes.Mesh002_5.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_6.geometry}
        material={nodes.Mesh002_6.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_7.geometry}
        material={materials.AirConditioner}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_8.geometry}
        material={materials.Vent}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_9.geometry}
        material={nodes.Mesh002_9.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_10.geometry}
        material={nodes.Mesh002_10.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_11.geometry}
        material={nodes.Mesh002_11.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_12.geometry}
        material={nodes.Mesh002_12.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_13.geometry}
        material={nodes.Mesh002_13.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_14.geometry}
        material={materials.Tiles}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_15.geometry}
        material={materials.CardInfo}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh002_16.geometry}
        material={materials['Light.001']}
      />
      <group
        position={[3.3767, 0.1559, -11.9621]}
        rotation={[Math.PI, 1.5705, 0]}
        scale={[-0.01, -0.01, -0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154.geometry}
          material={materials['Chrome_Slightly_Worn.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154_1.geometry}
          material={nodes.Mesh154_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154_2.geometry}
          material={materials['orange.003']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154_3.geometry}
          material={materials['Plastic_Shiny.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154_4.geometry}
          material={nodes.Mesh154_4.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154_5.geometry}
          material={materials['screen.006']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154_6.geometry}
          material={materials['Material #1189']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh154_7.geometry}
          material={materials['Rubber_clean.001']}
        />
      </group>
      <group
        position={[-0.4042, 0.0752, -13.4588]}
        scale={[0.0089, 0.0089, 0.0089]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh224.geometry}
          material={materials['soda.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh224_1.geometry}
          material={materials['fridge int']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh224_2.geometry}
          material={nodes.Mesh224_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh224_3.geometry}
          material={nodes.Mesh224_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh224_4.geometry}
          material={nodes.Mesh224_4.material}
        />
      </group>
      <group
        position={[-6.0727, 2.0816, -13.6401]}
        rotation={[0.0873, Math.PI / 2, 0]}
        scale={[0.0701, 0.6306, 1.121]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003.geometry}
          material={nodes.Cube003.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003_1.geometry}
          material={nodes.Cube003_1.material}
        />
      </group>
      <group
        position={[-3.3712, 2.0816, -13.6401]}
        rotation={[0.0873, Math.PI / 2, 0]}
        scale={[0.0701, 0.6306, 1.121]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={nodes.Cube002.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002_1.geometry}
          material={nodes.Cube002_1.material}
        />
      </group>
      <group
        position={[2.1223, 2.0816, -13.6401]}
        rotation={[0.0873, Math.PI / 2, 0]}
        scale={[0.0701, 0.6306, 1.121]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022.geometry}
          material={nodes.Cube022.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022_1.geometry}
          material={nodes.Cube022_1.material}
        />
      </group>
      <group
        position={[5.0423, 2.0816, -10.2933]}
        scale={[0.0701, 0.6306, 1.121]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023.geometry}
          material={nodes.Cube023.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_1.geometry}
          material={nodes.Cube023_1.material}
        />
      </group>
    </group>
  );
}

useGLTF.preload(cstore);
