import styled, { css } from 'styled-components';
import logo from './SageNetblue2.png';
import welcome from './ImageAssets/welcometo.png';
import { useContext } from 'react';
import { Context } from './Context';

const Whitebg = styled.div`
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Shift = styled.div`
  position: fixed;
  top: 15%;
  width: 100%;
  @media (max-height: 720px) {
    top: 12%;
  }
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: #f8981c;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const Fcol = styled.div`
  display: flex;
  flex-direction: column;
  top: 25%;
`;

const Simg = styled.img`
  width: 25%;
  height: auto;
  margin: 0 auto 2em;
  ${(props) =>
    props.welcome &&
    css`
      width: 35%;
      margin-bottom: 3em;
    `}
  @media (max-height: 720px) {
    margin-bottom: 1em;
  }
`;

const Enter = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 1.25rem;
  font-family: 'Arial';
  color: white;
  border: none;
  font-weight: bold;
  margin: 1em auto 0;
  background-color: #f8981c;
  &:hover {
    cursor: pointer;
  }
  @media (max-height: 720px) {
    font-size: 1rem;
    height: 5em;
    width: 5em;
    margin-top: 0.5em;
  }
`;

export default function Splash() {
  const { view, setView } = useContext(Context);

  return (
    <>
      {view === 'splash' && (
        <Whitebg>
          <Shift>
            <Fcol>
              <Simg src={logo} />
              <Simg welcome src={welcome} />
              <Dot />
              <Dot />
              <Dot />
              <Dot />
              <Enter onClick={() => setView('scene')}>ENTER</Enter>
            </Fcol>
          </Shift>
        </Whitebg>
      )}
    </>
  );
}
