/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import file from './floor.glb'
import ntiles from './asphalt_normal.jpg'
import * as THREE from 'three';

export default function Base(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(file)


  const ntex = useTexture(ntiles);
  ntex.wrapS = 1;
  ntex.wrapT = 1;
  materials['Material #1431659089'].normalMap = ntex;
  materials['Material #1431659089'].metalness = 0.2;
  materials['Material #1431659089'].roughness = 0.4;
  materials['Material #1431659089'].normalScale = new THREE.Vector2(5,5);
  
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh receiveShadow
        material={materials['Material #1431659089']}
        geometry={nodes.Rectangle068.geometry}
        position={[0, 0, 9.88]}
        scale={[0.01, 0.01, 0.01]}
      />
    </group>
  )
}

useGLTF.preload(file)
