import React, {  useState } from 'react'


export default function Screen(props){
  
    const [video] = useState(() => {
      const vid = document.createElement("video");
      vid.src = props.url;
      vid.crossOrigin = "Anonymous";
      vid.loop = true;
      vid.muted = "muted";
      vid.play();
      return vid;
    });
  
    return(
      <mesh  scale={props.scale} rotation={props.rotation} position={props.position}>
        <planeBufferGeometry args={props.pbs} />
        <meshBasicMaterial>
        <videoTexture attach="map" args={[video]} />
        </meshBasicMaterial>
    </mesh>
    );
  
  }