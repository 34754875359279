import React, { useRef, Suspense, useState } from 'react';
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber';
import './App.css';
import * as THREE from 'three';
import { Sky } from '@react-three/drei';
import Cata from './Bank/Cata_Bank';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Welcome from './Welcome';
import Load from './LoadingAni';
import Qsr from './Bank/Dsagebank';
import Server from './QSR/Serverrack';

extend({ OrbitControls });
const CameraControls = (props) => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  let Look = [0, 3, -25];
  let targ = new THREE.Vector3();

  switch (props.index) {
    case 1:
      Look = [6.92, 2.0, -3.6];

      break;
    case 3:
      Look = [11.58, 2, 3.97];
      break;
    case 2:
      Look = [1.11, 1.5, 5.66];
      break;
    case 4:
      Look = [-5.02, 1.8, 14.15];
      break;
    default:
      Look = [-5.93, 3.11, 0.07];

      break;
  }

  targ.set(Look[0], Look[1], Look[2]);

  const {
    camera,
    gl: { domElement },
  } = useThree();
  // const targ = new THREE.Vector3(Look[0], Look[1], Look[2]);
  // Ref to the controls, so that we can update them on every frame with useFrame
  const controls = useRef();
  //camera.lookAt([0,0,0])
  //controls.current.update();

  useFrame(() => (controls.current ? controls.current.update() : null));
  //useFrame(() => console.log(controls.current.object.position));
  return (
    <orbitControls
      target={targ}
      ref={controls}
      args={[camera, domElement]}
      //autoRotate={true}
      enableZoom={true}
      maxPolarAngle={Math.PI * 0.5}
      maxDistance={50}
      screenSpacePanning={false}
    />
  );
};

const dummy = new THREE.Vector3();
const ldummy = new THREE.Vector3();
const target = new THREE.Vector3(0, 0, 0);

function Dolly(props) {
  //props.gate ? 0 : 3, props.gate ? 3 : 2, props.gate ? 25 : 10
  //target.lerp(ldummy.set(props.gate ? 0 : 4, props.gate ? 3 : 2, props.gate ? -25 : 5), 0.1);
  let Pos = [0, 3, 25];
  let Look = [0, 3, -25];
  //console.log("Dolly: " + props.index)

  useFrame((state) => {
    switch (props.index) {
      case 1:
        Pos = [-21.08, 1.2, -3.33];
        Look = [6.92, 2.0, -3.6];
        break;
      case 3:
        Pos = [15, 2.44, 7];
        Look = [11.58, 2, 3.97];
        break;
      case 2:
        Pos = [-3.86, 4.36, -5.46];
        Look = [1.11, 1.5, 5.66];
        break;
      case 4:
        Pos = [5.19, 2.15, 15.96];
        Look = [-5.02, 1.8, 14.15];
        break;
      default:
        Pos = [-25.46, 9.22, -27.24];

        Look = [-5.93, 3.11, 0.07];

        break;
    }

    //console.log(Pos);

    state.camera.position.lerp(dummy.set(Pos[0], Pos[1], Pos[2]), 0.05);
    target.lerp(ldummy.set(Look[0], Look[1], Look[2]), 0.05);
    state.camera.lookAt(target);
    state.camera.updateProjectionMatrix();
  });
  return null;
}

function App(props) {
  //Scene();
  const [cam, setCam] = useState(false);
  const [visible, setVisible] = useState(true);

  setTimeout(() => {
    setVisible(false);
  }, 1000);

  return (
    <>
      {visible && <Load z={2} />}

      <Canvas
        colorManagement={false}
        gl={{ antialias: true }}
        shadowMap={true}
        camera={{ fov: 50, near: 2, position: [0, 3, 25] }}
        className="canvas"
      >
        <fog attach="fog" args={['#ffffff', 0, 90]} />

        {props.cam ? (
          <CameraControls index={props.index} cam={props.cam} />
        ) : (
          <Dolly index={props.index} gate={cam} />
        )}
        <directionalLight
          castShadow
          shadowMap={true}
          shadowBias={-0.00005}
          shadow-mapSize-height={1024}
          shadow-mapSize-width={1024}
          intensity={1}
          position={[10, 10, -10]}
        />
        <hemisphereLight
          color={'lightblue'}
          groundColor={'white'}
          intensity={0.5}
        />
        <pointLight
          castShadow
          shadowBias={-0.0001}
          position={[-5.02, 3, 13.15]}
          intensity={2}
          distance={27}
          decay={2}
        />
        <pointLight
          castShadow
          shadowMap={true}
          shadowBias={-0.0001}
          position={[0, 2, 0]}
          intensity={1}
          distance={17}
          decay={2}
        />
        <pointLight
          shadowBias={-0.0001}
          position={[7, 3.5, -2]}
          intensity={0}
          distance={17}
          decay={2}
        />

        <mesh
          castShadow
          receiveShadow
          visible={false}
          rotation={[-3.14 / 2, 0, 0]}
          position={[0, 0, 0]}
          scale={[100, 100, 1]}
        >
          <planeBufferGeometry />
          <meshStandardMaterial color="grey" />
        </mesh>
        <Suspense fallback={null}>
          <Qsr rotation={[0, Math.PI, 0]} />

          <Server
            rotation={[0, Math.PI * 2, 0]}
            scale={[0.5, 0.5, 0.5]}
            position={[0.19, 0, 12]}
          />

          {props.index === 1 && (
            <Cata
              index={props.index}
              position={[-16.5, 2.5, -5.2]}
              rotation={[0, -Math.PI * 0.5, 0]}
              seen={props.index === 1}
            />
          )}
          {props.index === 2 && (
            <group rotation={[0.11, 0, -0.05]}>
              <Cata
                index={props.index}
                position={[-0.4, 4.2, -2.2]}
                rotation={[Math.PI * 2, -Math.PI * 0.87, Math.PI * 2]}
                seen={props.index === 2}
              />
            </group>
          )}
          {props.index === 3 && (
            <Cata
              index={props.index}
              position={[10.85, 3.2, 5.61]}
              rotation={[0, Math.PI * 2.27, 0]}
              seen={props.index === 3}
            />
          )}
          {props.index === 4 && (
            <group rotation={[0, 0, 0]}>
              <Cata
                index={props.index}
                position={[0.5, 3.1, 17]}
                rotation={[0, Math.PI * 0.44, 0]}
                seen={props.index === 4}
              />
            </group>
          )}
          <Sky
            sunPosition={[0, 10, 0]}
            turbidity={0.6}
            rayleigh={0.1}
            mieCoefficient={0.4}
            mieDirectionalG={0.75}
          />
          {props.index === 0 && <Welcome index={props.index} />}
        </Suspense>
      </Canvas>
    </>
  );
}

export default App;
