/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import retail from './dnretail.glb';
import { useGLTF, Plane } from '@react-three/drei';
import * as THREE from 'three';
import Screen from '../Screen';
import covid5 from './covid5.webm';
import water from './waterfall-with-a-rainbow.webm';

export default function Model(props) {
  const group = useRef();

  const { nodes, materials } = useGLTF(retail);
  for (const property in materials) {
    materials[property].metalness = 0.08;
    materials[property].roughness = 0.8;
    //console.log(materials[property].metalness);
  }
  materials['Screen.002'].color.set('black');
  nodes.Full_Cash_Counter_obj171_4.material.color.set('white');
  nodes.Full_Cash_Counter_obj171_4.material.visible = true;
  materials['Item Preview'].metalness = 0.1;

  const screenmat = new THREE.MeshBasicMaterial();
  screenmat.map = materials['Item Preview'].map;

  materials['Item Preview'].visible = true;
  materials.VideoWallScreen.visible = true;

  /*nodes.Mesh111_2.material.color.set("rgb(245,245,245)");
  nodes.Mesh111_2.material.roughness = 0.9;
  nodes.Mesh111_2.material.metalness = 0.1;*/
  //console.log(screenmat);
  let hor = new Array(3).fill(0);
  let vert = new Array(3).fill(0);
  return (
    <group ref={group} {...props} dispose={null}>
      <Plane
        args={[5.15, 5]}
        rotation={[0, -Math.PI * 0.5, 0]}
        position={[8.8, 1.6, -9.8]}
      >
        <meshStandardMaterial attach="material" color="rgb(80,80,80)" />
      </Plane>
      <Screen
        url={covid5}
        pbs={[1.77, 1.05]}
        position={[-1.04, 2.92, -7.37]}
        rotation={[0.03, Math.PI * 1, 0]}
        scale={[0.9, 0.9, 1]}
      />

      {vert.map((empty, j) =>
        hor.map((item, i) => (
          <Screen
            key={i+j}
            url={water}
            pbs={[1.77, 1.05]}
            position={[0.48 + i * 1.63, 3.43 - j * 0.99, -6.46]}
            rotation={[0, Math.PI * 2, 0]}
            scale={[0.86, 0.86, 1]}
          />
        ))
      )}
      <group position={[-3.1394, 0, 0.6604]} scale={[0.7398, 0.7398, 0.7398]}>
        <group
          position={[0, 0.1923, -0.0275]}
          rotation={[Math.PI / 2, 0, -3.1346]}
          scale={[0.233, 0.233, 0.233]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_1.geometry}
            material={materials.Skin}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_2.geometry}
            material={nodes.Body_FreeAllSTL005_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_3.geometry}
            material={materials.Lips}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_4.geometry}
            material={materials.Gray}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_5.geometry}
            material={nodes.Body_FreeAllSTL005_5.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_6.geometry}
            material={nodes.Body_FreeAllSTL005_6.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_7.geometry}
            material={materials['LightBlue.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_8.geometry}
            material={materials.Pink}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_9.geometry}
            material={materials.ShoeColor1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_10.geometry}
            material={materials.ShoeColor2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_11.geometry}
            material={materials.ShoeColor3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Body_FreeAllSTL005_12.geometry}
            material={materials.Laces}
          />
          <group
            position={[-1.8635, 1.3369, -5.1671]}
            rotation={[0, 0, -0.7714]}
            scale={[0.1106, 0.1106, 0.1106]}
          >
            <mesh
              geometry={nodes['womens_yoga_pants_tanks(obj)001'].geometry}
              material={nodes['womens_yoga_pants_tanks(obj)001'].material}
            />
            <mesh
              geometry={nodes['womens_yoga_pants_tanks(obj)001_1'].geometry}
              material={nodes['womens_yoga_pants_tanks(obj)001_1'].material}
            />
            <mesh
              geometry={nodes['womens_yoga_pants_tanks(obj)001_2'].geometry}
              material={nodes['womens_yoga_pants_tanks(obj)001_2'].material}
            />
          </group>
        </group>
      </group>
      <group scale={[27, 6.5, 1]} position={[0.8738, 0, -6.9596]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={nodes.Cube002.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002_1.geometry}
          material={nodes.Cube002_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002_2.geometry}
          material={nodes.Cube002_2.material}
        />
      </group>
      <group
        position={[5.6486, 1.0499, -12.8252]}
        rotation={[-Math.PI / 2, 0, -1.5751]}
        scale={[-0.01, -0.01, -0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh009.geometry}
          material={nodes.Mesh009.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh009_1.geometry}
          material={nodes.Mesh009_1.material}
        />
      </group>
      <mesh
        geometry={nodes.Roof.geometry}
        material={nodes.Roof.material}
        position={[0.8753, 5.1504, -5.0818]}
        scale={[0.01, 0.01, 0.01]}
      />
      <group
        position={[-7.6648, 1.4651, 8.861]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.01, 0.01, 0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh038.geometry}
          material={nodes.Mesh038.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh038_1.geometry}
          material={nodes.Mesh038_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh038_2.geometry}
          material={nodes.Mesh038_2.material}
        />
      </group>
      <group
        position={[-7.6223, 1.4004, 3.787]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.01, 0.01, 0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh042.geometry}
          material={nodes.Mesh042.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh042_1.geometry}
          material={nodes.Mesh042_1.material}
        />
      </group>
      <group
        position={[-4.3247, 1.4004, 11.9245]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.01, 0.01, 0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh044.geometry}
          material={nodes.Mesh044.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh044_1.geometry}
          material={nodes.Mesh044_1.material}
        />
      </group>
      <group
        position={[10.3099, 2.5637, -13.3973]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[0.0078, 0.0106, 0.0106]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh046.geometry}
          material={nodes.Mesh046.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh046_1.geometry}
          material={nodes.Mesh046_1.material}
        />
      </group>
      <group
        position={[-7.966, 0.1544, 8.9607]}
        rotation={[0, 1.5705, 0]}
        scale={[0.01, 0.0024, 0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh098.geometry}
          material={nodes.Mesh098.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh098_1.geometry}
          material={nodes.Mesh098_1.material}
        />
      </group>
      <group position={[0.8753, 0.0004, 0.487]} scale={[0.01, 0.01, 0.01]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh099.geometry}
          material={materials.Sidewalk}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh099_1.geometry}
          material={materials.Tiles}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh099_2.geometry}
          material={materials.grass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh099_3.geometry}
          material={materials.Curb}
        />
      </group>
      <mesh
        geometry={nodes.garbage000.geometry}
        material={nodes.garbage000.material}
        position={[-8.1337, 0.1519, 6.1237]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[0.0139, 0.0116, 0.0139]}
      />
      <group
        position={[-7.2998, 5.1504, 0.962]}
        rotation={[0, 0, -Math.PI]}
        scale={[-0.01, -0.01, -0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh111.geometry}
          material={nodes.Mesh111.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh111_1.geometry}
          material={materials.Brick_Mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh111_2.geometry}
          material={nodes.Mesh111_2.material}
        />
      </group>
      <group
        position={[-7.2437, 4.4225, 12.6579]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        scale={[-0.0047, -0.0071, -0.0047]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh119.geometry}
          material={nodes.Mesh119.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh119_1.geometry}
          material={nodes.Mesh119_1.material}
        />
      </group>
      <group
        position={[-8.3971, 4.4225, 1.031]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={[-0.0047, -0.0071, -0.0047]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh127.geometry}
          material={nodes.Mesh127.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh127_1.geometry}
          material={nodes.Mesh127_1.material}
        />
      </group>
      <group
        position={[9.0363, 4.4225, 12.6579]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        scale={[-0.0047, -0.0071, -0.0047]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh131.geometry}
          material={nodes.Mesh131.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh131_1.geometry}
          material={nodes.Mesh131_1.material}
        />
      </group>
      <group
        position={[-4.3281, 3.3074, 12.6581]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={[0.0078, 0.0109, 0.0106]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh137.geometry}
          material={nodes.Mesh137.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh137_1.geometry}
          material={nodes.Mesh137_1.material}
        />
      </group>
      <group
        position={[2.2182, 3.0945, 15.338]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[0.0078, 0.0109, 0.0106]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh143.geometry}
          material={nodes.Mesh143.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh143_1.geometry}
          material={nodes.Mesh143_1.material}
        />
      </group>
      <group position={[6.3253, 2.6504, 11.887]} scale={[0.01, 0.01, 0.01]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh146.geometry}
          material={nodes.Mesh146.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh146_1.geometry}
          material={nodes.Mesh146_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh146_2.geometry}
          material={nodes.Mesh146_2.material}
        />
      </group>
      <mesh
        geometry={nodes.Street.geometry}
        material={materials.asphalt}
        position={[-0.679, 0, 1.6888]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        geometry={nodes.Right_Parking_Spaces.geometry}
        material={nodes.Right_Parking_Spaces.material}
        position={[-15.8707, 0.0001, 16.3395]}
        scale={[0.01, 0.01, 0.01]}
      />
      <group
        position={[1.9676, 0.4851, 14.7848]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.01, 0.01, 0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh152.geometry}
          material={nodes.Mesh152.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh152_1.geometry}
          material={nodes.Mesh152_1.material}
        />
      </group>
      <group
        position={[2.196, -0.0016, 14.3787]}
        scale={[0.0158, 0.01, 0.0158]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh155.geometry}
          material={nodes.Mesh155.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh155_1.geometry}
          material={nodes.Mesh155_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh155_2.geometry}
          material={nodes.Mesh155_2.material}
        />
      </group>
      <mesh
        geometry={nodes.Bike_Space.geometry}
        material={nodes.Bike_Space.material}
        position={[2.1695, 0.0001, 16.1328]}
        scale={[0.01, 0.01, 0.01]}
      />
      <group position={[-0.5889, 0.0004, 14.2954]} scale={[0.01, 0.01, 0.01]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh165.geometry}
          material={nodes.Mesh165.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh165_1.geometry}
          material={nodes.Mesh165_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh165_2.geometry}
          material={nodes.Mesh165_2.material}
        />
      </group>
      <mesh
        geometry={nodes.Handicapped_spot.geometry}
        material={nodes.Handicapped_spot.material}
        position={[-15.8484, -0.0002, 16.4484]}
        scale={[0.01, 0.003, 0.01]}
      />
      <mesh
        geometry={nodes.Front_Parking_spaces.geometry}
        material={nodes.Front_Parking_spaces.material}
        position={[-11.6502, 0.0001, -5.2377]}
        rotation={[0, -1.5705, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <group
        position={[-73.0022, 4.1794, -1.3491]}
        rotation={[0, -1.5705, 0]}
        scale={[0.01, 0.01, 0.01]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh171.geometry}
          material={nodes.Mesh171.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh171_1.geometry}
          material={materials.Light}
        />
      </group>
      <mesh
        geometry={nodes.Handicapped_spot001.geometry}
        material={nodes.Handicapped_spot001.material}
        position={[-18.1923, -0.0002, 16.0845]}
        scale={[0.01, 0.003, 0.01]}
      />
      <group
        position={[6.3099, 1.3773, -1.1442]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[0.002, 0.002, 0.002]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003.geometry}
          material={nodes.Shelves003.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_1.geometry}
          material={materials.Chips_BoldMix}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_2.geometry}
          material={materials.Lays_Classic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_3.geometry}
          material={nodes.Shelves003_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_4.geometry}
          material={materials.Lays_Chile}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_5.geometry}
          material={nodes.Shelves003_5.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_6.geometry}
          material={materials.Lays_SourCream}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_7.geometry}
          material={materials.Green}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_8.geometry}
          material={materials.Lays_OvenBaked}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_9.geometry}
          material={materials.Doritos_Classic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_10.geometry}
          material={materials.Doritos_Cheese}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_11.geometry}
          material={materials.Cheetos_BBQ}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_12.geometry}
          material={materials.Cheetos_Classic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_13.geometry}
          material={materials.Chip_ClassicMix}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_14.geometry}
          material={nodes.Shelves003_14.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_15.geometry}
          material={materials.Pringles_BBQ}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_16.geometry}
          material={materials.Preple}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_17.geometry}
          material={materials.Orange}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_18.geometry}
          material={materials.Pringles_Tortillas}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_19.geometry}
          material={materials.Pringles_SourCream}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_20.geometry}
          material={materials.Pringles_Cheese}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_21.geometry}
          material={nodes.Shelves003_21.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_22.geometry}
          material={materials.orange}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_23.geometry}
          material={materials.trim}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_24.geometry}
          material={materials.PriceTag}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_25.geometry}
          material={nodes.Shelves003_25.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_26.geometry}
          material={materials.NatureValley_Classic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_27.geometry}
          material={materials.Trix}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_28.geometry}
          material={materials.RasinBran}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_29.geometry}
          material={nodes.Shelves003_29.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_30.geometry}
          material={materials.CocoaKrispies}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_31.geometry}
          material={materials.NatureValley_Dark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_32.geometry}
          material={materials.NatureValley_TrailMix}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_33.geometry}
          material={materials.NatureValley_Nut}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_34.geometry}
          material={nodes.Shelves003_34.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_35.geometry}
          material={nodes.Shelves003_35.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_36.geometry}
          material={materials.Mezzani}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_37.geometry}
          material={materials.Pasta_Black}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_38.geometry}
          material={materials.Pasta_spag}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_39.geometry}
          material={materials.DelMonte}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_40.geometry}
          material={materials.Buitoni}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_41.geometry}
          material={materials.Barilla_Plus}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_42.geometry}
          material={nodes.Shelves003_42.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_43.geometry}
          material={materials.Barilla_Blue}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_44.geometry}
          material={materials.Barilla_BlueMix}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_45.geometry}
          material={materials.Pasta_Mix}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_46.geometry}
          material={materials.Pringles_Classic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_47.geometry}
          material={materials.Pringles_Xtra}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_48.geometry}
          material={materials['Black.Hair']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_49.geometry}
          material={materials.RiceKrispies_treat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_50.geometry}
          material={materials.ReesesPuff}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_51.geometry}
          material={materials.Teal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_52.geometry}
          material={materials.CornFlakes}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_53.geometry}
          material={materials.FrostedFlakes}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_54.geometry}
          material={materials.Barilla_Lasgne}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_55.geometry}
          material={materials.Stouffers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Shelves003_56.geometry}
          material={materials.Barilla}
        />
      </group>
      <group
        position={[2.1064, 2.4398, -6.5183]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0437, 0.0437, 0.0437]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Samsung_TV008.geometry}
          material={materials.VideoWallScreen}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Samsung_TV008_1.geometry}
          material={nodes.Samsung_TV008_1.material}
        />
      </group>
      <group
        position={[3.8735, 0.16, -3.1183]}
        scale={[0.0783, 0.9547, 1.9563]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube025.geometry}
          material={nodes.Cube025.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube025_1.geometry}
          material={nodes.Cube025_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube025_2.geometry}
          material={nodes.Cube025_2.material}
        />
      </group>
      <mesh
        geometry={nodes.Laptop_display.geometry}
        material={nodes.Laptop_display.material}
        position={[2.1586, 0.14, 1.7017]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.2, 0.42, 1.8]}
      >
        <group
          position={[-0.6869, 2, -0.5389]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005.geometry}
            material={nodes.Cube005.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_1.geometry}
            material={nodes.Cube005_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_2.geometry}
            material={nodes.Cube005_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_3.geometry}
            material={nodes.Cube005_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_4.geometry}
            material={nodes.Cube005_4.material}
          />
        </group>
        <group
          position={[-0.6869, 2, -0.1913]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004.geometry}
            material={nodes.Cube004.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_1.geometry}
            material={nodes.Cube004_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_2.geometry}
            material={nodes.Cube004_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_3.geometry}
            material={nodes.Cube004_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_4.geometry}
            material={nodes.Cube004_4.material}
          />
        </group>
        <group
          position={[-0.6869, 2, 0.1899]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube023.geometry}
            material={nodes.Cube023.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube023_1.geometry}
            material={nodes.Cube023_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube023_2.geometry}
            material={nodes.Cube023_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube023_3.geometry}
            material={nodes.Cube023_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube023_4.geometry}
            material={nodes.Cube023_4.material}
          />
        </group>
        <group
          position={[-0.6869, 2, 0.5444]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube026.geometry}
            material={nodes.Cube026.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube026_1.geometry}
            material={nodes.Cube026_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube026_2.geometry}
            material={nodes.Cube026_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube026_3.geometry}
            material={nodes.Cube026_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube026_4.geometry}
            material={nodes.Cube026_4.material}
          />
        </group>
        <group
          position={[-0.3064, 2, 0.8549]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[0.9051, 0.6034, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube027.geometry}
            material={nodes.Cube027.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube027_1.geometry}
            material={nodes.Cube027_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube027_2.geometry}
            material={nodes.Cube027_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube027_3.geometry}
            material={nodes.Cube027_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube027_4.geometry}
            material={nodes.Cube027_4.material}
          />
        </group>
        <group
          position={[0.2944, 2, 0.8549]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[0.9051, 0.6034, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028.geometry}
            material={nodes.Cube028.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028_1.geometry}
            material={nodes.Cube028_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028_2.geometry}
            material={nodes.Cube028_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028_3.geometry}
            material={nodes.Cube028_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028_4.geometry}
            material={nodes.Cube028_4.material}
          />
        </group>
        <group
          position={[0.2944, 2, -0.8499]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.9051, 0.6034, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube033.geometry}
            material={nodes.Cube033.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube033_1.geometry}
            material={nodes.Cube033_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube033_2.geometry}
            material={nodes.Cube033_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube033_3.geometry}
            material={nodes.Cube033_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube033_4.geometry}
            material={nodes.Cube033_4.material}
          />
        </group>
        <group
          position={[-0.3065, 2, -0.8499]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.9051, 0.6034, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube034.geometry}
            material={nodes.Cube034.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube034_1.geometry}
            material={nodes.Cube034_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube034_2.geometry}
            material={nodes.Cube034_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube034_3.geometry}
            material={nodes.Cube034_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube034_4.geometry}
            material={nodes.Cube034_4.material}
          />
        </group>
        <group
          position={[0.0113, 2.8613, -0.5431]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.9051, 0.6034, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube035.geometry}
            material={nodes.Cube035.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube035_1.geometry}
            material={nodes.Cube035_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube035_2.geometry}
            material={nodes.Cube035_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube035_3.geometry}
            material={nodes.Cube035_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube035_4.geometry}
            material={nodes.Cube035_4.material}
          />
        </group>
        <group
          position={[-0.3081, 2.8613, -0.3698]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube036.geometry}
            material={nodes.Cube036.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube036_1.geometry}
            material={nodes.Cube036_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube036_2.geometry}
            material={nodes.Cube036_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube036_3.geometry}
            material={nodes.Cube036_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube036_4.geometry}
            material={nodes.Cube036_4.material}
          />
        </group>
        <group
          position={[-0.3081, 2.8613, -0.0101]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube037.geometry}
            material={nodes.Cube037.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube037_1.geometry}
            material={nodes.Cube037_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube037_2.geometry}
            material={nodes.Cube037_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube037_3.geometry}
            material={nodes.Cube037_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube037_4.geometry}
            material={nodes.Cube037_4.material}
          />
        </group>
        <group
          position={[-0.3081, 2.8613, 0.363]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube038.geometry}
            material={nodes.Cube038.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube038_1.geometry}
            material={nodes.Cube038_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube038_2.geometry}
            material={nodes.Cube038_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube038_3.geometry}
            material={nodes.Cube038_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube038_4.geometry}
            material={nodes.Cube038_4.material}
          />
        </group>
        <group
          position={[0.0113, 2.8613, 0.5695]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[0.9051, 0.6034, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039.geometry}
            material={nodes.Cube039.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039_1.geometry}
            material={nodes.Cube039_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039_2.geometry}
            material={nodes.Cube039_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039_3.geometry}
            material={nodes.Cube039_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039_4.geometry}
            material={nodes.Cube039_4.material}
          />
        </group>
        <group
          position={[0.3223, 2.8613, 0.363]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube040.geometry}
            material={nodes.Cube040.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube040_1.geometry}
            material={nodes.Cube040_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube040_2.geometry}
            material={nodes.Cube040_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube040_3.geometry}
            material={nodes.Cube040_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube040_4.geometry}
            material={nodes.Cube040_4.material}
          />
        </group>
        <group
          position={[0.3223, 2.8613, -0.0101]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube041.geometry}
            material={nodes.Cube041.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube041_1.geometry}
            material={nodes.Cube041_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube041_2.geometry}
            material={nodes.Cube041_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube041_3.geometry}
            material={nodes.Cube041_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube041_4.geometry}
            material={nodes.Cube041_4.material}
          />
        </group>
        <group
          position={[0.3223, 2.8613, -0.3698]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.6034, 0.9051, 2.5859]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube042.geometry}
            material={nodes.Cube042.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube042_1.geometry}
            material={nodes.Cube042_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube042_2.geometry}
            material={nodes.Cube042_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube042_3.geometry}
            material={nodes.Cube042_3.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube042_4.geometry}
            material={nodes.Cube042_4.material}
          />
        </group>
      </mesh>
      <group
        position={[0.4346, 0.16, -3.1183]}
        scale={[0.0783, 0.9547, 1.9563]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube043.geometry}
          material={nodes.Cube043.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube043_1.geometry}
          material={nodes.Cube043_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube043_2.geometry}
          material={nodes.Cube043_2.material}
        />
      </group>
      <group
        position={[8.54, 0.14, 0.4835]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh001.geometry}
          material={nodes.Box095_Mesh001.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh001_1.geometry}
          material={nodes.Box095_Mesh001_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh001_2.geometry}
          material={nodes.Box095_Mesh001_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh001_3.geometry}
          material={nodes.Box095_Mesh001_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh001_4.geometry}
          material={nodes.Box095_Mesh001_4.material}
        />
      </group>
      <group
        position={[8.54, 0.14, -1.8305]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh002.geometry}
          material={nodes.Box095_Mesh002.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh002_1.geometry}
          material={nodes.Box095_Mesh002_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh002_2.geometry}
          material={nodes.Box095_Mesh002_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh002_3.geometry}
          material={nodes.Box095_Mesh002_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh002_4.geometry}
          material={nodes.Box095_Mesh002_4.material}
        />
      </group>
      <group
        position={[8.54, 0.14, -4.146]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh003.geometry}
          material={nodes.Box095_Mesh003.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh003_1.geometry}
          material={nodes.Box095_Mesh003_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh003_2.geometry}
          material={nodes.Box095_Mesh003_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh003_3.geometry}
          material={nodes.Box095_Mesh003_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh003_4.geometry}
          material={nodes.Box095_Mesh003_4.material}
        />
      </group>
      <group
        position={[8.54, 0.14, 2.8094]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh224.geometry}
          material={nodes.Box095_Mesh224.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh224_1.geometry}
          material={nodes.Box095_Mesh224_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh224_2.geometry}
          material={nodes.Box095_Mesh224_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh224_3.geometry}
          material={nodes.Box095_Mesh224_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box095_Mesh224_4.geometry}
          material={nodes.Box095_Mesh224_4.material}
        />
      </group>
      <group scale={[1, 1, 0.3]} position={[-3.5, 0.14, -0.18]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={nodes.Cube001.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_1.geometry}
          material={nodes.Cube001_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_2.geometry}
          material={materials['Screen.002']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_3.geometry}
          material={materials.Mirror}
        />
        <mesh
          geometry={nodes.Text001.geometry}
          material={nodes.Text001.material}
          position={[0.8205, 0.63, 1.0249]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.0464, 0.0392, 0.0706]}
        />
        <mesh
          geometry={nodes.Text003.geometry}
          material={nodes.Text003.material}
          position={[-0.8054, 0.63, 1.0249]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.0464, 0.0392, 0.0706]}
        />
        <mesh
          geometry={nodes.Text006.geometry}
          material={nodes.Text006.material}
          position={[0.8154, 1.0792, 0.9871]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.0569, 0.4806, 0.0865]}
        />
        <mesh
          geometry={nodes.Text007.geometry}
          material={nodes.Text007.material}
          position={[-0.7983, 1.0792, 0.9871]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.0569, 0.4806, 0.0865]}
        />
      </group>
      <group
        position={[-2.0304, 0.6852, -3.1593]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0191, 0.0191, 0.0191]}
      >
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_1'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_1'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_2'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_2'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_3'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_3'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_4'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_4'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_5'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_5'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_6'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_6'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_7'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_7'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_8'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_8'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_9'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_9'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_10'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_10'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_11'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_11'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_12'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_12'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_13'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_13'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_14'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_14'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_15'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_15'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_16'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_16'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_17'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_17'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_18'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_18'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_19'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_19'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_20'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_20'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)008_21'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)008_21'].material}
        />
      </group>
      <group
        position={[-4.9168, 0.6852, -3.1593]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0191, 0.0191, 0.0191]}
      >
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_1'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_1'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_2'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_2'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_3'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_3'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_4'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_4'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_5'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_5'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_6'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_6'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_7'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_7'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_8'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_8'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_9'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_9'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_10'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_10'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_11'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_11'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_12'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_12'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_13'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_13'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_14'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_14'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_15'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_15'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_16'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_16'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_17'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_17'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_18'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_18'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_19'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_19'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_20'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_20'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)022_21'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)022_21'].material}
        />
      </group>
      <group
        position={[-5.1023, 0.144, 3.15]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0191, 0.0191, 0.0191]}
      >
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_1'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_1'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_2'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_2'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_3'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_3'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_4'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_4'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_5'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_5'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_6'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_6'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_7'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_7'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_8'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_8'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_9'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_9'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_10'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_10'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_11'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_11'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_12'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_12'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_13'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_13'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_14'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_14'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_15'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_15'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_16'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_16'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_17'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_17'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_18'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_18'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_19'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_19'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_20'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_20'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_21'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_21'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_22'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_22'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_23'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_23'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)004_24'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)004_24'].material}
        />
      </group>
      <group
        position={[-2.4345, 0.144, 3.15]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0191, 0.0191, 0.0191]}
      >
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_1'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_1'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_2'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_2'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_3'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_3'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_4'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_4'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_5'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_5'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_6'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_6'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_7'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_7'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_8'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_8'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_9'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_9'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_10'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_10'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_11'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_11'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_12'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_12'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_13'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_13'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_14'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_14'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_15'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_15'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_16'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_16'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_17'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_17'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_18'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_18'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_19'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_19'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_20'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_20'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_21'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_21'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_22'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_22'].material}
        />
        <mesh
          geometry={nodes['womens_yoga_pants_tanks(obj)006_23'].geometry}
          material={nodes['womens_yoga_pants_tanks(obj)006_23'].material}
        />
      </group>
      <group
        position={[-0.8461, 0.5163, -12.0246]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          geometry={nodes.BOH_Office_Chair000_Office_Chair004.geometry}
          material={nodes.BOH_Office_Chair000_Office_Chair004.material}
        />
        <mesh
          geometry={nodes.BOH_Office_Chair000_Office_Chair004_1.geometry}
          material={nodes.BOH_Office_Chair000_Office_Chair004_1.material}
        />
      </group>
      <group
        position={[0.5109, 0.5163, -11.1011]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          geometry={nodes.BOH_Office_Chair001_Office_Chair002.geometry}
          material={nodes.BOH_Office_Chair001_Office_Chair002.material}
        />
        <mesh
          geometry={nodes.BOH_Office_Chair001_Office_Chair002_1.geometry}
          material={nodes.BOH_Office_Chair001_Office_Chair002_1.material}
        />
      </group>
      <group
        position={[1.6092, 0.5163, -12.1665]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          geometry={nodes.BOH_Office_Chair002_Office_Chair003.geometry}
          material={nodes.BOH_Office_Chair002_Office_Chair003.material}
        />
        <mesh
          geometry={nodes.BOH_Office_Chair002_Office_Chair003_1.geometry}
          material={nodes.BOH_Office_Chair002_Office_Chair003_1.material}
        />
      </group>
      <group
        position={[-1.0399, 2.9124, -7.339]}
        rotation={[1.6197, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BOH_screen_Cube007_1.geometry}
          material={nodes.BOH_screen_Cube007_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BOH_screen_Cube007_2.geometry}
          material={materials.BOH_TVScreen}
        />
      </group>
      <mesh
        geometry={nodes.BOH_Table_Cube006.geometry}
        material={nodes.BOH_Table_Cube006.material}
        position={[0.4928, 0.7469, -12.0469]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      />
      <group
        position={[8.6469, 1.1577, -8.0237]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box003.geometry}
          material={nodes.Box003.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box003_1.geometry}
          material={nodes.Box003_1.material}
        />
      </group>
      <group
        position={[8.6456, 1.1598, -7.4642]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box005.geometry}
          material={nodes.Box005.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box005_1.geometry}
          material={nodes.Box005_1.material}
        />
      </group>
      <group
        position={[-4.1676, 0.1471, -9.0954]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CokeMachine_1.geometry}
          material={nodes.CokeMachine_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CokeMachine_2.geometry}
          material={materials.CokeMachine}
        />
      </group>
      <group
        position={[-4.1676, 0.1471, -9.0954]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          geometry={nodes['refrigerator_refrigerator(obj)_1'].geometry}
          material={nodes['refrigerator_refrigerator(obj)_1'].material}
        />
        <mesh
          geometry={nodes['refrigerator_refrigerator(obj)_2'].geometry}
          material={nodes['refrigerator_refrigerator(obj)_2'].material}
        />
      </group>
      <group
        position={[8.6469, 1.1577, -9.1658]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box003001.geometry}
          material={nodes.Box003001.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box003001_1.geometry}
          material={nodes.Box003001_1.material}
        />
      </group>
      <group
        position={[8.6456, 1.1598, -8.6064]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.601, 0.601, 0.601]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box005001.geometry}
          material={nodes.Box005001.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box005001_1.geometry}
          material={nodes.Box005001_1.material}
        />
      </group>
      <mesh
        geometry={nodes.BOH_Table_Cube001.geometry}
        material={nodes.BOH_Table_Cube001.material}
        position={[-6.5938, 0.7469, -11.326]}
        rotation={[Math.PI / 2, 0, 1.5707]}
        scale={[0.601, 0.601, 0.601]}
      />
      <mesh
        geometry={nodes.SmartMirrorDemo.geometry}
        material={screenmat}
        position={[-3.5, 0.14, -0.93]}
      />
      <group
        position={[-2.3155, 0.14, 8.0039]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.0064, 0.0064, 0.0064]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Full_Cash_Counter_obj171.geometry}
          material={nodes.Full_Cash_Counter_obj171.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_1.geometry}
          material={nodes.Full_Cash_Counter_obj171_1.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_2.geometry}
          material={nodes.Full_Cash_Counter_obj171_2.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_3.geometry}
          material={nodes.Full_Cash_Counter_obj171_3.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_4.geometry}
          material={nodes.Full_Cash_Counter_obj171_4.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_5.geometry}
          material={nodes.Full_Cash_Counter_obj171_5.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_6.geometry}
          material={nodes.Full_Cash_Counter_obj171_6.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_7.geometry}
          material={nodes.Full_Cash_Counter_obj171_7.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_8.geometry}
          material={nodes.Full_Cash_Counter_obj171_8.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_9.geometry}
          material={nodes.Full_Cash_Counter_obj171_9.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_10.geometry}
          material={nodes.Full_Cash_Counter_obj171_10.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_11.geometry}
          material={nodes.Full_Cash_Counter_obj171_11.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj171_12.geometry}
          material={nodes.Full_Cash_Counter_obj171_12.material}
        />
        <group
          position={[138.202, 118.4892, -50.4144]}
          rotation={[0, 0, Math.PI]}
        >
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453.geometry}
            material={nodes.Full_Cash_Counter_obj1453.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_1.geometry}
            material={nodes.Full_Cash_Counter_obj1453_1.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_2.geometry}
            material={nodes.Full_Cash_Counter_obj1453_2.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_3.geometry}
            material={nodes.Full_Cash_Counter_obj1453_3.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_4.geometry}
            material={nodes.Full_Cash_Counter_obj1453_4.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_5.geometry}
            material={nodes.Full_Cash_Counter_obj1453_5.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_6.geometry}
            material={nodes.Full_Cash_Counter_obj1453_6.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_7.geometry}
            material={nodes.Full_Cash_Counter_obj1453_7.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_8.geometry}
            material={nodes.Full_Cash_Counter_obj1453_8.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_9.geometry}
            material={nodes.Full_Cash_Counter_obj1453_9.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1453_10.geometry}
            material={nodes.Full_Cash_Counter_obj1453_10.material}
          />
        </group>
        <group
          position={[107.1779, -84.3644, 21.9739]}
          rotation={[-0.0175, 0, 0]}
          scale={[134.4808, 134.4808, 134.4808]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Man_Mesh.geometry}
            material={nodes.Man_Mesh.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Man_Mesh_1.geometry}
            material={nodes.Man_Mesh_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Man_Mesh_2.geometry}
            material={nodes.Man_Mesh_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Man_Mesh_3.geometry}
            material={nodes.Man_Mesh_3.material}
          />
        </group>
      </group>
      <group
        position={[0.8002, 0.14, 8.0039]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.0064, 0.0064, 0.0064]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Full_Cash_Counter_obj1515.geometry}
          material={nodes.Full_Cash_Counter_obj1515.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_1.geometry}
          material={nodes.Full_Cash_Counter_obj1515_1.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_2.geometry}
          material={nodes.Full_Cash_Counter_obj1515_2.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_3.geometry}
          material={nodes.Full_Cash_Counter_obj1515_3.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_4.geometry}
          material={nodes.Full_Cash_Counter_obj1515_4.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_5.geometry}
          material={nodes.Full_Cash_Counter_obj1515_5.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_6.geometry}
          material={nodes.Full_Cash_Counter_obj1515_6.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_7.geometry}
          material={nodes.Full_Cash_Counter_obj1515_7.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_8.geometry}
          material={nodes.Full_Cash_Counter_obj1515_8.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_9.geometry}
          material={nodes.Full_Cash_Counter_obj1515_9.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_10.geometry}
          material={nodes.Full_Cash_Counter_obj1515_10.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_11.geometry}
          material={nodes.Full_Cash_Counter_obj1515_11.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1515_12.geometry}
          material={nodes.Full_Cash_Counter_obj1515_12.material}
        />
        <group
          position={[138.202, 118.4892, -50.4144]}
          rotation={[0, 0, Math.PI]}
        >
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516.geometry}
            material={nodes.Full_Cash_Counter_obj1516.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_1.geometry}
            material={nodes.Full_Cash_Counter_obj1516_1.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_2.geometry}
            material={nodes.Full_Cash_Counter_obj1516_2.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_3.geometry}
            material={nodes.Full_Cash_Counter_obj1516_3.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_4.geometry}
            material={nodes.Full_Cash_Counter_obj1516_4.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_5.geometry}
            material={nodes.Full_Cash_Counter_obj1516_5.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_6.geometry}
            material={nodes.Full_Cash_Counter_obj1516_6.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_7.geometry}
            material={nodes.Full_Cash_Counter_obj1516_7.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_8.geometry}
            material={nodes.Full_Cash_Counter_obj1516_8.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_9.geometry}
            material={nodes.Full_Cash_Counter_obj1516_9.material}
          />
          <mesh
            geometry={nodes.Full_Cash_Counter_obj1516_10.geometry}
            material={nodes.Full_Cash_Counter_obj1516_10.material}
          />
        </group>
        <group
          position={[107.1779, -84.3644, 21.9739]}
          rotation={[-0.0175, 0, 0]}
          scale={[134.4808, 134.4808, 134.4808]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Man_Mesh001.geometry}
            material={nodes.Man_Mesh001.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Man_Mesh001_1.geometry}
            material={nodes.Man_Mesh001_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Man_Mesh001_2.geometry}
            material={nodes.Man_Mesh001_2.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Man_Mesh001_3.geometry}
            material={nodes.Man_Mesh001_3.material}
          />
        </group>
      </group>
      <group
        position={[-3.0704, 0.4612, 8.8845]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[0.0064, 0.0064, 0.0064]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Full_Cash_Counter_obj1518.geometry}
          material={nodes.Full_Cash_Counter_obj1518.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_1.geometry}
          material={nodes.Full_Cash_Counter_obj1518_1.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_2.geometry}
          material={nodes.Full_Cash_Counter_obj1518_2.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_3.geometry}
          material={nodes.Full_Cash_Counter_obj1518_3.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_4.geometry}
          material={nodes.Full_Cash_Counter_obj1518_4.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_5.geometry}
          material={nodes.Full_Cash_Counter_obj1518_5.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_6.geometry}
          material={nodes.Full_Cash_Counter_obj1518_6.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_7.geometry}
          material={nodes.Full_Cash_Counter_obj1518_7.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_8.geometry}
          material={nodes.Full_Cash_Counter_obj1518_8.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_9.geometry}
          material={nodes.Full_Cash_Counter_obj1518_9.material}
        />
        <mesh
          geometry={nodes.Full_Cash_Counter_obj1518_10.geometry}
          material={nodes.Full_Cash_Counter_obj1518_10.material}
        />
      </group>
      <group
        position={[-1.778, 0, 8.6868]}
        rotation={[Math.PI / 2, -0.0175, Math.PI / 2]}
        scale={[0.8568, 0.8568, 0.8568]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Man_Mesh002.geometry}
          material={nodes.Man_Mesh002.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Man_Mesh002_1.geometry}
          material={nodes.Man_Mesh002_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Man_Mesh002_2.geometry}
          material={nodes.Man_Mesh002_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Man_Mesh002_3.geometry}
          material={nodes.Man_Mesh002_3.material}
        />
      </group>
      <group
        position={[-2.1974, 0.1144, 13.5701]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0149, 0.0149, 0.0149]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station_1.geometry}
          material={nodes.charging_station_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station_2.geometry}
          material={nodes.charging_station_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station_3.geometry}
          material={nodes.charging_station_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station_4.geometry}
          material={nodes.charging_station_4.material}
        />
      </group>
      <group
        position={[-4.6474, 0.1144, 13.5701]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0149, 0.0149, 0.0149]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station001_1.geometry}
          material={nodes.charging_station001_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station001_2.geometry}
          material={nodes.charging_station001_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station001_3.geometry}
          material={nodes.charging_station001_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station001_4.geometry}
          material={nodes.charging_station001_4.material}
        />
      </group>
      <group
        position={[-7.0244, 0.1144, 13.5701]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0149, 0.0149, 0.0149]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station002_1.geometry}
          material={nodes.charging_station002_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station002_2.geometry}
          material={nodes.charging_station002_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station002_3.geometry}
          material={nodes.charging_station002_3.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charging_station002_4.geometry}
          material={nodes.charging_station002_4.material}
        />
      </group>
      <group
        position={[8.6557, 1.1665, -10.4673]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0075, 0.0075, 0.0075]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Coat-Rack_ChamferBox007001'].geometry}
          material={materials.Brown}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Coat-Rack_ChamferBox007001_1'].geometry}
          material={materials['Shirt.000']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Coat-Rack_ChamferBox007001_2'].geometry}
          material={materials['Shirt.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Coat-Rack_ChamferBox007001_3'].geometry}
          material={materials['Shirt.002']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Coat-Rack_ChamferBox007001_4'].geometry}
          material={materials['Shirt.003']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Coat-Rack_ChamferBox007001_5'].geometry}
          material={materials['Shirt.004']}
        />
        <mesh
          geometry={nodes['Coat-Rack_ChamferBox007001_6'].geometry}
          material={nodes['Coat-Rack_ChamferBox007001_6'].material}
        />
      </group>
      <group
        position={[-5.334, 0, 13.5128]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          geometry={nodes['Curb-side_kiosk000_Plane001_1'].geometry}
          material={nodes['Curb-side_kiosk000_Plane001_1'].material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Curb-side_kiosk000_Plane001_2'].geometry}
          material={materials['Crubside_Screen.002']}
        />
      </group>
      <group
        position={[-2.9225, 1.211, 13.5622]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          geometry={nodes['Curb-side_kiosk000_Plane002_1'].geometry}
          material={nodes['Curb-side_kiosk000_Plane002_1'].material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Curb-side_kiosk000_Plane002_2'].geometry}
          material={materials['Crubside_Screen.001']}
        />
      </group>
      <group
        position={[-7.7285, 1.211, 13.5622]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          geometry={nodes['Curb-side_kiosk000_Plane003_1'].geometry}
          material={nodes['Curb-side_kiosk000_Plane003_1'].material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['Curb-side_kiosk000_Plane003_2'].geometry}
          material={materials['Crubside_Screen.003']}
        />
      </group>
      <mesh
        geometry={nodes.shelves_Cube_81.geometry}
        material={nodes.shelves_Cube_81.material}
        position={[2.4905, 2.0733, -12.4661]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0095, 0.0075, 0.0075]}
      />
      <mesh
        geometry={nodes.shelves_Cube_81001.geometry}
        material={nodes.shelves_Cube_81001.material}
        position={[-1.5805, 2.0733, -12.4661]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.0095, 0.0075, 0.0075]}
      />
      <group
        position={[-6.9374, 1.6396, -10.904]}
        rotation={[Math.PI / 2, 0, -1.5574]}
        scale={[0.0075, 0.0075, 0.0075]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SmallCabnit_kulp_1_1.geometry}
          material={nodes.SmallCabnit_kulp_1_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SmallCabnit_kulp_1_2.geometry}
          material={nodes.SmallCabnit_kulp_1_2.material}
        />
      </group>
      <group
        position={[-6.9374, 1.6396, -11.7764]}
        rotation={[Math.PI / 2, 0, -1.5574]}
        scale={[0.0075, 0.0075, 0.0075]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SmallCabnit_kulp_1001_1.geometry}
          material={nodes.SmallCabnit_kulp_1001_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SmallCabnit_kulp_1001_2.geometry}
          material={nodes.SmallCabnit_kulp_1001_2.material}
        />
      </group>
      <group
        position={[7.6363, 2.2128, -12.3848]}
        rotation={[Math.PI / 2, 0, 0.0134]}
        scale={[0.0075, 0.0075, 0.0075]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SmallCabnit_kulp_1002_1.geometry}
          material={nodes.SmallCabnit_kulp_1002_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SmallCabnit_kulp_1002_2.geometry}
          material={nodes.SmallCabnit_kulp_1002_2.material}
        />
      </group>
      <mesh
        geometry={nodes.CurbSideServiceText000.geometry}
        material={nodes.CurbSideServiceText000.material}
        position={[-2.9464, 0.0133, 17.1704]}
        scale={[0.341, 0.341, 0.341]}
      />
      <mesh
        geometry={nodes.CurbSideServiceText001.geometry}
        material={nodes.CurbSideServiceText001.material}
        position={[-5.3039, 0.0133, 17.1704]}
        scale={[0.341, 0.341, 0.341]}
      />
      <mesh
        geometry={nodes.CurbSideServiceText002.geometry}
        material={nodes.CurbSideServiceText002.material}
        position={[-7.6493, 0.0133, 17.1704]}
        scale={[0.341, 0.341, 0.341]}
      />
      <mesh
        geometry={nodes['W&W_Store_Logo_full000'].geometry}
        material={nodes['W&W_Store_Logo_full000'].material}
        position={[-0.9652, 3.9624, 12.0345]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[17.0171, 17.0171, 17.0171]}
      />
      <mesh
        geometry={nodes['W&W_Store_Logo_full001'].geometry}
        material={nodes['W&W_Store_Logo_full001'].material}
        position={[-7.793, 3.9624, 4.2113]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[17.0171, 17.0171, 17.0171]}
      />
    </group>
  );
}

useGLTF.preload(retail);
