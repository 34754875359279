import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import graph from '../sagelabel/Graph_icon.png';
import heart from '../sagelabel/heart.png';
import video from '../sagelabel/Video-icon.png';
import ha from '../sagelabel/download_buttonA.png';
import tech from '../sagelabel/Tech_icon.png';
import firewall from '../sagelabel/Firewall_icon.png';
import seamless from '../sagelabel/Seamless_icon.png';
import wifi2 from '../sagelabel/Wi-FI_icon.png';
import pene from '../sagelabel/Penetration-Tests_icon.png';
import monitor from '../sagelabel/Monitoring_icon.png';
import peneb from '../sagelabel/pene-button.png';
import seamb from '../sagelabel/seam_button.png';
import { useTexture } from '@react-three/drei';
import wearmask from '../ImageAssets/wearmask.jpg';
import accessp from '../ImageAssets/access.png';
import vsat from '../ImageAssets/vsat.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.02}
        offs2={0.01}
        dot={-1}
        text={'Employee Communication.'}
        para={
          'Back of house digital signage can increase employee engagement. Introduce new menu items. Share company news and policies. Feature contests, recognition programs/employee of the week, career opportunities and offer training reminders. All with attention-grabbing graphics and up-to-date statistics.'
        }
        position={[0.5, 2.5, 0]}
        skew={0}
        wx={1.9}
        icon={graph}
        icolor={'#8AD2D2'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.03}
        text={'Health & Safety.'}
        dot={2}
        image={useTexture(wearmask)}
        para={
          'Your c-store is only as strong as your people - keep them safe. With digital signage, you can send messages to staff. Send reminders to wash hands, do temp checks, wear a mask, and maintain social distancing. If your state of business changes the mandates, so can you.'
        }
        position={[3.2, 1.5, 0]}
        skew={0}
        wx={1.3}
        offs={-0.04}
        icon={heart}
        icolor={'#8AD2D2'}
      />
    </>
  );
}
function Sagesec(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.03}
        text={'Penetration Tests.'}
        dot={3}
        offs={-0.07}
        linka={ha}
        linki={peneb}
        url={
          'https://sagenetvirtual.com/SageNet_CyberPenTesting_2pgr_New070319-1.pdf'
        }
        para={
          'Cyber threats are ongoing, so your security practice should be too. Penetration tests mimic real-world attacks to better understand security vulnerabilities. With a thorough and systematic test against your environment, defenses can be hardened and made more secure. 84% of shoppers say they’ll change buying habits if their favorite store faces a breach – protect your customers.'
        }
        position={[0, 2.5, 0]}
        skew={0}
        wx={1.4}
        icon={pene}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        para={'TBD'}
        vidurl={
          'https://player.vimeo.com/video/557676076?autoplay=1&title=0&byline=0&portrait=0'
        }
        text={'Managed Firewall.'}
        position={[0, 1.7, 0]}
        skew={0}
        wx={1.4}
        offs2={0.02}
        icon={firewall}
        icolor={'#72337F'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={0.02}
        text={'MDR.'}
        position={[2.7, 1.6, 0]}
        skew={0}
        wx={0.8}
        para={
          'Managed Detection & Response. Legacy Endpoint Protection is no longer sufficient. Antiquated technology leaves endpoints vulnerable to modern attack methods and ransomware. It’s time to retire outmoded antivirus and combat today’s cybercrooks with advanced threat detection, deep analysis & insight, actions of triage, quarantine, mitigation, and rollback.'
        }
        icon={tech}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'VoIP/UCaaS.'}
        image={useTexture(vsat)}
        para={
          'VoIP uses your network to eliminate monthly phone bills. What’s more, Unified Communications as a Service (UCaaS) turns your phone into a productivity tool with features like unified messaging, chat, voice & video conferencing and much more. SageNet’s “as a service” model takes the trial and error out of integrated, enterprise-wide communications.'
        }
        position={[0.5, 2.4, 0]}
        skew={0}
        wx={1.2}
        icon={tech}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Seamless Experience.'}
        dot={3}
        offs2={-0.03}
        linka={ha}
        offs={-0.04}
        linki={seamb}
        url={
          'https://sagenetvirtual.com/SageNet_ManagedNetworkServices_WP_NEW041020.pdf'
        }
        para={
          'The best connections are the ones that customers and staff never notice. SageCONNECT ensures every transaction is flawless and frictionless, inventory and back office systems are up to date, your digital signage is up-to-date, and your guest Wi-Fi is fast and reliable. All this with the assurance that connections are secure, and costs are competitive.'
        }
        position={[0, 1.7, 0]}
        skew={0}
        wx={1.5}
        icon={seamless}
        icolor={'#76787B'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.05}
        text={'Multi-Site Connections.'}
        vidurl={
          'https://player.vimeo.com/video/501963890?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[2.5, 1.6, 0]}
        skew={0}
        wx={1.7}
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.04}
        text={'Access Point Management.'}
        image={useTexture(accessp)}
        para={
          'Whether for employees, in-store devices or guests, inside or out; reliance on and expectations for Wi-Fi is high. Access Point placement, quality and security will determine availability and reliability. Interference has many culprits. Refrigerators, security cameras, microwaves, TVs, walls, windows, and even weather can impact wireless performance.'
        }
        position={[0.3, 2.4, 0]}
        skew={0}
        wx={1.8}
        icon={monitor}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        offs={-0.04}
        index={props.index}
        offs2={-0.03}
        dot={2}
        url={'https://sagenetvirtual.com/SageNet_Wi-FiDS_071619.pdf'}
        text={'Staff Wi-Fi.'}
        para={
          'Wi-Fi isn’t just for guests. Beyond the mobile order and POS capabilities of Wi-Fi, there are many operational benefits to enterprise-grade Wi-Fi. Scheduling and time sheets can be completed via Wi-Fi. QSRs can offer online training via video and PDFs, including brief training videos and facility tours. Location analytics can even provide data regarding staff and process flow. Text messaging and VoIP can also take advantage of enterprise grade Wi-Fi.'
        }
        position={[0, 1.5, 0]}
        skew={0}
        wx={1.2}
        icon={wifi2}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        offs2={-0.03}
        index={props.index}
        text={'Wi-Fi Deployment.'}
        position={[2.8, 1.6, 0]}
        skew={0}
        wx={1.4}
        vidurl={
          'https://player.vimeo.com/video/501963763?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function POP(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
