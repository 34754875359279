/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import lot from './lot.glb'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(lot)
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0,0.1,0]}>
        <mesh geometry={nodes.Plane.geometry} material={materials.sreet} />
        <mesh geometry={nodes.Plane_1.geometry} material={materials['Parking ']} />
      </group>
    </group>
  )
}

useGLTF.preload(lot)
