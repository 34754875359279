import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import video from '../sagelabel/Video-icon.png';
import ha from '../sagelabel/download_buttonA.png';
import tech from '../sagelabel/Tech_icon.png';
import pc from '../sagelabel/PCI_icon.png';
import scanb from '../sagelabel/scanning_button.png';
import { useTexture } from '@react-three/drei';
import heart from '../sagelabel/heart.png';
import hs from '../sagelabel/download_button.png';
import peneb from '../sagelabel/pene-button.png';
import pene from '../sagelabel/Penetration-Tests_icon.png';
import firewall from '../sagelabel/Firewall_icon.png';
import vsat from '../ImageAssets/vsat.png';
import seamb from '../sagelabel/seam_button.png';
import seamless from '../sagelabel/Seamless_icon.png';
import accessp from '../ImageAssets/access.png';
import monitor from '../sagelabel/Monitoring_icon.png';
import wifi2 from '../sagelabel/Wi-FI_icon.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        index={props.index}
        linki={hs}
        linka={ha}
        offs2={-0.03}
        hidden={props.hidden}
        text={'Health & Safety.'}
        dot={2}
        offs={-0.05}
        url={
          'https://sagenetvirtual.com/SageNet_6Paradigms6Tools_WP_NEW101620%20(5).pdf'
        }
        para={
          'Your store is only as strong as your people - keep them safe. With digital signage, you can send messages to staff. Send reminders to wash hands, do temp checks, wear a mask, and maintain social distancing. If your state of business changes the mandates, so can you.'
        }
        position={[-0.2, 2.2, 0]}
        skew={0}
        wx={1.3}
        icon={heart}
        icolor={'#8AD2D2'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Employee Communication.'}
        position={[0.5, 1.5, 0]}
        offs2={0.02}
        dot={-1}
        skew={0}
        wx={1.8}
        para={
          'Back of house digital signage can increase employee engagement. Introduce new promotional items. Share company news and policies. Feature contests, recognition programs/employee of the week, career opportunities and offer training reminders. All with attention-grabbing graphics and up-to-date statistics.'
        }
        icon={video}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Inventory Management.'}
        para={
          'Whether a warehouse or a stockroom, your employees can spend a lot of time sifting through rows of products looking for that singular, coveted piece. Digital signage can show staff real-time inventory as well as locations of every piece of product moving in and out of your store. Saving them time, and you money.'
        }
        position={[3.2, 2.5, 0]}
        skew={0}
        wx={1.7}
        icon={video}
        icolor={'#8AD2D2'}
      />
    </>
  );
}
function Sagesec(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.03}
        text={'Penetration Tests.'}
        dot={3}
        offs={-0.07}
        linka={ha}
        linki={peneb}
        url={
          'https://sagenetvirtual.com/SageNet_CyberPenTesting_2pgr_New070319-1.pdf'
        }
        para={
          'Cyber threats are ongoing, so your security practice should be too. Penetration tests mimic real-world attacks to better understand security vulnerabilities. With a thorough and systematic test against your environment, defenses can be hardened and made more secure. 84% of shoppers say they’ll change buying habits if their favorite store faces a breach – protect your customers.'
        }
        position={[0, 2.5, 0]}
        skew={0}
        wx={1.4}
        icon={pene}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={0.01}
        text={'MDR.'}
        position={[2.7, 2.2, 0]}
        skew={0}
        wx={1.4}
        para={
          'Managed Detection & Response. Legacy Endpoint Protection is no longer sufficient. Antiquated technology leaves endpoints vulnerable to modern attack methods and ransomware. It’s time to retire outmoded antivirus and combat today’s cybercrooks with advanced threat detection, deep analysis & insight, actions of triage, quarantine, mitigation, and rollback.'
        }
        icon={tech}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Hyperscale Security.'}
        position={[0, 1.7, 0]}
        skew={0}
        vidurl={
          'https://player.vimeo.com/video/560086203?autoplay=1&title=0&byline=0&portrait=0'
        }
        wx={1.4}
        offs2={0.02}
        icon={video}
        icolor={'#72337F'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'VULN Scanning.'}
        position={[3.5, 1.5, 0]}
        skew={0}
        wx={1.2}
        linka={ha}
        linki={scanb}
        url={
          'https://sagenetvirtual.com/SageNet_MngdVulnerabilityScanningDS_New092120.pdf'
        }
        para={
          'Minimize the risk of a security breach with continuous scans of your network. Vulnerability Scans provide insights and guidance to properly secure your network. Your PCI compliance depends on not only getting ASV scans completed, but by maintaining a healthy security posture based on scan results. Stay secure so you can continue accepting card payments.'
        }
        icon={pc}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'VoIP/UCaaS.'}
        image={useTexture(vsat)}
        para={
          'VoIP uses your network to eliminate monthly phone bills. What’s more, Unified Communications as a Service (UCaaS) turns your phone into a productivity tool with features like unified messaging, chat, voice & video conferencing and much more. SageNet’s “as a service” model takes the trial and error out of integrated, enterprise-wide communications.'
        }
        position={[0.5, 2.4, 0]}
        skew={0}
        wx={1.2}
        icon={tech}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Seamless Experience.'}
        dot={3}
        offs2={-0.03}
        linka={ha}
        offs={-0.04}
        linki={seamb}
        url={
          'https://sagenetvirtual.com/SageNet_ManagedNetworkServices_WP_NEW041020.pdf'
        }
        para={
          'The best connections are the ones that customers and staff never notice. SageCONNECT ensures every transaction is flawless and frictionless, inventory and back office systems are up to date, your digital signage is up to date, and your guest Wi-Fi is fast and reliable. All this with the assurance that connections are secure, and costs are competitive.'
        }
        position={[0, 1.7, 0]}
        skew={0}
        wx={1.5}
        icon={seamless}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.05}
        text={'Multi-Site Connections.'}
        vidurl={
          'https://player.vimeo.com/video/501963890?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[2.5, 1.6, 0]}
        skew={0}
        wx={1.7}
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.04}
        text={'Access Point Management.'}
        image={useTexture(accessp)}
        para={
          'Whether for employees, in-store devices or guests, inside or out; reliance on and expectations for Wi-Fi is high. Access Point placement, quality and security will determine availability and reliability. Interference has many culprits. Refrigerators, security cameras, microwaves, TVs, walls, windows, even weather can impact wireless performance.'
        }
        position={[0.3, 2.4, 0]}
        skew={0}
        wx={1.8}
        icon={monitor}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        offs={-0.04}
        index={props.index}
        offs2={-0.03}
        dot={2}
        url={'https://sagenetvirtual.com/SageNet_Wi-FiDS_071619.pdf'}
        text={'Staff Wi-Fi.'}
        para={
          'Wi-Fi isn’t just for guests. Beyond the mobile order and POS capabilities of Wi-Fi, there are many operational benefits to enterprise-grade Wi-Fi. Scheduling and time sheets can be completed via Wi-Fi. QSRs can offer online training via video and PDFs, including brief training videos and facility tours. Location analytics can even provide data regarding staff and process flow. Text messaging and VoIP can also take advantage of enterprise grade Wi-Fi.'
        }
        position={[0, 1.5, 0]}
        skew={0}
        wx={1.2}
        icon={wifi2}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        offs2={-0.03}
        index={props.index}
        text={'Wi-Fi Deployment.'}
        position={[2.8, 1.6, 0]}
        skew={0}
        wx={1.4}
        vidurl={
          'https://player.vimeo.com/video/501963763?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function Drivethru(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
