import React, { useRef, Suspense } from 'react';
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber';
import './App.css';
import { Sky } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Test from './OverworldModels/Dnewcity';

extend({ OrbitControls });
const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement },
  } = useThree();

  // Ref to the controls, so that we can update them on every frame with useFrame
  const controls = useRef();
  useFrame(() => controls.current.update());
  var x = 2.3;
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      //autoRotate={true}
      enableZoom={true}
      maxPolarAngle={Math.PI * 0.45}
      minPolarAngle={Math.PI * 0.3}
      maxAzimuthAngle={Math.PI * 0.1 + x}
      minAzimuthAngle={-Math.PI * 0.1 + x}
      maxDistance={22}
      minDistance={15}
      enablePan={false}
    />
  );
};

function Overworld(props) {
  //Scene();
  /*let _ocean = useTexture(ocean);
  _ocean.wrapT = THREE.RepeatWrapping;
  _ocean.repeat.y = -1;*/

  //console.log(a);
  return (
    <>
      <Canvas
        gl={{ antialias: true }}
        shadowMap={false}
        camera={{ fov: 40, position: [12.7, 7.21, -12.3] }}
        className="canvas"
      >
        <fog attach="fog" args={['#c0d3e2', 15, 30]} />

        <CameraControls cam={props.cam} />
        <directionalLight intensity={2} position={[2, 1, 10]} />
        <hemisphereLight
          color={'white'}
          groundColor={'#008eff'}
          intensity={0.4}
        />
        <pointLight
          position={[-0.2, 0.85, -1.5]}
          distance={1.3}
          intensity={40}
          decay={2}
          color={'orange'}
        />
        <pointLight
          position={[-0.7, 0.5, -4.5]}
          distance={1.3}
          intensity={40}
          decay={2}
          color={'orange'}
        />
        <pointLight
          position={[2.7, 0.6, 1]}
          distance={1.3}
          intensity={40}
          decay={2}
          color={'orange'}
        />
        <pointLight
          position={[-0.2, 0.6, 1.7]}
          distance={1.3}
          intensity={40}
          decay={2}
          color={'orange'}
        />

        <Suspense fallback={null}>
          <Test position={[0, 0, 0]} />
          <Sky
            sunPosition={[0, 1, 0]}
            turbidity={7.1}
            rayleigh={0.15}
            mieCoefficient={0.006}
            mieDirectionalG={0.941}
            exposure={0.18}
          />
          {props.obj}
        </Suspense>
      </Canvas>
    </>
  );
}

export default Overworld;
