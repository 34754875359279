/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import ipad from './Videos/ipad.webm';
import menu from './Videos/menu.webm';
import drive from './Videos/drivethru.webm';
import Screen from './Screen';

export default function Pump(props) {
  const group = useRef();

  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <Screen
          url={ipad}
          pbs={[1, 2]}
          position={[2.95, 0.98, 4.96]}
          rotation={[-0.6, 0, 0]}
          scale={[0.3, 0.3, 1]}
        />
        <group rotation={[0, Math.PI * 0.5, 0]} position={[-5.9, 0.91, -10.73]}>
          <Screen
            url={ipad}
            pbs={[1, 2]}
            position={[0, 0, 0]}
            rotation={[-0.55, 0, 0]}
            scale={[0.23, 0.2, 1]}
          />
          <Screen
            url={ipad}
            pbs={[1, 2]}
            position={[-4.2, 0.7, 12.1]}
            rotation={[0, -1, 0]}
            scale={[0.7, 0.7, 1]}
          />
        </group>
        <Screen
          url={menu}
          pbs={[2, 1]}
          position={[-7.2, 2.4, -9.32]}
          rotation={[0, Math.PI * 0.5, 0]}
          scale={[0.61, 0.69, 1]}
        />
        <Screen
          url={menu}
          pbs={[2, 1]}
          position={[-7.2, 2.4, -10.57]}
          rotation={[0, Math.PI * 0.5, 0]}
          scale={[0.61, 0.69, 1]}
        />
        <Screen
          url={menu}
          pbs={[2, 1]}
          position={[-7.2, 2.4, -11.83]}
          rotation={[0, Math.PI * 0.5, 0]}
          scale={[0.61, 0.69, 1]}
        />
        <Screen
          url={drive}
          pbs={[1.7, 1]}
          position={[6.09, 1.65, -13.52]}
          rotation={[0, Math.PI * 0.385, 0]}
          scale={[1.4, 1.4, 1]}
        />
      </group>
    </>
  );
}

//useGLTF.preload(npump)
