import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import graph from '../sagelabel/Graph_icon.png';
import location from '../sagelabel/Location_icon.png';
import heart from '../sagelabel/heart.png';
import video from '../sagelabel/Video-icon.png';
import wifi from '../sagelabel/Secure Wi-Fi_Icon.png';
import hs from '../sagelabel/download_button.png';
import ha from '../sagelabel/download_buttonA.png';
import dlocation from '../sagelabel/download_location.png';
import { useTexture } from '@react-three/drei';
import signage from '../sagelabel/signage.png';
import wifi2 from '../sagelabel/Wi-FI_icon.png';
import wifib from '../sagelabel/wifi_button.png';

import ass2 from '../ImageAssets/asessments.jpg';
import kiosk from '../sagelabel/Kiosk_icon.png';
import obutton from '../sagelabel/optimize_button.png';
import touchless from '../ImageAssets/touchless.jpg';
import tech from '../sagelabel/Tech_icon.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.05}
        offs2={-0.02}
        dot={4}
        text={'In-branch Experience.'}
        para={
          'Make the most of the in-branch experience. Three-quarters of financial institutions with interactive digital signage report increased sales and increased scores in customer rankings in branch trustworthiness. Dynamic and engaging digital signage helps financial institutions increase awareness around products and promotions at the most critical time – in the branch. With the right content on bright, eye-catching screens, you can promote special rates and offer reminders to start that college fund and more.'
        }
        position={[-0.7, 2.3, 0]}
        skew={0}
        wx={1.6}
        icon={graph}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        text={'Get Interactive.'}
        dot={1}
        offs={-0.03}
        offs2={-0.03}
        para={
          '                                                                                                           Most digital signage engagements involve one-way delivery of content. Interactive digital signage has the potential to provide the greatest value for financial institutions. Interactive digital engagement can simplify customer interactions while guiding them quickly to the services they want. This is not only more convenient for the customer but could also reduce staffing requirements for the financial institution and free up branch personnel for higher-value tasks. Digital signage can also be utilized to train staff after business hours, extending the overall investment value.'
        }
        hidden={props.hidden}
        position={[0, 1.5, 0]}
        skew={0}
        wx={1.3}
        icon={tech}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        text={'Kiosks.'}
        dot={1}
        offs={-0.03}
        offs2={-0.01}
        para={
          'Interactive, touch-enabled digital signage allows your customers to use the same functionality of online banking inside the branch, reducing teller workloads and customer wait times. View balances and transfers, check rates, explore loan calculators and more. Help your customers get information they need quickly and efficiently, and free up your staff to perform other responsibilities.'
        }
        hidden={props.hidden}
        position={[1, 0.9, 0]}
        skew={0}
        wx={0.9}
        icon={kiosk}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        offs2={-0.03}
        hidden={props.hidden}
        text={'Want More Foot Traffic, Look Out.'}
        dot={4}
        offs={-0.1}
        para={
          '“If this was your bank, you’d be here already.” Put your message where your prospects are. Attract more customers and drive more walk-in traffic with ultra-bright, street/sidewalk/mall-facing signage. Seasonal offers, interest rates, and promotional offers can be updated in real-time, by time of day, day of week, weather, or current events. '
        }
        position={[3.2, 2, 0]}
        skew={0}
        linki={signage}
        linka={ha}
        url={
          'https://sagenetvirtual.com/SageNet_DigitalSignage_Value_WP_101014FINAL-2%20(1).pdf'
        }
        wx={2.2}
        icon={heart}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        text={'Video.'}
        position={[2.7, 1.3, 0]}
        hidden={props.hidden}
        skew={0}
        wx={1}
        vidurl={
          'https://player.vimeo.com/video/560099938?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#8AD2D2'}
      />
    </>
  );
}

function Sagesec(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.02}
        text={'Cyber Security.'}
        vidurl={
          'https://player.vimeo.com/video/501963814?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.2, 2.3, 0]}
        skew={0}
        wx={1.2}
        icon={video}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        para={
          'Assessments should be based on industry best-practices to ensure the best security posture for your store. Utilizing compliance frameworks like ISO 27001/27002, NIST 800-53, and PCI, your cyber partner can identify existing/potential gaps in your physical or online security. Those gaps are then triaged so any necessary remediation efforts can begin.'
        }
        text={'Assessments.'}
        image={useTexture(ass2)}
        position={[0.3, 1.4, 0]}
        skew={0}
        wx={1.1}
        icon={wifi}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        linki={hs}
        linka={ha}
        offs2={-0.03}
        hidden={props.hidden}
        text={'Expanded Cybersecurity.'}
        offs={-0.05}
        url={
          'https://sagenetvirtual.com/SageNet_6Paradigms6Tools_WP_NEW101620%20(5).pdf'
        }
        para={
          'Financial institutions face a myriad of regulatory cybersecurity requirements from Dodd- Frank to New York DFS 23 NYCRR 500, mandating specific in-depth cybersecurity programs. SageNet Cybersecurity and Consulting Services provide financial organizations access to an experienced cybersecurity team. SageNet cybersecurity analysts, engineers and architects are certified, qualified and armed with a suite of specialized security tools and solutions as well as three U.S.-based 24/7 Network Operations and Security Operations Centers.'
        }
        position={[3, 2.3, 0]}
        skew={0}
        wx={1.9}
        icon={wifi}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Cyber For Kiosks.'}
        dot={3}
        offs2={-0.02}
        offs={-0.07}
        position={[2.7, 1.4, 0]}
        skew={0}
        para={
          'Any time a new device is connected to your network, you introduce new security risks. Make sure that your kiosks remain secure, that their screens only show the content you intend, and customers are protected from skimming or other methods of identity theft. Keep your customers’ info secure, and they’ll keep their brand loyalty.'
        }
        wx={1.4}
        icon={kiosk}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        index={props.index}
        linki={hs}
        linka={ha}
        offs2={-0.01}
        hidden={props.hidden}
        text={'Empowering Connectivity.'}
        dot={2}
        offs={-0.05}
        url={
          'https://sagenetvirtual.com/SageNet_6Paradigms6Tools_WP_NEW101620%20(5).pdf'
        }
        para={
          'The pandemic led to several paradigm shifts for branches: health concerns, financial uncertainty, the need for agility, and the use of tech to accelerate change. However, robust connectivity remained an enabling force. From security to digital experience, frictionless transactions to analytics, the future of finance is built on the power of the network.'
        }
        position={[-0.2, 2.2, 0]}
        skew={0}
        wx={1.9}
        icon={heart}
        icolor={'#76787B'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Back-up Connectivity.'}
        position={[0.5, 1.5, 0]}
        offs2={-0.02}
        offs={-0.08}
        dot={4}
        skew={0}
        wx={1.8}
        para={
          'Security and reliability are paramount for financial institutions. In addition to robust primary connectivity, high-speed disaster recovery circuits to completely redundant recovery co-lo sites are a must. Secondary enterprise-grade links, via separate carriers, technologies and infrastructure provide 100% SLA-backed auto-failover backup connectivity and reverts back to primary when connectivity is restored.'
        }
        icon={tech}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Seamless Experience.'}
        offs={-0.05}
        offs2={-0.03}
        dot={3}
        para={
          'The best connections are the ones that customers and staff never notice. SageCONNECT makes sure every transaction comes through flawlessly, every deposit, withdrawal, transfer, payment and application. A myriad of data are constantly swirling. This is where SageNet managed services devises a strategy using the right networks, the right hardware, and the right design for every branch.'
        }
        position={[3.2, 2.5, 0]}
        skew={0}
        wx={1.7}
        icon={location}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        offs={-0.05}
        dot={3}
        vidurl={
          'https://player.vimeo.com/video/557676802?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[3.2, 1.5, 0]}
        skew={0}
        wx={1}
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Customer Experience.'}
        vidurl={
          'https://player.vimeo.com/video/501964005?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.0, 2.3, 0]}
        skew={0}
        wx={1.5}
        icon={video}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        vidurl={
          'https://player.vimeo.com/video/557676948?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[2.2, 1.0, 0]}
        skew={0}
        wx={0.9}
        icon={video}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Touchless Kiosk Interaction.'}
        image={useTexture(touchless)}
        offs={-0.13}
        offs2={-0.04}
        dot={4}
        linka={ha}
        linki={obutton}
        para={
          'Increase safety for customers and employees by pairing wireless mobile technology with your kiosk menu. Allow customers to control kiosk boards and place orders without ever touching the screen. Orders are placed directly from their mobile device, or by in-person staff without a physical exchange. Reduce customer wait times. Make visits smoother and safer.'
        }
        position={[0.5, 1.7, 0]}
        skew={0}
        url={'https://sagenetvirtual.com/SageNet_WiFi-WP_NEW092319.pdf'}
        wx={2.0}
        icon={kiosk}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        linki={dlocation}
        offs={-0.1}
        offs2={-0.03}
        dot={4}
        linka={ha}
        url={
          'https://sagenetvirtual.com/SageNet_CaptivePortal_WP_NEW091919.pdf'
        }
        text={'Location Analytics.'}
        para={
          'The Wi-Fi location analytics market is growing 15% annually. Why? Because Wi-Fi location analytics uses multiple wireless access points and advanced algorithms to provide insights into customer behavior. Financial institutions can get info on how the customer travels – from outside, to which display? How long do they watch the digital sign? Do they grab the product? Where do they go next? Based on traffic patterns, retailers can rearrange promotions and signage to maximize results.'
        }
        position={[3.4, 1.7, 0]}
        skew={0}
        wx={1.5}
        icon={location}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        offs={-0.04}
        index={props.index}
        linki={wifib}
        offs2={-0.03}
        linka={ha}
        dot={2}
        url={'https://sagenetvirtual.com/SageNet_Wi-FiDS_090918-3.pdf'}
        text={'Guest Wi-Fi.'}
        para={
          'Guest Wi-Fi has moved from nice to have, to must have for C-stores. Loyalty apps offer coupons and personalize the guest experience and keep customers coming back for more. 40% of customers want to hear from “my C-store” monthly. Data collection through captive portals improves the customer experience and helps c-stores better improve operational efficiency.'
        }
        position={[4.5, 2.3, 0]}
        skew={0}
        wx={1}
        icon={wifi2}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Wi-Fi Security.'}
        offs={-0.08}
        offs2={-0.05}
        dot={2}
        position={[2.4, 2.3, 0]}
        skew={0}
        wx={1.2}
        para={
          'Firewall policies must block access to internal network segments while allowing guests access to the Internet. This prevents unauthorized users from accessing company data and helps prevent the spread of malware that may exist on visitor devices. Content filtering blocks access to inappropriate sites and materials that could damage the company’s brand or bring legal liability.'
        }
        icon={wifi}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function Forecourt(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
