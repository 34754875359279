/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import cstore from './ncstore2.glb';
import icon from './icons.png';
import fridge from './full cooler.jpg';
import sub from './ImageAssets/italiansub.jpg';
import covid1 from './ImageAssets/covid1.jpg';
//import covid2 from './ImageAssets/covid2.jpg'
import louis from './ImageAssets/easypeas.jpg';
import client from './ImageAssets/newclient.jpg';
import Screen from './Screen';
import ez from './Videos/easypeasy.webm';
import * as THREE from 'three';

function Flip(tex, material) {
  let t = useTexture(tex);
  t.wrapT = THREE.RepeatWrapping;
  t.repeat.y = -1;
  material.map = t;
}

export default function Store(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(cstore);

  materials['Glass.004'].transparent = true;
  materials['Glass.004'].opacity = 0.1;
  materials['Glass.004'].roughness = 0.2;
  materials['Glass.004'].metalness = 0.5;
  materials['signs.002'].transparent = true;
  materials['signs.002'].alphaMap = useTexture(icon);
  materials['fridge int.002'].metalness = 0.5;
  //materials['fridge int.002'].map = useTexture(fridge);
  materials['soda.003'].map = useTexture(fridge);
  materials['soda.003'].map = useTexture(fridge);
  materials['default'].roughness = 1;
  materials['default'].metalness = 0.3;
  materials['default'].color.set('rgb(200,200,200)');
  materials['Material #1431659628.003'].metalness = 0.3;
  materials['Material #1431659628.003'].roughness = 0.4;
  materials['Material #1431659657.003'].color.set('#f04a1d');
  materials['Material #1431659657.003'].roughness = 0.4;
  materials['Material #1431659657.003'].metalness = 0.2;
  materials['Chrome_Slightly_Worn.003'].roughness = 0.4;
  materials['Chrome_Slightly_Worn.003'].metalness = 0.7;

  let sandwich = useTexture(sub);
  const _sandwich = new THREE.MeshBasicMaterial();
  sandwich.wrapT = THREE.RepeatWrapping;
  sandwich.repeat.y = -1;
  _sandwich.map = sandwich;
  //materials['Material #2121708538.002'].map = sandwich;

  const _social = new THREE.MeshBasicMaterial();
  Flip(covid1, _social);
  materials['Material #2121708537.002'].metalness = 0.8;
  materials['Material #2121708537.002'].roughness = 0.3;
  //const _covid2 = new THREE.MeshBasicMaterial();

  //Flip(covid2, _covid2);

  const _louis = new THREE.MeshBasicMaterial();

  Flip(louis, _louis);

  const _client = new THREE.MeshBasicMaterial();
  Flip(client, _client);
  materials['plastic black.005'].roughness = 0.4;
  materials['plastic black.005'].metalness = 0.1;

  const grey = new THREE.MeshStandardMaterial();
  grey.color.set('rgb(50,50,50)');
  grey.metalness = 0.5;
  grey.roughness = 0.3;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        material={_social}
        geometry={nodes.Object057001.geometry}
        position={[-3.39, 1.67, -13.69]}
        rotation={[0, Math.PI * 0.5, 0.1]}
        scale={[1, 0.008, 0.022]}
      />
      <Screen
        url={ez}
        pbs={[1.77, 1]}
        position={[2.1, 2.08, -13.56]}
        rotation={[0.1, 0, 0]}
        scale={[1.2, 1.2, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        material={_louis}
        geometry={nodes.Object057001.geometry}
        position={[4.99, 1.65, -10.3]}
        rotation={[0, -2 * Math.PI, 0]}
        scale={[0.01, 0.0081, 0.022]}
      />
      <mesh
        castShadow
        receiveShadow
        material={_client}
        geometry={nodes.Object057001.geometry}
        position={[2.94, 1.13, -12.08]}
        rotation={[0, -2 * Math.PI, -0.55]}
        scale={[0.01, 0.0023, 0.0019]}
      />
      <mesh
        castShadow
        receiveShadow
        material={_sandwich}
        geometry={nodes.Object058001.geometry}
        position={[-6.1, 1.65, -13.69]}
        rotation={[0, Math.PI * 0.5, 0.1]}
        scale={[1, 0.0083, 0.024]}
      />
    </group>
  );
}

useGLTF.preload(cstore);
