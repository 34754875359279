import React, { useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import Sv from '../sagelabel/sv.jpg';
import Ss from '../sagelabel/ss.jpg';
import Sc from '../sagelabel/sc.jpg';
import Siw from '../sagelabel/siw.jpg';
import { Vector2 } from 'three';
import Forecourt from './Curbside';
import Foodservice from './POS';
import POP from './Drivethru_QSR';
import Drivethru from './Back';

export default function Cata(props) {
  const group = useRef();
  const [active, setActive] = useState(0);
  const [bang, setBang] = useState(true);

  function Label(props) {
    const [hover, setHover] = useState(false);
    const tex = useTexture(props.file);
    tex.center = new Vector2(0.5, 0.5);
    tex.repeat = new Vector2(1.5, 1.5);

    return (
      <mesh
        visible={true}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        onClick={() => setActive(props.index)}
        scale={props.scale}
        position={props.position}
      >
        <planeBufferGeometry />
        <meshBasicMaterial
          map={tex}
          attach="material"
          color={hover ? 'orange' : 'white'}
          transparent={true}
          opacity={0.8}
        />
      </mesh>
    );
  }

  return (
    <group
      position={props.position}
      rotation={props.rotation}
      visible={props.seen}
      ref={group}
    >
      <group position={[0, -3, 0]}>
        {props.index === 1 && (
          <Forecourt hide={(a) => setBang(a)} hidden={bang} index={active} />
        )}
        {props.index === 2 && (
          <Foodservice hide={(a) => setBang(a)} hidden={bang} index={active} />
        )}
        {props.index === 3 && (
          <POP hide={(a) => setBang(a)} hidden={bang} index={active} />
        )}
        {props.index === 4 && (
          <Drivethru hide={(a) => setBang(a)} hidden={bang} index={active} />
        )}
      </group>
      <group visible={bang}>
        <Label
          index={0}
          file={Sv}
          scale={[1.1, 0.35, 1]}
          position={props.index === 2 ? [0.0, 0, 0.01] : [-0.8, 0, 0.01]}
        />
        <Label
          index={1}
          file={Ss}
          scale={[1.4, 0.35, 1]}
          position={props.index === 2 ? [1.25, 0, 0] : [0.445, 0, 0]}
        />
        <Label
          index={2}
          file={Sc}
          scale={[1.4, 0.35, 1]}
          position={props.index === 2 ? [2.646, 0, 0.01] : [1.846, 0, 0.01]}
        />
        <Label
          index={3}
          file={Siw}
          scale={[2.1, 0.35, 1]}
          position={props.index === 2 ? [4.4, 0, 0] : [3.596, 0, 0]}
        />
      </group>
    </group>
  );
}
