import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import graph from '../sagelabel/Graph_icon.png';
import heart from '../sagelabel/heart.png';
import video from '../sagelabel/Video-icon.png';
import wifi from '../sagelabel/Secure Wi-Fi_Icon.png';
import ha from '../sagelabel/download_buttonA.png';
import { useTexture } from '@react-three/drei';
import location from '../sagelabel/Location_icon.png';
import skim from '../sagelabel/Skimming_icon.png';
import _skimming from '../sagelabel/skimming_button.png';
import emv from '../sagelabel/EMV_icon.png';
import SkimUI from '../SkimUI';
import demv from '../sagelabel/download_emv.png';
import seven from '../ImageAssets/724.png';
import network from '../sagelabel/Network_icon.png';
import screen from '../sagelabel/Screen_icon.png';
import portal from '../ImageAssets/Captive Portal.png';
import dlocation from '../sagelabel/download_location.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.05}
        dot={1}
        text={'Curbside Ordering/Status.'}
        para={
          'Combining mobile apps with curbside can lead to more than a 50% sales lift. At the curb, digital signage can offer info on ordering without the pressure of the drive-thru environment. With curbside ordering, you can increase convenience, minimize in-store queuing for increased safety, and ultimately increase basket sizes for shoppers who might not have otherwise gone inside.'
        }
        position={[-0.7, 2.3, 0]}
        skew={0}
        wx={1.8}
        icon={graph}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        vidurl={
          'https://player.vimeo.com/video/557675953?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[2.5, 1.4, 0]}
        skew={0}
        wx={0.9}
        icon={video}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        text={'Loyalty.'}
        dot={3}
        offs={-0.03}
        para={
          'It’s about brand affinity. With so many dining options, it’s critical to attract/retain the right customers. With digital signage, you can welcome customers to the store, showcase your brand voice, and highlight the deals and LTOs that keep them coming back. Loyal customers will drive & spend a bit more to buy from a brand they love.'
        }
        hidden={props.hidden}
        position={[0, 1.5, 0]}
        skew={0}
        wx={0.9}
        icon={location}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        offs2={0.02}
        hidden={props.hidden}
        text={'Promotions.'}
        dot={1}
        offs={-0.05}
        para={
          'Take advantage of this new “streetside” marketing venue. Bright, weatherproof digital signage helps reach hungry customers outside your store. Make updates to promotions with just a click. Interested in an LTO on coffee from 6-8 am? Schedule your BOGO bagels at the same time! Quickly switch between promotional offers on any goods at any time.'
        }
        position={[3.2, 2, 0]}
        skew={0}
        wx={1.3}
        icon={heart}
        icolor={'#8AD2D2'}
      />
    </>
  );
}
function Sagesec(props) {
  const stext =
    'Protect your guests and business from theft. Skimming occurs when fake card readers, cameras, or pin pads are used over your equipment, like an ATM or at the pump, and card numbers and pins are stolen for later, malicious use. Your customer loyalty is built on a positive relationship with your brand. Keep their money safe and your customer-base secure.';
  const etext =
    'The deadlines are quickly approaching to make your gas pumps EMV-compliant. With the new liability shift, starting April 17th, should fraudulent transactions take place at your pump, the financial burden becomes your own. These new mandates keep your customers’ data protected and you safe from extra charges. Sleep easier with a security plan in place.';
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Secure Wi-Fi.'}
        offs2={-0.03}
        offs={-0.05}
        para={
          'Guest Wi-Fi provides another avenue for promotions and building customer satisfaction. It also opens you up to numerous risks. Without proper restrictions, guests can access questionable sites, accidentally or purposely inject malware into your network, or even access proprietary info on your corporate network. Build trust with your customers by keeping their data safe.'
        }
        position={[0, 2.5, 0]}
        skew={0}
        wx={1.3}
        icon={wifi}
        icolor={'#72337F'}
      />
      <SkimUI
        index={props.index}
        para={stext}
        text={'Skimming.'}
        offs2={0.02}
        hide={props.hide}
        hidden={props.hidden}
        position={[0.2, 1.5, 0]}
        skew={0}
        wx={1}
        icon={skim}
        icolor={'#72337F'}
        linki={_skimming}
        linka={ha}
        vidurl={
          'https://player.vimeo.com/video/502220475?autoplay=1&title=0&byline=0&portrait=0'
        }
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        offs2={0.01}
        index={props.index}
        linki={demv}
        linka={ha}
        url={'https://sagenetvirtual.com/SageNet_EMV_WP_NEW110520.pdf'}
        text={'Outdoor-EMV.'}
        para={etext}
        position={[3.2, 2, 0]}
        skew={0}
        wx={1.2}
        icon={emv}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'PCI-Compliance.'}
        position={[2.7, 1.3, 0]}
        skew={0}
        wx={1.3}
        vidurl={
          'https://player.vimeo.com/video/501964324?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  const ctext =
    'Cash only makes up 15% of payments at the pump. So if you can’t process debit/credit cards, you may as well be out of gas. With the shift to digital signage and pump-side ordering, fast, reliable, secure connectivity is a c-store’s lifeline. High-speed broadband with automatic failover and 24/7 monitoring and field service ensures always-on connectivity. ';
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Network Operations Center.'}
        image={useTexture(seven)}
        offs={-0.07}
        dot={1}
        para={
          'Someone to watch over me. Whether in the store, at the pump or drive-thru, for PoS, digital signage or guest Wi-Fi, the network is the backbone of your business. The support of a 24/7 NOC is more than a luxury, it’s a necessity. Our engineers utilize advanced tools and systems to analyze, troubleshoot, remediate and optimize network performance.'
        }
        position={[-0.5, 1.5, 0]}
        skew={0}
        wx={1.9}
        icon={network}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.1}
        text={'Connectivity Certainty.'}
        offs2={-0.05}
        para={ctext}
        position={[3.5, 2.2, 0]}
        skew={0}
        wx={1.7}
        icon={network}
        icolor={'#76787B'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Multi-Site Management.'}
        position={[3.2, 1.5, 0]}
        skew={0}
        wx={1.7}
        vidurl={
          'https://player.vimeo.com/video/501964524?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  const ltext =
    'The Wi-Fi location analytics market is growing 15% annually. Here’s why. Wi-Fi location analytics uses multiple wireless access points and advanced algorithms to provide insights into customer behavior based on the user’s movement: From pump to store? Which aisle first? How many aisles, in what order? Time spent in location? In check-out?';

  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Captive Portal.'}
        dot={3}
        offs={-0.05}
        offs2={-0.02}
        para={
          'Captive portals present Wi-Fi users with a web page that requires some action before network access is granted. At the curb, it’s a powerful marketing tool as well as a network access mechanism, presenting the user with messaging based on location, time of day, or loyalty info while capturing valuable data to help personalize the customer experience.'
        }
        position={[0.3, 2.4, 0]}
        skew={0}
        wx={1.3}
        icon={screen}
        icolor={'#E79F37'}
        image={useTexture(portal)}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        linki={dlocation}
        offs={-0.1}
        offs2={-0.03}
        dot={4}
        linka={ha}
        url={
          'https://sagenetvirtual.com/SageNet_CaptivePortal_WP_NEW091919.pdf'
        }
        text={'Location Analytics.'}
        para={ltext}
        position={[0.4, 1.5, 0]}
        skew={0}
        wx={1.5}
        icon={location}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.05}
        text={'The Value Of Data @ Your Locations.'}
        position={[3.5, 2.5, 0]}
        skew={0}
        wx={2.3}
        vidurl={
          'https://player.vimeo.com/video/501963728?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.07}
        text={'The 3 Pillars Of IoT.'}
        position={[2.8, 1.6, 0]}
        skew={0}
        wx={1.5}
        vidurl={
          'https://player.vimeo.com/video/501963975?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function POP(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
