/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import server from './serverrack.glb'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(server)
  materials['03___Default____Standard__'].transparent = true;
  materials['03___Default____Standard__'].opacity = 0.3;
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={[0.0026, 0.0026, 0.0026]}>
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_1.geometry} material={materials['01___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_2.geometry} material={materials['01___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_3.geometry} material={materials['01___Default____Standard___ncl1_2']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_4.geometry} material={materials['01___Default____Standard___ncl1_3']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_5.geometry} material={materials['04___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_6.geometry} material={materials['02___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_7.geometry} material={materials['01___Default____Standard___ncl1_4']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_8.geometry} material={materials['03___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_9.geometry} material={materials['01___Default____Standard___ncl1_5']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_10.geometry} material={materials['04___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_11.geometry} material={materials['01___Default____Standard___ncl1_6']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_12.geometry} material={materials['04___Default____Standard___ncl1_2']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_13.geometry} material={materials['02___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_14.geometry} material={materials['01___Default____Standard___ncl1_7']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_15.geometry} material={materials['03___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_16.geometry} material={materials['04___Default____Standard___ncl1_3']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_17.geometry} material={materials['04___Default____Standard___ncl1_4']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_18.geometry} material={materials['04___Default____Standard___ncl1_5']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_19.geometry} material={materials['04___Default____Standard___ncl1_6']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_20.geometry} material={materials['04___Default____Standard___ncl1_7']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_21.geometry} material={materials['04___Default____Standard___ncl1_8']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_22.geometry} material={materials['04___Default____Standard___ncl1_9']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_23.geometry} material={materials['04___Default____Standard___ncl1_10']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_24.geometry} material={materials['04___Default____Standard___ncl1_11']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_25.geometry} material={materials['04___Default____Standard___ncl1_12']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_26.geometry} material={materials['04___Default____Standard___ncl1_13']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_27.geometry} material={materials['04___Default____Standard___ncl1_14']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_28.geometry} material={materials['04___Default____Standard___ncl1_15']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_29.geometry} material={materials['04___Default____Standard___ncl1_16']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_30.geometry} material={materials['04___Default____Standard___ncl1_17']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_31.geometry} material={materials['04___Default____Standard___ncl1_18']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_32.geometry} material={materials['04___Default____Standard___ncl1_19']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_33.geometry} material={materials['04___Default____Standard___ncl1_20']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_34.geometry} material={materials['04___Default____Standard___ncl1_21']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_35.geometry} material={materials['04___Default____Standard___ncl1_22']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_36.geometry} material={materials['16___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_37.geometry} material={materials['15___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_38.geometry} material={materials['15___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_39.geometry} material={materials['16___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_40.geometry} material={materials['15___Default____Standard___ncl1_2']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_41.geometry} material={materials['15___Default____Standard___ncl1_3']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_42.geometry} material={materials['16___Default____Standard___ncl1_2']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_43.geometry} material={materials['16___Default____Standard___ncl1_3']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_44.geometry} material={materials['07___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_45.geometry} material={materials['08___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_46.geometry} material={materials['14___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_47.geometry} material={materials['07___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_48.geometry} material={materials['10___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_49.geometry} material={materials['13___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_50.geometry} material={materials['07___Default____Standard___ncl1_2']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_51.geometry} material={materials['08___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_52.geometry} material={materials['14___Default____Standard___ncl1_1']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_53.geometry} material={materials['07___Default____Standard___ncl1_3']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_54.geometry} material={materials['09___Default____Standard__']} />
        <mesh castShadow receiveShadow  geometry={nodes.Data_rack_01_55.geometry} material={materials['11___Default____Standard__']} />
      </group>
    </group>
  )
}

useGLTF.preload(server)
