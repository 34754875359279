import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import location from '../sagelabel/Location_icon.png';
import video from '../sagelabel/Video-icon.png';
import wifi from '../sagelabel/Secure Wi-Fi_Icon.png';
import wifi2 from '../sagelabel/Wi-FI_icon.png';
import screen from '../sagelabel/Screen_icon.png';
import conn from '../sagelabel/Connectivity_icon.png';
import wifib from '../sagelabel/wifi_button.png';
import ha from '../sagelabel/download_buttonA.png';
import serviceb from '../sagelabel/services_button.png';
import sat from '../sagelabel/SAT_icon.png';
import pc from '../sagelabel/PCI_icon.png';
import { useTexture } from '@react-three/drei';
import _sat from '../ImageAssets/sat.png';
import cybersign from '../ImageAssets/cybersign.png';
import dlocation from '../sagelabel/download_location.png';
import hs from '../sagelabel/download_button.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Visualization Kiosks.'}
        dot={5}
        offs2={-0.03}
        offs={-0.08}
        para={
          'With digital kiosks, you can give your customers a way to see themselves with your products. Wondering if that couch is the right color for the space? Or if that shirt will bring out the color of your eyes? By utilizing larger format digital signage with the ability to scan a person or input a photo, bring your products to life and show customers the real-life application of their potential purchase.'
        }
        position={[0, 2.5, 0]}
        skew={0}
        wx={1.5}
        icon={wifi}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.02}
        dot={2}
        text={'Smart Shelves.'}
        para={
          'With 87% of shoppers now expecting digital content during their shopping experience, it is critical for the customer experience (& customer loyalty!) that retailers invest. With ads on small screens at the end of your shelves or electronic shelf labels (ESLs), keep your customers comprised of all of the great products and prices you offer.'
        }
        position={[3.2, 2, 0]}
        skew={0}
        wx={1.3}
        icon={pc}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.02}
        dot={2}
        text={'Screen Promotions.'}
        para={
          'What better way to show off a product’s “wow factor” than by having it sell itself. By incorporating a manufacturer’s product into the in-store digital experience, you can show off what the technology can do and increase interest and therefore sales. For example, if your shop sells digital, load it up with great content and leave it to do the selling while you and your staff focus on store operations.'
        }
        position={[0, 1.5, 0]}
        skew={0}
        wx={1.4}
        icon={pc}
        icolor={'#8AD2D2'}
      />
    </>
  );
}
function Sagesec(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Cyber For Signage.'}
        image={useTexture(cybersign)}
        dot={3}
        offs={-0.04}
        offs2={-0.01}
        para={
          'Any time you connect a device to your network, you’re introducing new security risks. Make sure your signage remains secure, and your screens only show the content you intended by securing the network and its connected devices. The only thing worse than no content, is a hacker highlighting the great deals at your competitor’s locations.'
        }
        position={[-0.3, 2.3, 0]}
        skew={0}
        wx={1.4}
        icon={screen}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        linki={hs}
        linka={ha}
        offs2={-0.03}
        hidden={props.hidden}
        text={'Expanded Cybersecurity.'}
        offs={-0.05}
        url={
          'https://sagenetvirtual.com/SageNet_6Paradigms6Tools_WP_NEW101620%20(5).pdf'
        }
        para={
          'As organizations have extended their networks to support new locations and growing numbers of users and devices, security vulnerabilities have also increased exponentially. Cybercriminals have taken advantage of the pandemic to exploit these vulnerabilities and introduce new threats. Keep all of the devices in your store, connected speakers, fridge monitoring tools, guest wi-fi, and so much more, protected.'
        }
        position={[-0.2, 1.5, 0]}
        skew={0}
        wx={1.9}
        icon={wifi}
        icolor={'#72337F'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        dot={3}
        offs2={-0.02}
        offs={-0.07}
        position={[2.7, 1.4, 0]}
        skew={0}
        vidurl={
          'https://player.vimeo.com/video/501964243?autoplay=1&title=0&byline=0&portrait=0'
        }
        wx={0.8}
        icon={video}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'V-SAT Connectivity.'}
        image={useTexture(_sat)}
        offs2={-0.05}
        dot={4}
        offs={-0.1}
        para={
          'When terrestrial connections are not an option for either primary or backup connectivity, SageSat can provide VSAT satellite technology to seamlessly connect any remote location with enterprise-grade broadband. Only SageNet has deep experience using terrestrial and non-terrestrial technologies to support all aspects  of c-store operations.'
        }
        position={[0.1, 1.5, 0]}
        skew={0}
        wx={1.4}
        icon={sat}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Connectivity Sanity.'}
        offs={-0.09}
        offs2={-0.05}
        dot={3}
        para={
          'With multiple carriers, vendors and compliance demands, managing a multi-site network can be a challenge. An MNSP provides a single point of contact for circuit provisioning, consolidated billing, help desk support, routine maintenance and emergency services. Advanced services include 24/7 network monitoring, PCI-compliance and SIEM and SOC services.'
        }
        linka={ha}
        linki={serviceb}
        url={'https://sagenetvirtual.com/SageNet_MNS_WP_NEW060319-1.pdf'}
        position={[3.5, 2.1, 0]}
        skew={0}
        wx={1.5}
        icon={conn}
        icolor={'#76787B'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.02}
        text={'V-SAT.'}
        vidurl={
          'https://player.vimeo.com/video/501964480?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[2.8, 1.5, 0]}
        skew={0}
        wx={0.8}
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        offs={-0.04}
        index={props.index}
        linki={wifib}
        offs2={-0.03}
        linka={ha}
        dot={2}
        url={'https://sagenetvirtual.com/SageNet_Wi-FiDS_090918-3.pdf'}
        text={'Guest Wi-Fi.'}
        para={
          'Guest Wi-Fi has moved from nice to have, to must have for C-stores. Loyalty apps offer coupons and personalize the guest experience and keep customers coming back for more. 40% of customers want to hear from “my C-store” monthly. Data collection through captive portals improves the customer experience and helps c-stores better improve operational efficiency.'
        }
        position={[0, 1.5, 0]}
        skew={0}
        wx={1}
        icon={wifi2}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        linki={dlocation}
        offs={-0.1}
        offs2={-0.03}
        dot={4}
        linka={ha}
        url={
          'https://sagenetvirtual.com/SageNet_CaptivePortal_WP_NEW091919.pdf'
        }
        text={'Location Analytics.'}
        para={
          'The Wi-Fi location analytics market is growing 15% annually. Here’s why. Wi-Fi location analytics uses multiple wireless access points and advanced algorithms to provide insights into customer behavior based on the user’s movement: From parking lot to the curb to the table? Time spent outside, inside? In check-out? In dining?'
        }
        position={[0.4, 2.2, 0]}
        skew={0}
        wx={1.5}
        icon={location}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.05}
        text={'The Value Of Data @ Your Locations.'}
        position={[3.5, 2.5, 0]}
        skew={0}
        wx={2.3}
        vidurl={
          'https://player.vimeo.com/video/501963728?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function Foodservice(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
