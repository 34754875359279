import React from 'react';
import { StageSpinner } from 'react-spinners-kit';
import styled from 'styled-components';
import logo from './SageNetblue.png';

const Fill = styled.div`
  position: fixed;
  z-index: ${(props) => props.z};
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  /*background-image: url(${logo});
background-position: center;
background-repeat: no-repeat;
background-size: 10%;*/
`;
const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    const { loading } = this.state;
    return (
      <Fill z={this.props.z}>
        <Center>
          <StageSpinner size={100} color="#f8981c" loading={loading} />
        </Center>
      </Fill>
    );
  }
}
