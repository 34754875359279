/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import soda from './dsoda.glb'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(soda)
  for (const property in materials) {
    materials[property].metalness = 0.1;
    materials[property].roughness = 0.2;
    //console.log(materials[property].metalness);
  }
  return (
    <group {...props} ref={group} {...props} dispose={null}>
      <group position={[0, 0.22, 0]} scale={[0.03, 0.03, 0.03]}>
        
        <mesh castShadow receiveShadow  material={materials.Cup_Mat} geometry={nodes.Coca_Cup001.geometry} position={[-19.26, 12.6, 6.22]} />
        <group position={[-12,0,0]}>
        <mesh castShadow receiveShadow  material={materials.Biscuit_Material} geometry={nodes.biscuit.geometry} position={[28.09, 8.85, 4.27]} />
        <mesh castShadow receiveShadow 
          material={materials.Vanilla_Cream_Material}
          geometry={nodes.Cream.geometry}
          position={[28.1, 13.68, 4.19]}
        />
        </group>
        <mesh castShadow receiveShadow 
          material={materials.Dispenser_metals_Mat}
          geometry={nodes.Dispenser_Metals.geometry}
          position={[21.8, 22.18, -12.08]}
        />
        <mesh castShadow receiveShadow 
          material={materials.Dispenser_Plastics_Mat}
          geometry={nodes.Dispenser_Plastics.geometry}
          position={[21.78, 22.28, -8.78]}
        />
        <mesh castShadow receiveShadow  material={materials.Soda_Mat} geometry={nodes.Soda_003.geometry} position={[-19.26, 17.01, 6.22]} />
        <mesh castShadow receiveShadow 
          material={materials.Machine_01_Mat}
          geometry={nodes.Soda_Machine_01.geometry}
          position={[-18.65, 21.56, -12.01]}
        />
        <mesh castShadow receiveShadow 
          material={materials.Machine_02_Mat}
          geometry={nodes.Soda_Machine_02.geometry}
          position={[-18.69, 21.89, -11.97]}
        />
      </group>
    </group>
  )
}

useGLTF.preload(soda)
