import styled, { css } from 'styled-components';
import logo from './SageNetblue2.png';
import welcome from './ImageAssets/Builton.png';
import learn from './ImageAssets/tolearnmore.png';

const Whitebg = styled.div`
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Shift = styled.div`
  position: fixed;
  top: 12%;
  width: 100%;
  height: 65%;
  overflow: scroll;
`;

const Fcol = styled.div`
  display: flex;
  flex-direction: column;
  top: 25%;
`;

const Simg = styled.img`
  width: 20%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.welcome &&
    css`
      width: 35%;
    `}
`;

const Text = styled.div`
  font-family: 'Arial';
  color: #006aa6;
  padding-left: 15%;
  padding-right: 15%;
  font-size: 17px;
  text-align: center;
  ${(props) =>
    props.primary &&
    css`
      line-height: 280%;
      font-size: 22px;
    `}
`;

export default function About() {
  return (
    <>
      <Whitebg>
        <Shift>
          <Fcol>
            <Simg src={logo} />

            <Simg welcome src={welcome} />
            <br />
            <br />
            <Text>
              <b>
                <u>Our Vision</u>
              </b>
              : To be the managed services benchmark; transforming organizations
              through Trusted Connections. We believe that by creating,
              discovering and nurturing trusted connections with our customers,
              associates and community, we enhance the world that connects us
              all.
            </Text>
            <br />
            <Text>
              As a leader in managed network and cybersecurity services, we
              connect, manage and protect technologies and devices across all
              across your enterprise. Our collaborative approach provides peace
              of mind and systems-confidence that empowers your organization to
              focus on its core mission.
            </Text>
            <br />
            <Text>
              SageNet offers world-class service and support via multiple
              US-based 24/7 Network Operations Centers (NOCs) and Security
              Operations Centers (SOCs), geographically-diverse teleports, a
              centralized National Logistics Center, multiple data centers, and
              a nationwide field service organization.
            </Text>
            <br />
            <Text>
              With a three-decade track record in managed services, SageNet
              boasts a long-term customer base that includes the nation’s
              largest retail, healthcare, financial, utilities and energy
              organizations. SageNet manages communications at more than 220,000
              locations. Headquartered in Tulsa, SageNet has regional offices in
              Washington, D.C., Atlanta, Chicago and Philadelphia.
            </Text>
            <br />
            <br />
            <Simg
              onClick={() => window.open('https://www.sagenet.com/', '_blank')}
              style={{ width: '45%' }}
              src={learn}
            />
          </Fcol>
        </Shift>
      </Whitebg>
    </>
  );
}
