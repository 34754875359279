import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import './styles.css';
import HUD from './Hud';
import Loading from './LoadingAni';
import Splash from './Splash';
import { Context } from './Context';

const Root = () => {
  // views: home, scene, about
  const [view, setView] = useState('splash');
  return (
    <Context.Provider value={{ view: view, setView: setView }}>
      <Splash />
      <Loading />
      <HUD />
    </Context.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
