import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import BackupUI from '../BackupUI';
import location from '../sagelabel/Location_icon.png';
import video from '../sagelabel/Video-icon.png';
import wifi from '../sagelabel/Secure Wi-Fi_Icon.png';
import screen from '../sagelabel/Screen_icon.png';
import ha from '../sagelabel/download_buttonA.png';
import signage from '../sagelabel/signage.png';
import tech from '../sagelabel/Tech_icon.png';
import sat from '../sagelabel/SAT_icon.png';
import kiosk from '../sagelabel/Kiosk_icon.png';
import clock from '../sagelabel/Clock_icon.png';
import parabutton from '../sagelabel/6para_button.png';
import obutton from '../sagelabel/optimize_button.png';
import sixtyfive from '../ImageAssets/65pk.png';
import { useTexture } from '@react-three/drei';
import touchless from '../ImageAssets/touchless.jpg';
import ass2 from '../ImageAssets/asessments.jpg';
import _sat from '../ImageAssets/sat.png';

function Sageview(props) {
  const daypart =
    'Want to push coffee in the morning? Sandwiches at lunch? Pretzels in the afternoon? With dayparting, these content switches are not only possible, but made simple. Automatically swap out content, depending on time of day, with a simple click in your Content Management System.';

  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Day-Parting.'}
        dot={1}
        para={daypart}
        offs={-0.03}
        offs2={-0.02}
        position={[0.1, 2.3, 0]}
        skew={0}
        linki={signage}
        linka={ha}
        url={
          'https://sagenetvirtual.com/SageNet_DigitalSignage_Value_WP_101014FINAL-2%20(1).pdf'
        }
        wx={1.1}
        icon={clock}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Kiosks.'}
        offs={-0.03}
        dot={100}
        para={
          'With digital kiosks, you can give your customers a way to place their orders while serving up promotions, ultimately increasing basket sizes. With self-order kiosks, you minimize person-to-person contact, avoid long lines/wait times, and with over 65pk of consumers saying they would visit more often if kiosks are offered, increase consumer satisfaction.'
        }
        position={[1.5, 1.5, 0]}
        skew={0}
        wx={0.8}
        icon={kiosk}
        icolor={'#8AD2D2'}
        image={useTexture(sixtyfive)}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Wi-Fi at Food Service.'}
        dot={2}
        offs2={0.03}
        position={[3.0, 2.3, 0]}
        skew={0}
        wx={1.5}
        vidurl={
          'https://player.vimeo.com/video/557769024?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#8AD2D2'}
      />
    </>
  );
}
function Sagesec(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.02}
        text={'Cyber Security.'}
        vidurl={
          'https://player.vimeo.com/video/557676127?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.2, 2, 0]}
        skew={0}
        wx={1.2}
        icon={video}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        para={
          'Assessments should be based on industry best-practices to ensure the best security posture for your store. Utilizing compliance frameworks like ISO 27001/27002, NIST 800-53, and PCI, your cyber partner can identify existing/potential gaps in your physical or online security. Those gaps are then triaged so any necessary remediation efforts can begin.'
        }
        text={'Assessments.'}
        image={useTexture(ass2)}
        position={[0.3, 1.4, 0]}
        skew={0}
        wx={1.1}
        icon={wifi}
        icolor={'#72337F'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Cyber For Kiosks.'}
        dot={3}
        offs2={-0.02}
        offs={-0.07}
        position={[2.7, 1.4, 0]}
        skew={0}
        para={
          'Any time a new device is connected to your network, you introduce new security risks. Make sure that your kiosks remain secure, that their screens only show the content you intend, and customers are protected from skimming or other methods of identity theft. Keep your customers’ info secure, and they’ll keep their brand loyalty.'
        }
        wx={1.4}
        icon={kiosk}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.07}
        text={'Empowering Connectivity.'}
        dot={0}
        para={
          'The pandemic led to several paradigm shifts for QSRs: health concerns, financial uncertainty, the need for agility, and the use of tech to accelerate change. However, robust connectivity remained an enabling force. From security to digital experience, frictionless checkout to analytics, the future of c-stores is built on the power of the network.'
        }
        position={[0.5, 1.8, 0]}
        url={
          'https://sagenetvirtual.com/SageNet_6Paradigms6Tools_WP_NEW101620%20(5).pdf'
        }
        linki={parabutton}
        linka={ha}
        skew={0}
        wx={1.8}
        icon={location}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'V-SAT Connectivity.'}
        image={useTexture(_sat)}
        offs2={-0.05}
        dot={4}
        offs={-0.1}
        para={
          'When terrestrial connections are not an option for either primary or backup connectivity, SageSat can provide VSAT satellite technology to seamlessly connect any remote location with enterprise-grade broadband. Only SageNet has deep experience using terrestrial and non-terrestrial technologies to support all aspects  of c-store operations.'
        }
        position={[0.1, 1.3, 0]}
        skew={0}
        wx={1.4}
        icon={sat}
        icolor={'#76787B'}
      />
      <BackupUI
        index={props.index}
        hide={props.hide}
        hidden={props.hidden}
        text={'Back-Up Connectivity.'}
        dot={4}
        offs={-0.08}
        offs2={-0.03}
        position={[3, 2, 0]}
        skew={0}
        wx={1.6}
        para={
          'Today’s C-store is simply network dependent. Backup connectivity is essential. It is also a requirement for EMV, MNSP and lottery compliance. Even at 99% uptime, several hours of outages monthly are commonplace. Backup via an alternate technology (typically cellular or satellite) automatically kicks-in when your primary connection fails.'
        }
        icon={tech}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.02}
        text={'V-SAT.'}
        vidurl={
          'https://player.vimeo.com/video/501964480?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[2.8, 1.5, 0]}
        skew={0}
        wx={0.8}
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Customer Experience.'}
        vidurl={
          'https://player.vimeo.com/video/501964005?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.2, 2.1, 0]}
        skew={0}
        wx={1.5}
        icon={video}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Touchless Kiosk Interaction.'}
        image={useTexture(touchless)}
        offs={-0.13}
        offs2={-0.04}
        dot={4}
        linka={ha}
        linki={obutton}
        para={
          'Increase safety for customers and employees by pairing wireless mobile technology with your kiosk menu. Allow customers to control kiosk boards and place orders without ever touching the screen. Orders are placed directly from their mobile device, or by in-person staff without a physical exchange. Reduce customer wait times. Make visits smoother and safer.'
        }
        position={[0.5, 1.4, 0]}
        skew={0}
        url={'https://sagenetvirtual.com/SageNet_WiFi-WP_NEW092319.pdf'}
        wx={2.0}
        icon={kiosk}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Wi-Fi Security.'}
        offs={-0.08}
        offs2={-0.05}
        dot={2}
        position={[2.7, 2, 0]}
        skew={0}
        wx={1.2}
        para={
          'Firewall policies must block access to internal network segments while allowing guests access to the Internet. This prevents unauthorized users from accessing company data and helps prevent the spread of malware that may exist on visitor devices. Content filtering blocks access to inappropriate sites and materials that could damage the company’s brand or bring legal liability.'
        }
        icon={wifi}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function Foodservice(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
