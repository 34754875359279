/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import city from './dmini.glb'
import folia from './folia.png'
import folic from './folic.png'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(city)
  for (const property in materials) {
    materials[property].metalness = 0.1;
    materials[property].roughness = 0.5;
    //console.log(materials[property].metalness);
  }
  nodes.ID4269_3.material.transparent = true;
  nodes.ID4269_3.material.alphaMap = useTexture(folia);
  nodes.ID4269_3.material.map = useTexture(folic);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Plane001.geometry} material={nodes.Plane001.material} position={[4.0708, 0.1, 2.5311]}>
        <group
          position={[-1.2658, 0.3195, -12.9022]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[0.0536, 0.0666, 0.0536]}>
          <mesh geometry={nodes.ID4314.geometry} material={nodes.ID4314.material} />
          <mesh geometry={nodes.ID4314_1.geometry} material={nodes.ID4314_1.material} />
          <mesh geometry={nodes.ID4314_2.geometry} material={nodes.ID4314_2.material} />
          <mesh geometry={nodes.ID4314_3.geometry} material={nodes.ID4314_3.material} />
          <mesh geometry={nodes.ID4314_4.geometry} material={nodes.ID4314_4.material} />
        </group>
        <group position={[0.6385, 0.3437, -2.6553]} rotation={[Math.PI, 1.5705, 0]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4305.geometry} material={nodes.ID4305.material} />
          <mesh geometry={nodes.ID4305_1.geometry} material={nodes.ID4305_1.material} />
          <mesh geometry={nodes.ID4305_2.geometry} material={nodes.ID4305_2.material} />
          <mesh geometry={nodes.ID4305_3.geometry} material={nodes.ID4305_3.material} />
          <mesh geometry={nodes.ID4305_4.geometry} material={nodes.ID4305_4.material} />
          <mesh geometry={nodes.ID4305_5.geometry} material={nodes.ID4305_5.material} />
          <mesh geometry={nodes.ID4305_6.geometry} material={nodes.ID4305_6.material} />
          <mesh geometry={nodes.ID4305_7.geometry} material={nodes.ID4305_7.material} />
        </group>
        <group position={[-6.5474, 0.3437, -0.2367]} rotation={[0, 0, -Math.PI]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4296.geometry} material={nodes.ID4296.material} />
          <mesh geometry={nodes.ID4296_1.geometry} material={nodes.ID4296_1.material} />
          <mesh geometry={nodes.ID4296_2.geometry} material={nodes.ID4296_2.material} />
          <mesh geometry={nodes.ID4296_3.geometry} material={nodes.ID4296_3.material} />
          <mesh geometry={nodes.ID4296_4.geometry} material={nodes.ID4296_4.material} />
          <mesh geometry={nodes.ID4296_5.geometry} material={nodes.ID4296_5.material} />
          <mesh geometry={nodes.ID4296_6.geometry} material={nodes.ID4296_6.material} />
          <mesh geometry={nodes.ID4296_7.geometry} material={nodes.ID4296_7.material} />
          <mesh geometry={nodes.ID4296_8.geometry} material={nodes.ID4296_8.material} />
          <mesh geometry={nodes.ID4296_9.geometry} material={nodes.ID4296_9.material} />
          <mesh geometry={nodes.ID4296_10.geometry} material={nodes.ID4296_10.material} />
          <mesh geometry={nodes.ID4296_11.geometry} material={nodes.ID4296_11.material} />
        </group>
      </mesh>
      <mesh geometry={nodes.Plane002.geometry} material={nodes.Plane002.material} position={[-1.7, 0.1, -3.24]}>
        <group
          position={[-1.2658, 0.3195, -12.5622]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[0.0536, 0.0666, 0.0536]}>
          <mesh geometry={nodes.ID4314.geometry} material={nodes.ID4314.material} />
          <mesh geometry={nodes.ID4314_1.geometry} material={nodes.ID4314_1.material} />
          <mesh geometry={nodes.ID4314_2.geometry} material={nodes.ID4314_2.material} />
          <mesh geometry={nodes.ID4314_3.geometry} material={nodes.ID4314_3.material} />
          <mesh geometry={nodes.ID4314_4.geometry} material={nodes.ID4314_4.material} />
        </group>
        <group position={[0.6385, 0.3437, -2.6553]} rotation={[Math.PI, 1.5705, 0]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4305.geometry} material={nodes.ID4305.material} />
          <mesh geometry={nodes.ID4305_1.geometry} material={nodes.ID4305_1.material} />
          <mesh geometry={nodes.ID4305_2.geometry} material={nodes.ID4305_2.material} />
          <mesh geometry={nodes.ID4305_3.geometry} material={nodes.ID4305_3.material} />
          <mesh geometry={nodes.ID4305_4.geometry} material={nodes.ID4305_4.material} />
          <mesh geometry={nodes.ID4305_5.geometry} material={nodes.ID4305_5.material} />
          <mesh geometry={nodes.ID4305_6.geometry} material={nodes.ID4305_6.material} />
          <mesh geometry={nodes.ID4305_7.geometry} material={nodes.ID4305_7.material} />
        </group>
        <group position={[-6.5474, 0.3437, -0.2367]} rotation={[0, 0, -Math.PI]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4296.geometry} material={nodes.ID4296.material} />
          <mesh geometry={nodes.ID4296_1.geometry} material={nodes.ID4296_1.material} />
          <mesh geometry={nodes.ID4296_2.geometry} material={nodes.ID4296_2.material} />
          <mesh geometry={nodes.ID4296_3.geometry} material={nodes.ID4296_3.material} />
          <mesh geometry={nodes.ID4296_4.geometry} material={nodes.ID4296_4.material} />
          <mesh geometry={nodes.ID4296_5.geometry} material={nodes.ID4296_5.material} />
          <mesh geometry={nodes.ID4296_6.geometry} material={nodes.ID4296_6.material} />
          <mesh geometry={nodes.ID4296_7.geometry} material={nodes.ID4296_7.material} />
          <mesh geometry={nodes.ID4296_8.geometry} material={nodes.ID4296_8.material} />
          <mesh geometry={nodes.ID4296_9.geometry} material={nodes.ID4296_9.material} />
          <mesh geometry={nodes.ID4296_10.geometry} material={nodes.ID4296_10.material} />
          <mesh geometry={nodes.ID4296_11.geometry} material={nodes.ID4296_11.material} />
        </group>
        <group position={[0.7101, 0.35, 0.5259]} rotation={[0, 1.5705, 0]} scale={[0.0015, 0.0015, 0.0017]}>
          <mesh geometry={nodes.ID4386.geometry} material={nodes.ID4386.material} />
          <mesh geometry={nodes.ID4386_1.geometry} material={nodes.ID4386_1.material} />
          <mesh geometry={nodes.ID4386_2.geometry} material={nodes.ID4386_2.material} />
          <mesh geometry={nodes.ID4386_3.geometry} material={nodes.ID4386_3.material} />
          <mesh geometry={nodes.ID4386_4.geometry} material={nodes.ID4386_4.material} />
        </group>
      </mesh>
      <mesh geometry={nodes.Plane.geometry} material={nodes.Plane.material} position={[-1.86, 0, 2.42]}>
        <group position={[0.625, 0.4479, -0.5098]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.0014, 0.0014, 0.0014]}>
          <mesh geometry={nodes.ID4404.geometry} material={nodes.ID4404.material} />
          <mesh geometry={nodes.ID4404_1.geometry} material={nodes.ID4404_1.material} />
        </group>
        <group position={[1.2956, 1.0182, -1.7525]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4197.geometry} material={nodes.ID4197.material} />
          <mesh geometry={nodes.ID4197_1.geometry} material={nodes.ID4197_1.material} />
          <mesh geometry={nodes.ID4197_2.geometry} material={nodes.ID4197_2.material} />
          <mesh geometry={nodes.ID4197_3.geometry} material={nodes.ID4197_3.material} />
        </group>
        <group position={[6.7037, 0.4437, -0.125]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4125.geometry} material={nodes.ID4125.material} />
          <mesh geometry={nodes.ID4125_1.geometry} material={nodes.ID4125_1.material} />
          <mesh geometry={nodes.ID4125_2.geometry} material={nodes.ID4125_2.material} />
          <mesh geometry={nodes.ID4125_3.geometry} material={nodes.ID4125_3.material} />
          <mesh geometry={nodes.ID4125_4.geometry} material={nodes.ID4125_4.material} />
          <mesh geometry={nodes.ID4125_5.geometry} material={nodes.ID4125_5.material} />
          <mesh geometry={nodes.ID4125_6.geometry} material={nodes.ID4125_6.material} />
          <mesh geometry={nodes.ID4125_7.geometry} material={nodes.ID4125_7.material} />
          <mesh geometry={nodes.ID4125_8.geometry} material={nodes.ID4125_8.material} />
          <mesh geometry={nodes.ID4125_9.geometry} material={nodes.ID4125_9.material} />
          <mesh geometry={nodes.ID4125_10.geometry} material={nodes.ID4125_10.material} />
        </group>
        <group position={[-2.4123, 0.4437, -0.8906]} rotation={[-Math.PI, 0, 0]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4260.geometry} material={nodes.ID4260.material} />
          <mesh geometry={nodes.ID4260_1.geometry} material={nodes.ID4260_1.material} />
          <mesh geometry={nodes.ID4260_2.geometry} material={nodes.ID4260_2.material} />
          <mesh geometry={nodes.ID4260_3.geometry} material={nodes.ID4260_3.material} />
          <mesh geometry={nodes.ID4260_4.geometry} material={nodes.ID4260_4.material} />
        </group>
        <group position={[4.3984, 0.4198, 8.5905]} scale={[0.0536, 0.0666, 0.0536]}>
          <mesh geometry={nodes.ID4269.geometry} material={nodes.ID4269.material} />
          <mesh geometry={nodes.ID4269_1.geometry} material={nodes.ID4269_1.material} />
          <mesh geometry={nodes.ID4269_2.geometry} material={nodes.ID4269_2.material} />
          <mesh geometry={nodes.ID4269_3.geometry} material={nodes.ID4269_3.material} />
        </group>
        <mesh
          geometry={nodes.GlassBG27C1.geometry}
          material={nodes.GlassBG27C1.material}
          position={[0.8956, 1.023, -2.2342]}
          rotation={[-Math.PI / 2, 0, -Math.PI]}
          scale={[-0.0015, -0.0015, -0.0015]}
        />
        <mesh
          geometry={nodes.GlassKC9BG03.geometry}
          material={nodes.GlassKC9BG03.material}
          position={[-0.3282, 0.6069, -2.1484]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.0015, 0.0015, 0.0015]}
        />
        <mesh
          geometry={nodes.glassSolar_panels.geometry}
          material={nodes.glassSolar_panels.material}
          position={[1.6499, 1.6575, -1.8623]}
          rotation={[Math.PI / 2, 1.3156, -Math.PI / 2]}
          scale={[0.0015, 0.0015, 0.0015]}
        />
        <group
          position={[1.8048, 0.9748, -1.9382]}
          rotation={[Math.PI, -1.5705, 0]}
          scale={[-0.0016, -0.0015, -0.0016]}>
          <mesh geometry={nodes.ID4215.geometry} material={nodes.ID4215.material} />
          <mesh geometry={nodes.ID4215_1.geometry} material={nodes.ID4215_1.material} />
          <mesh geometry={nodes.ID4215_2.geometry} material={nodes.ID4215_2.material} />
          <mesh geometry={nodes.ID4215_3.geometry} material={nodes.ID4215_3.material} />
          <mesh geometry={nodes.ID4215_4.geometry} material={nodes.ID4215_4.material} />
        </group>
        <group position={[1.4961, 0.6236, 1.6351]} rotation={[0, -1.5705, 0]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4161.geometry} material={nodes.ID4161.material} />
          <mesh geometry={nodes.ID4161_1.geometry} material={nodes.ID4161_1.material} />
          <mesh geometry={nodes.ID4161_2.geometry} material={nodes.ID4161_2.material} />
          <mesh geometry={nodes.ID4161_3.geometry} material={nodes.ID4161_3.material} />
          <mesh geometry={nodes.ID4161_4.geometry} material={nodes.ID4161_4.material} />
        </group>
        <mesh
          geometry={nodes.KC8BG06_detail.geometry}
          material={nodes.KC8BG06_detail.material}
          position={[1.4961, 0.6236, 1.6351]}
          rotation={[0, -1.5705, 0]}
          scale={[0.0015, 0.0015, 0.0015]}
        />
        <group position={[0.6626, 0.6069, -1.5654]} rotation={[0, 1.5705, 0]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4179.geometry} material={nodes.ID4179.material} />
          <mesh geometry={nodes.ID4179_1.geometry} material={nodes.ID4179_1.material} />
          <mesh geometry={nodes.ID4179_2.geometry} material={nodes.ID4179_2.material} />
          <mesh geometry={nodes.ID4179_3.geometry} material={nodes.ID4179_3.material} />
          <mesh geometry={nodes.ID4179_4.geometry} material={nodes.ID4179_4.material} />
          <mesh geometry={nodes.ID4179_5.geometry} material={nodes.ID4179_5.material} />
        </group>
      </mesh>
      <mesh
        geometry={nodes.Plane003.geometry}
        material={nodes.Plane003.material}
        position={[3.9287, 0, -3.6022]}
        rotation={[Math.PI, 0, Math.PI]}>
        <group position={[0.625, 0.4479, -0.5098]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.0014, 0.0014, 0.0014]}>
          <mesh geometry={nodes.ID4404.geometry} material={nodes.ID4404.material} />
          <mesh geometry={nodes.ID4404_1.geometry} material={nodes.ID4404_1.material} />
        </group>
        <group position={[1.2956, 0.7069, -1.7525]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4197.geometry} material={nodes.ID4197.material} />
          <mesh geometry={nodes.ID4197_1.geometry} material={nodes.ID4197_1.material} />
          <mesh geometry={nodes.ID4197_2.geometry} material={nodes.ID4197_2.material} />
          <mesh geometry={nodes.ID4197_3.geometry} material={nodes.ID4197_3.material} />
        </group>
        <group position={[6.4037, 0.4437, -0.125]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4125.geometry} material={nodes.ID4125.material} />
          <mesh geometry={nodes.ID4125_1.geometry} material={nodes.ID4125_1.material} />
          <mesh geometry={nodes.ID4125_2.geometry} material={nodes.ID4125_2.material} />
          <mesh geometry={nodes.ID4125_3.geometry} material={nodes.ID4125_3.material} />
          <mesh geometry={nodes.ID4125_4.geometry} material={nodes.ID4125_4.material} />
          <mesh geometry={nodes.ID4125_5.geometry} material={nodes.ID4125_5.material} />
          <mesh geometry={nodes.ID4125_6.geometry} material={nodes.ID4125_6.material} />
          <mesh geometry={nodes.ID4125_7.geometry} material={nodes.ID4125_7.material} />
          <mesh geometry={nodes.ID4125_8.geometry} material={nodes.ID4125_8.material} />
          <mesh geometry={nodes.ID4125_9.geometry} material={nodes.ID4125_9.material} />
          <mesh geometry={nodes.ID4125_10.geometry} material={nodes.ID4125_10.material} />
        </group>
        <group position={[-2.4123, 0.4437, -0.8906]} rotation={[-Math.PI, 0, 0]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4260.geometry} material={nodes.ID4260.material} />
          <mesh geometry={nodes.ID4260_1.geometry} material={nodes.ID4260_1.material} />
          <mesh geometry={nodes.ID4260_2.geometry} material={nodes.ID4260_2.material} />
          <mesh geometry={nodes.ID4260_3.geometry} material={nodes.ID4260_3.material} />
          <mesh geometry={nodes.ID4260_4.geometry} material={nodes.ID4260_4.material} />
        </group>
        <group position={[4.3984, 0.4198, 8.5905]} scale={[0.0536, 0.0666, 0.0536]}>
          <mesh geometry={nodes.ID4269.geometry} material={nodes.ID4269.material} />
          <mesh geometry={nodes.ID4269_1.geometry} material={nodes.ID4269_1.material} />
          <mesh geometry={nodes.ID4269_2.geometry} material={nodes.ID4269_2.material} />
          <mesh geometry={nodes.ID4269_3.geometry} material={nodes.ID4269_3.material} />
        </group>
        <group position={[1.4961, 0.6236, 1.6351]} rotation={[0, -1.5705, 0]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4161.geometry} material={nodes.ID4161.material} />
          <mesh geometry={nodes.ID4161_1.geometry} material={nodes.ID4161_1.material} />
          <mesh geometry={nodes.ID4161_2.geometry} material={nodes.ID4161_2.material} />
          <mesh geometry={nodes.ID4161_3.geometry} material={nodes.ID4161_3.material} />
          <mesh geometry={nodes.ID4161_4.geometry} material={nodes.ID4161_4.material} />
        </group>
        <group position={[0.6626, 0.6069, -1.5654]} rotation={[0, 1.5705, 0]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4179.geometry} material={nodes.ID4179.material} />
          <mesh geometry={nodes.ID4179_1.geometry} material={nodes.ID4179_1.material} />
          <mesh geometry={nodes.ID4179_2.geometry} material={nodes.ID4179_2.material} />
          <mesh geometry={nodes.ID4179_3.geometry} material={nodes.ID4179_3.material} />
          <mesh geometry={nodes.ID4179_4.geometry} material={nodes.ID4179_4.material} />
          <mesh geometry={nodes.ID4179_5.geometry} material={nodes.ID4179_5.material} />
        </group>
        <mesh
          geometry={nodes.B_court_fence001.geometry}
          material={nodes.B_court_fence001.material}
          position={[0.625, 0.4479, -0.5098]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[0.0014, 0.0014, 0.0014]}
        />
        <group position={[0.625, 0.4479, -0.5098]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.0014, 0.0014, 0.0014]}>
          <mesh geometry={nodes.ID4413.geometry} material={nodes.ID4413.material} />
          <mesh geometry={nodes.ID4413_1.geometry} material={nodes.ID4413_1.material} />
          <mesh geometry={nodes.ID4413_2.geometry} material={nodes.ID4413_2.material} />
        </group>
        <group position={[3.3277, 0.4437, -2.5906]} rotation={[-Math.PI, 0, 0]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4260001.geometry} material={nodes.ID4260001.material} />
          <mesh geometry={nodes.ID4260001_1.geometry} material={nodes.ID4260001_1.material} />
          <mesh geometry={nodes.ID4260001_2.geometry} material={nodes.ID4260001_2.material} />
          <mesh geometry={nodes.ID4260001_3.geometry} material={nodes.ID4260001_3.material} />
          <mesh geometry={nodes.ID4260001_4.geometry} material={nodes.ID4260001_4.material} />
          <mesh geometry={nodes.ID4260001_5.geometry} material={nodes.ID4260001_5.material} />
        </group>
      </mesh>
      <group position={[-0.3615, 0.7444, -4.2762]} rotation={[0, -0.0244, 0]} scale={[0.0006, 0.0006, 0.0006]}>
        <mesh geometry={nodes.Mesh001.geometry} material={materials.Orange} />
        <mesh geometry={nodes.Mesh001_1.geometry} material={nodes.Mesh001_1.material} />
        <mesh geometry={nodes.Mesh001_2.geometry} material={nodes.Mesh001_2.material} />
        <mesh geometry={nodes.Mesh001_3.geometry} material={nodes.Mesh001_3.material} />
        <mesh geometry={nodes.Mesh001_4.geometry} material={nodes.Mesh001_4.material} />
        <mesh geometry={nodes.Mesh001_5.geometry} material={nodes.Mesh001_5.material} />
        <mesh geometry={nodes.Mesh001_6.geometry} material={nodes.Mesh001_6.material} />
        <mesh geometry={nodes.Mesh001_7.geometry} material={nodes.Mesh001_7.material} />
      </group>
      <group
        position={[3.622, 1.5592, -5.2557]}
        rotation={[Math.PI, -1.5362, Math.PI]}
        scale={[0.0008, 0.0014, 0.0013]}>
        <mesh geometry={nodes.ID3693001.geometry} material={nodes.ID3693001.material} />
        <mesh geometry={nodes.ID3693001_1.geometry} material={nodes.ID3693001_1.material} />
        <mesh geometry={nodes.ID3693001_2.geometry} material={nodes.ID3693001_2.material} />
        <mesh geometry={nodes.ID3693001_3.geometry} material={nodes.ID3693001_3.material} />
        <mesh geometry={nodes.ID3693001_4.geometry} material={nodes.ID3693001_4.material} />
      </group>
      <group position={[-0.2545, 0.4441, -1.6039]} rotation={[Math.PI / 2, 0, 0]} scale={[0.0007, 0.0007, 0.0007]}>
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001.geometry}
          material={nodes.exterior_walls_column_exterior_walls2_pivot001.material}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_1.geometry}
          material={nodes.exterior_walls_column_exterior_walls2_pivot001_1.material}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_2.geometry}
          material={nodes.exterior_walls_column_exterior_walls2_pivot001_2.material}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_3.geometry}
          material={nodes.exterior_walls_column_exterior_walls2_pivot001_3.material}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_4.geometry}
          material={nodes.exterior_walls_column_exterior_walls2_pivot001_4.material}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_5.geometry}
          material={nodes.exterior_walls_column_exterior_walls2_pivot001_5.material}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_6.geometry}
          material={nodes.exterior_walls_column_exterior_walls2_pivot001_6.material}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_7.geometry}
          material={nodes.exterior_walls_column_exterior_walls2_pivot001_7.material}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_8.geometry}
          material={materials['Crubside_Screen.001']}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_9.geometry}
          material={materials['Crubside_Screen.002']}
        />
        <mesh
          geometry={nodes.exterior_walls_column_exterior_walls2_pivot001_10.geometry}
          material={materials['Crubside_Screen.003']}
        />
      </group>
      <group position={[2.7895, 0.4379, 0.7192]} rotation={[Math.PI / 2, 0, 3.1415]} scale={[0.0413, 0.0413, 0.0413]}>
        <mesh geometry={nodes.Main_Roof_Line1919.geometry} material={nodes.Main_Roof_Line1919.material} />
        <mesh geometry={nodes.Main_Roof_Line1919_1.geometry} material={nodes.Main_Roof_Line1919_1.material} />
        <mesh geometry={nodes.Main_Roof_Line1919_2.geometry} material={nodes.Main_Roof_Line1919_2.material} />
        <mesh geometry={nodes.Main_Roof_Line1919_3.geometry} material={nodes.ID4179_3.material} />
        <mesh geometry={nodes.Main_Roof_Line1919_4.geometry} material={materials.Brick_Block} />
        <mesh geometry={nodes.Main_Roof_Line1919_5.geometry} material={materials.Blue_Color} />
        <mesh geometry={nodes.Main_Roof_Line1919_6.geometry} material={nodes.Main_Roof_Line1919_6.material} />
        <mesh geometry={nodes.Main_Roof_Line1919_7.geometry} material={nodes.Main_Roof_Line1919_7.material} />
        <mesh geometry={nodes.Main_Roof_Line1919_8.geometry} material={nodes.Main_Roof_Line1919_8.material} />
        <mesh geometry={nodes.Main_Roof_Line1919_9.geometry} material={materials['Crubside_Screen.004']} />
        <mesh geometry={nodes.Main_Roof_Line1919_10.geometry} material={materials['Crubside_Screen.005']} />
        <mesh geometry={nodes.Main_Roof_Line1919_11.geometry} material={materials['Crubside_Screen.006']} />
      </group>
      <group position={[-0.8148, 0.4607, 1.8817]} rotation={[Math.PI / 2, 0, -1.5708]} scale={[0.0572, 0.0572, 0.0572]}>
        <mesh geometry={nodes.Roof_Mesh027.geometry} material={nodes.Roof_Mesh027.material} />
        <mesh geometry={nodes.Roof_Mesh027_1.geometry} material={nodes.Roof_Mesh027_1.material} />
        <mesh geometry={nodes.Roof_Mesh027_2.geometry} material={nodes.Roof_Mesh027_2.material} />
        <mesh geometry={nodes.Roof_Mesh027_3.geometry} material={nodes.Roof_Mesh027_3.material} />
        <mesh geometry={nodes.Roof_Mesh027_4.geometry} material={materials.Blue} />
        <mesh geometry={nodes.Roof_Mesh027_5.geometry} material={nodes.Roof_Mesh027_5.material} />
        <mesh geometry={nodes.Roof_Mesh027_6.geometry} material={materials.Gutters} />
        <mesh geometry={nodes.Roof_Mesh027_7.geometry} material={nodes.Roof_Mesh027_7.material} />
        <mesh geometry={nodes.Roof_Mesh027_8.geometry} material={nodes.Roof_Mesh027_8.material} />
        <mesh geometry={nodes.Roof_Mesh027_9.geometry} material={nodes.Roof_Mesh027_9.material} />
        <mesh geometry={nodes.Roof_Mesh027_10.geometry} material={nodes.Roof_Mesh027_10.material} />
        <mesh geometry={nodes.Roof_Mesh027_11.geometry} material={nodes.Roof_Mesh027_11.material} />
        <mesh geometry={nodes.Roof_Mesh027_12.geometry} material={materials.Logo} />
        <mesh geometry={nodes.Roof_Mesh027_13.geometry} material={nodes.Roof_Mesh027_13.material} />
        <mesh geometry={nodes.Roof_Mesh027_14.geometry} material={materials.Yellow} />
        <mesh geometry={nodes.Roof_Mesh027_15.geometry} material={materials.Blue_sign} />
        <mesh geometry={nodes.Roof_Mesh027_16.geometry} material={materials['Crubside_Screen.007']} />
        <mesh geometry={nodes.Roof_Mesh027_17.geometry} material={materials['Crubside_Screen.008']} />
        <mesh geometry={nodes.Roof_Mesh027_18.geometry} material={materials['Crubside_Screen.009']} />
      </group>
      <group position={[3.622, 1.5592, 6.2741]} rotation={[Math.PI, -1.5362, Math.PI]} scale={[0.0008, 0.0014, 0.0013]}>
        <mesh geometry={nodes.ID3693002.geometry} material={nodes.ID3693002.material} />
        <mesh geometry={nodes.ID3693002_1.geometry} material={nodes.ID3693002_1.material} />
        <mesh geometry={nodes.ID3693002_2.geometry} material={nodes.ID3693002_2.material} />
        <mesh geometry={nodes.ID3693002_3.geometry} material={nodes.ID3693002_3.material} />
        <mesh geometry={nodes.ID3693002_4.geometry} material={nodes.ID3693002_4.material} />
      </group>
      <mesh geometry={nodes.Plane005.geometry} material={nodes.Plane005.material} position={[-1.7, 0.1, 8.2898]}>
        <group position={[-6.5474, 0.3437, -0.2367]} rotation={[0, 0, -Math.PI]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4296001.geometry} material={nodes.ID4296001.material} />
          <mesh geometry={nodes.ID4296001_1.geometry} material={nodes.ID4296001_1.material} />
          <mesh geometry={nodes.ID4296001_2.geometry} material={nodes.ID4296001_2.material} />
          <mesh geometry={nodes.ID4296001_3.geometry} material={materials['SideWalk.001']} />
          <mesh geometry={nodes.ID4296001_4.geometry} material={nodes.ID4296001_4.material} />
          <mesh geometry={nodes.ID4296001_5.geometry} material={nodes.ID4296001_5.material} />
          <mesh geometry={nodes.ID4296001_6.geometry} material={nodes.ID4296001_6.material} />
          <mesh geometry={nodes.ID4296001_7.geometry} material={nodes.ID4296001_7.material} />
          <mesh geometry={nodes.ID4296001_8.geometry} material={nodes.ID4296001_8.material} />
          <mesh geometry={nodes.ID4296001_9.geometry} material={nodes.ID4296001_9.material} />
          <mesh geometry={nodes.ID4296001_10.geometry} material={nodes.ID4296001_10.material} />
        </group>
        <group position={[0.7101, 0.35, 0.5259]} rotation={[0, 1.5705, 0]} scale={[0.0015, 0.0015, 0.0017]}>
          <mesh geometry={nodes.ID4386001.geometry} material={nodes.ID4386001.material} />
          <mesh geometry={nodes.ID4386001_1.geometry} material={nodes.ID4386001_1.material} />
          <mesh geometry={nodes.ID4386001_2.geometry} material={nodes.ID4386001_2.material} />
          <mesh geometry={nodes.ID4386001_3.geometry} material={nodes.ID4386001_3.material} />
          <mesh geometry={nodes.ID4386001_4.geometry} material={nodes.ID4386001_4.material} />
        </group>
      </mesh>
      <mesh
        geometry={nodes.Plane004.geometry}
        material={nodes.Plane004.material}
        position={[3.9287, 0, 7.9276]}
        rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          geometry={nodes.B_court_fence002.geometry}
          material={nodes.B_court_fence002.material}
          position={[0.625, 0.4479, -0.5098]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[0.0014, 0.0014, 0.0014]}
        />
        <mesh
          geometry={nodes.B_court_lights002.geometry}
          material={nodes.B_court_lights002.material}
          position={[0.625, 0.4479, -0.5098]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[0.0014, 0.0014, 0.0014]}
        />
        <group position={[0.625, 0.4479, -0.5098]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.0014, 0.0014, 0.0014]}>
          <mesh geometry={nodes.ID4413001.geometry} material={nodes.ID4413001.material} />
          <mesh geometry={nodes.ID4413001_1.geometry} material={nodes.ID4413001_1.material} />
          <mesh geometry={nodes.ID4413001_2.geometry} material={nodes.ID4413001_2.material} />
        </group>
        <group position={[6.4037, 0.4437, -0.125]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4125001.geometry} material={nodes.ID4125001.material} />
          <mesh geometry={nodes.ID4125001_1.geometry} material={nodes.ID4125001_1.material} />
          <mesh geometry={nodes.ID4125001_2.geometry} material={nodes.ID4125001_2.material} />
          <mesh geometry={nodes.ID4125001_3.geometry} material={nodes.ID4125001_3.material} />
          <mesh geometry={nodes.ID4125001_4.geometry} material={nodes.ID4125001_4.material} />
          <mesh geometry={nodes.ID4125001_5.geometry} material={nodes.ID4125001_5.material} />
          <mesh geometry={nodes.ID4125001_6.geometry} material={nodes.ID4125001_6.material} />
          <mesh geometry={nodes.ID4125001_7.geometry} material={nodes.ID4125001_7.material} />
          <mesh geometry={nodes.ID4125001_8.geometry} material={nodes.ID4125001_8.material} />
          <mesh geometry={nodes.ID4125001_9.geometry} material={nodes.ID4125001_9.material} />
          <mesh geometry={nodes.ID4125001_10.geometry} material={nodes.ID4125001_10.material} />
        </group>
        <group position={[3.3277, 0.4437, -2.5906]} rotation={[-Math.PI, 0, 0]} scale={[-0.0015, -0.0015, -0.0015]}>
          <mesh geometry={nodes.ID4260002.geometry} material={nodes.ID4260002.material} />
          <mesh geometry={nodes.ID4260002_1.geometry} material={nodes.ID4260002_1.material} />
          <mesh geometry={nodes.ID4260002_2.geometry} material={nodes.ID4260002_2.material} />
          <mesh geometry={nodes.ID4260002_3.geometry} material={nodes.ID4260002_3.material} />
          <mesh geometry={nodes.ID4260002_4.geometry} material={nodes.ID4260002_4.material} />
          <mesh geometry={nodes.ID4260002_5.geometry} material={nodes.ID4260002_5.material} />
        </group>
        <group position={[1.4961, 0.6236, 1.6351]} rotation={[0, -1.5705, 0]} scale={[0.0015, 0.0015, 0.0015]}>
          <mesh geometry={nodes.ID4161001.geometry} material={nodes.ID4161001.material} />
          <mesh geometry={nodes.ID4161001_1.geometry} material={nodes.ID4161001_1.material} />
          <mesh geometry={nodes.ID4161001_2.geometry} material={nodes.ID4161001_2.material} />
          <mesh geometry={nodes.ID4161001_3.geometry} material={nodes.ID4161001_3.material} />
          <mesh geometry={nodes.ID4161001_4.geometry} material={nodes.ID4161001_4.material} />
        </group>
      </mesh>
    </group>
  )
}

useGLTF.preload(city)