import React, { useRef, Suspense, useState } from 'react';
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber';
import './App.css';
import * as THREE from 'three';
import { Sky } from '@react-three/drei';
import Cata from './Retail/Cata_Retail';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Load from './LoadingAni';
import Qsr from './Retail/Dnretail';
import Server from './QSR/Serverrack';

extend({ OrbitControls });
const CameraControls = (props) => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  let Look = [0, 3, -25];
  let targ = new THREE.Vector3();

  switch (props.index) {
    case 1:
      Look = [-7.99, 2, 20.4];

      break;
    case 2:
      Look = [-4.14, 0.95, 9.9];
      break;
    case 3:
      Look = [-2, 1.2, 2.68];
      break;
    case 4:
      Look = [-4.25, 3.55, -16.63];
      break;
    default:
      Look = [1.4, 3, -6.39];

      break;
  }

  targ.set(Look[0], Look[1], Look[2]);

  const {
    camera,
    gl: { domElement },
  } = useThree();
  // const targ = new THREE.Vector3(Look[0], Look[1], Look[2]);
  // Ref to the controls, so that we can update them on every frame with useFrame
  const controls = useRef();
  //camera.lookAt([0,0,0])
  //controls.current.update();

  useFrame(() => (controls.current ? controls.current.update() : null));
  //useFrame(() =>console.log(controls.current.object.position));

  return (
    <orbitControls
      target={targ}
      ref={controls}
      args={[camera, domElement]}
      //autoRotate={true}
      enableZoom={true}
      maxPolarAngle={Math.PI * 0.5}
      maxDistance={50}
      screenSpacePanning={false}
    />
  );
};

const dummy = new THREE.Vector3();
const ldummy = new THREE.Vector3();
const target = new THREE.Vector3(0, 0, 0);

function Dolly(props) {
  //props.gate ? 0 : 3, props.gate ? 3 : 2, props.gate ? 25 : 10
  //target.lerp(ldummy.set(props.gate ? 0 : 4, props.gate ? 3 : 2, props.gate ? -25 : 5), 0.1);
  let Pos = [0, 3, 25];
  let Look = [0, 3, -25];
  //console.log("Dolly: " + props.index)

  useFrame((state) => {
    switch (props.index) {
      case 1:
        Pos = [-7.99, 2, 37.01];
        Look = [-7.99, 2, 20.4];
        break;
      case 2:
        Pos = [-8.49, 0.95, 8.44];
        Look = [-4.14, 0.95, 9.9];
        break;
      case 3:
        Pos = [-1.12, 1.35, 3.88];
        Look = [-2, 1.2, 2.68];
        break;
      case 4:
        Pos = [-10.73, 3.84, -18.2];
        Look = [-4.25, 3.55, -16.63];
        break;
      default:
        Pos = [-29.84, 3.57, 18.11];

        Look = [1.4, 3, -6.39];

        break;
    }

    //console.log(Pos);

    state.camera.position.lerp(dummy.set(Pos[0], Pos[1], Pos[2]), 0.05);
    target.lerp(ldummy.set(Look[0], Look[1], Look[2]), 0.05);
    state.camera.lookAt(target);
    state.camera.updateProjectionMatrix();
  });
  return null;
}

function App(props) {
  //Scene();
  const [cam, setCam] = useState(false);
  const [visible, setVisible] = useState(true);

  setTimeout(() => {
    setVisible(false);
  }, 1000);

  return (
    <>
      {visible && <Load z={2} />}

      <Canvas
        colorManagement={false}
        gl={{ antialias: true }}
        shadowMap={true}
        camera={{ fov: 50, near: 2, position: [0, 3, 25] }}
        className="canvas"
      >
        <fog attach="fog" args={['#ffffff', 0, 90]} />

        {props.cam ? (
          <CameraControls index={props.index} cam={props.cam} />
        ) : (
          <Dolly index={props.index} gate={cam} />
        )}
        {/*<CameraControls2 index={props.index} cam={props.cam} /> */}

        <directionalLight
          castShadow
          shadowMap={true}
          shadowBias={-0.0002}
          shadow-mapSize-height={512}
          shadow-mapSize-width={1024}
          intensity={0.9}
          position={[-5, 1, 0]}
        />
        <hemisphereLight
          color={'lightblue'}
          groundColor={'white'}
          intensity={0.7}
        />
        <pointLight
          shadowBias={0.005}
          position={[-5, 3.5, -2]}
          intensity={0.2}
        />
        <pointLight color={'#FFFFCC'} position={[8, 2.5, -5]} intensity={1} />
        <pointLight
          color={'#FFFFCC'}
          position={[-5, 3.5, -1]}
          intensity={0.5}
        />

        <mesh
          castShadow
          receiveShadow
          visible={false}
          rotation={[-3.14 / 2, 0, 0]}
          position={[0, 0, 0]}
          scale={[100, 100, 1]}
        >
          <planeBufferGeometry />
          <meshStandardMaterial color="grey" />
        </mesh>
        <Suspense fallback={null}>
          <Qsr
            scale={[1.5, 1.5, 1.5]}
            rotation={[0, Math.PI * 2, 0]}
            position={[0, -1.2, -1]}
          />

          <Server
            rotation={[0, Math.PI, 0]}
            scale={[0.5, 0.5, 0.5]}
            position={[-3.8, -1, -12.5]}
          />

          {props.index === 1 && (
            <Cata
              index={props.index}
              position={[-9.9, 3.1, 32.3]}
              rotation={[0, Math.PI * 2, 0]}
              seen={props.index === 1}
            />
          )}
          {props.index === 2 && (
            <group rotation={[0, Math.PI * 2.1, 0]}>
              <Cata
                index={props.index}
                position={[-5.8, 2, 6.9]}
                rotation={[0, -Math.PI * 0.7, 0]}
                seen={props.index === 2}
              />
            </group>
          )}
          {props.index === 3 && (
            <Cata
              index={props.index}
              position={[-5.5, 2.1, 1.3]}
              rotation={[0, Math.PI * 0.18, 0]}
              seen={props.index === 3}
            />
          )}
          {props.index === 4 && (
            <group rotation={[0, 0, 0]}>
              <Cata
                index={props.index}
                position={[-6, 4.7, -19]}
                rotation={[0, -Math.PI * 0.58, 0]}
                seen={props.index === 4}
              />
            </group>
          )}
          <Sky
            sunPosition={[0, 10, 0]}
            turbidity={0.6}
            rayleigh={0.1}
            mieCoefficient={0.4}
            mieDirectionalG={0.75}
          />
          {/*props.index == 0 &&
         
          <Welcome index={props.index} />
         
          */}
        </Suspense>
      </Canvas>
    </>
  );
}

export default App;
