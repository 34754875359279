import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import location from '../sagelabel/Location_icon.png';
import video from '../sagelabel/Video-icon.png';
import screen from '../sagelabel/Screen_icon.png';
import conn from '../sagelabel/Connectivity_icon.png';
import ha from '../sagelabel/download_buttonA.png';
import serviceb from '../sagelabel/services_button.png';
import sat from '../sagelabel/SAT_icon.png';
import kiosk from '../sagelabel/Kiosk_icon.png';
import sixtyfive from '../ImageAssets/65pk.png';
import { useTexture } from '@react-three/drei';
import _sat from '../ImageAssets/sat.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Shifting Gears at the Drive-thru.'}
        dot={5}
        offs2={-0.08}
        offs={-0.17}
        para={
          'During the pandemic, 95% of banks and credit unions reported an increase in drive-thru traffic. With the advances in outdoor screen technology, the drive-thru offers a significant opportunity to bring your message outdoors with bright, eye-catching pillar and canopy screens. Reduce perceived wait times, advertise new products, communicate the latest health and safety requirements, and increase customer loyalty.'
        }
        position={[0, 2.5, 0]}
        skew={0}
        wx={2.1}
        icon={location}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.02}
        dot={2}
        text={'Go Small or Go Home.'}
        para={
          'Big screens are great for the big game, but sometimes victory goes to the small. Mini- screens can go where large screens can’t. Placed beside or atop your ATM or on pillars or existing fixtures, mini-screens can complement transaction-focused ATM/drive-thru screens. These bright, weather-proof and compact wonders pack a powerful punch. Branding, building loyalty, offering promotional information and cybersecurity reminders… these are just a few strengths of the well-placed small screen.'
        }
        position={[3.2, 2, 0]}
        skew={0}
        wx={1.7}
        icon={screen}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Two-thirds of At-branch are Outside.'}
        offs={-0.16}
        dot={5}
        offs2={-0.04}
        para={
          'Up to 65pk of branch transactions occur at the drive-thru, so if you’re not marketing here, you’re missing the majority of your in-person customer visits. Previously, concerns about weather-proofing, brightness and cost have been barriers to addressing the prime marketing space. However, recent advances in screen technology have changed the equation. Today’s outdoor-ready displays can withstand the summer heat and winter’s cold, are readable on even the brightest of days, and offer excellent ROI.'
        }
        position={[-0.2, 1.5, 0]}
        skew={0}
        wx={2.4}
        icon={kiosk}
        icolor={'#8AD2D2'}
        image={useTexture(sixtyfive)}
      />
    </>
  );
}
function Sagesec(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.02}
        text={'Cyber Security.'}
        vidurl={
          'https://player.vimeo.com/video/557676412?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.2, 2, 0]}
        skew={0}
        wx={1.2}
        icon={video}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'V-SAT Connectivity.'}
        image={useTexture(_sat)}
        offs2={-0.05}
        dot={4}
        offs={-0.1}
        para={
          'When terrestrial connections are not an option for either primary or backup connectivity, SageSat can provide VSAT satellite technology to seamlessly connect any remote location with enterprise-grade broadband. Only SageNet has deep experience using terrestrial and non-terrestrial technologies to support all aspects  of c-store operations.'
        }
        position={[0.1, 1.5, 0]}
        skew={0}
        wx={1.4}
        icon={sat}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Connectivity Sanity.'}
        offs={-0.09}
        offs2={-0.05}
        dot={3}
        para={
          'With multiple carriers, vendors and compliance demands, managing a multi-site network can be a challenge. An MNSP provides a single point of contact for circuit provisioning, consolidated billing, help desk support, routine maintenance and emergency services. Advanced services include 24/7 network monitoring, PCI-compliance and SIEM and SOC services.'
        }
        linka={ha}
        linki={serviceb}
        url={'https://sagenetvirtual.com/SageNet_MNS_WP_NEW060319-1.pdf'}
        position={[3.5, 2.1, 0]}
        skew={0}
        wx={1.5}
        icon={conn}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.02}
        text={'Video.'}
        vidurl={
          'https://player.vimeo.com/video/557682843?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.5, 2.5, 0]}
        skew={0}
        wx={0.8}
        icon={video}
        icolor={'#76787B'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.02}
        text={'V-SAT.'}
        vidurl={
          'https://player.vimeo.com/video/501964480?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[2.8, 1.5, 0]}
        skew={0}
        wx={0.8}
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return <></>;
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function Foodservice(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
