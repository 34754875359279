import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import graph from '../sagelabel/Graph_icon.png';
import video from '../sagelabel/Video-icon.png';
import ha from '../sagelabel/download_buttonA.png';
import tech from '../sagelabel/Tech_icon.png';
import loyal from '../sagelabel/Loyalty_icon.png';
import screen from '../sagelabel/Screen_icon.png';
import pc from '../sagelabel/PCI_icon.png';
import network from '../sagelabel/Network_icon.png';
import location from '../sagelabel/Location_icon.png';
import conn from '../sagelabel/Connectivity_icon.png';
import ecob from '../sagelabel/economics_button.png';
import scanb from '../sagelabel/scanning_button.png';
import serviceb from '../sagelabel/services_button.png';
import fred from '../ImageAssets/fred.png';
import { useTexture } from '@react-three/drei';
import takeit from '../ImageAssets/takeit.png';
import cybersign from '../ImageAssets/cybersign.png';
import seven from '../ImageAssets/724.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Loyalty.'}
        offs={-0.04}
        offs2={-0.02}
        para={
          'Using loyalty-integration, captive-portals, and even license plate recognition, menu offerings can include recommendations based on customer profiles and order history. Has your customer been in the drive-thru and ordered the same chicken nugget meal their past 5 visits? Your signage can remind them that nothing goes better with nuggets than some fries.'
        }
        position={[0.3, 1.5, 0]}
        image={useTexture(fred)}
        skew={0}
        wx={0.9}
        icon={loyal}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Economics.'}
        offs={-0.03}
        linka={ha}
        linki={ecob}
        url={
          'https://sagenetvirtual.com/SageNet_Drive-thruTechnology_WP_NEW022019.pdf'
        }
        para={
          'Drive-thru sales account for as much as 70% of a QSR’s revenue. With a number that high, you want to make sure you’re tapped in. The use of digital signage can upsell high margin items like beverages. On average, 20% of drive-thru customers buy a drink with their food. With signage, that can increase to 40%, causing an overall increase in sales up to 7%.'
        }
        position={[3.4, 2.3, 0]}
        skew={0}
        wx={1}
        icon={graph}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        vidurl={
          'https://player.vimeo.com/video/557683695?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.1, 2.3, 0]}
        skew={0}
        wx={0.9}
        icon={video}
        icolor={'#8AD2D2'}
      />
    </>
  );
}
function Sagesec(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Cyber For Signage.'}
        image={useTexture(cybersign)}
        dot={3}
        offs={-0.04}
        offs2={-0.01}
        para={
          'Any time you connect a device to your network, you’re introducing new security risks. Make sure your signage remains secure, and your screens only show the content you intended by securing the network and its connected devices. The only thing worse than no content, is a hacker highlighting the great deals at your competitor’s locations.'
        }
        position={[-0.3, 2.3, 0]}
        skew={0}
        wx={1.4}
        icon={screen}
        icolor={'#72337F'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'VULN Scanning.'}
        position={[3.5, 1.5, 0]}
        skew={0}
        wx={1.2}
        linka={ha}
        linki={scanb}
        url={
          'https://sagenetvirtual.com/SageNet_MngdVulnerabilityScanningDS_New092120.pdf'
        }
        para={
          'Minimize the risk of a security breach with continuous scans of your network. Vulnerability Scans provide insights and guidance to properly secure your network. Your PCI compliance depends on not only getting ASV scans completed, but by maintaining a healthy security posture based on scan results. Stay secure so you can continue accepting card payments.'
        }
        icon={pc}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'The Right Broadband.'}
        vidurl={
          'https://player.vimeo.com/video/501963949?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[-0.5, 2.3, 0]}
        skew={0}
        wx={1.6}
        icon={video}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Network Operations Center.'}
        image={useTexture(seven)}
        offs={-0.07}
        dot={1}
        para={
          'Someone to watch over me. Whether in the store, at the pump or drive-thru, for PoS, digital signage or guest Wi-Fi, the network is the backbone of your business. The support of a 24/7 NOC is more than a luxury, it’s a necessity. Our engineers utilize advanced tools and systems to analyze, troubleshoot, remediate and optimize network performance.'
        }
        position={[-0.5, 1.5, 0]}
        skew={0}
        wx={1.9}
        icon={network}
        icolor={'#76787B'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Connectivity Sanity.'}
        offs={-0.09}
        offs2={-0.05}
        dot={3}
        para={
          'With multiple carriers, vendors and compliance demands, managing a multi-site network can be a challenge. An MNSP provides a single point of contact for circuit provisioning, consolidated billing, help desk support, routine maintenance and emergency services. Advanced services include 24/7 network monitoring, PCI-compliance and SIEM and SOC services.'
        }
        linka={ha}
        linki={serviceb}
        url={'https://sagenetvirtual.com/SageNet_MNS_WP_NEW060319-1.pdf'}
        position={[3.5, 1.5, 0]}
        skew={0}
        wx={1.5}
        icon={conn}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.09}
        offs2={-0.01}
        dot={1}
        text={'How Location Analytics Work.'}
        para={
          'The Wi-Fi location analytics market is growing 15% annually. Here’s why. Wi-Fi location analytics uses multiple wireless access points and advanced algorithms to provide insights into customer behavior based on the user’s movement: Which aisle first? How many aisles, in what order? Time spent in location? In check-out?'
        }
        position={[-0.1, 2.2, 0]}
        skew={0}
        wx={2.1}
        icon={tech}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        dot={3}
        offs={-0.08}
        image={useTexture(takeit)}
        text={'Take It Outside.'}
        para={
          'The pandemic transformed QSRs. To-go, curbside and drive-thru means untethering workers and customers with robust, secure Wi-Fi. Empower employees with wireless headsets, tablets, and printers. Outdoor access points allow customers to place orders from the drive-thru, parking lot or the pump. In-car convenience increases basket-size as well.'
        }
        position={[0.2, 1.4, 0]}
        skew={0}
        offs2={-0.04}
        wx={1.3}
        icon={location}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'WPA Encryption.'}
        position={[3.3, 1.8, 0]}
        skew={0}
        wx={1.3}
        vidurl={
          'https://player.vimeo.com/video/501963786?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function Drivethru(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
