/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import burger from './dburger.glb'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(burger)
  for (const property in materials) {
    materials[property].metalness = 0.2;
    materials[property].roughness = 0.4;
    //console.log(materials[property].metalness);
  }
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={[0.01, 0.01, 0.01]}>
        <mesh castShadow receiveShadow  material={materials.wire_177088027} geometry={nodes.ChickenBurgerSet_obj_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.wire_177028149} geometry={nodes.ChickenBurgerSet_obj_2.geometry} />
        <mesh castShadow receiveShadow  material={materials.wire_148177027} geometry={nodes.ChickenBurgerSet_obj_3.geometry} />
        <mesh castShadow receiveShadow  material={materials.wire_008008136} geometry={nodes.ChickenBurgerSet_obj_4.geometry} />
        <mesh castShadow receiveShadow  material={materials.wire_154154229} geometry={nodes.ChickenBurgerSet_obj_5.geometry} />
      </group>
    </group>
  )
}

useGLTF.preload(burger)
