/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF  } from '@react-three/drei'
import dcigs from './dcigs.glb'


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(dcigs)
  for (const property in materials) {
    materials[property].metalness = 0.3;
    materials[property].roughness = 0.5;
    //console.log(materials[property].metalness);
  }

  return (
    <>
    <group position={[0,0,-0.5]} ref={group} {...props} dispose={null}>
      <group position={[5.26, 0.8, -12.14]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[0.01, 0.01, 0.01]}>
        <mesh castShadow receiveShadow  material={materials['kent mentol']} geometry={nodes.Mesh192.geometry} />
        <mesh castShadow receiveShadow  material={materials['virginia3.001']} geometry={nodes.Mesh192_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['virginia2.001']} geometry={nodes.Mesh192_2.geometry} />
        <mesh castShadow receiveShadow  material={materials['virginia.001']} geometry={nodes.Mesh192_3.geometry} />
        <mesh castShadow receiveShadow  material={materials['Kansas.001']} geometry={nodes.Mesh192_4.geometry} />
        <mesh castShadow receiveShadow  material={materials['Cambridge.001']} geometry={nodes.Mesh192_5.geometry} />
        <mesh castShadow receiveShadow  material={materials['Camel.001']} geometry={nodes.Mesh192_6.geometry} />
        <mesh castShadow receiveShadow  material={materials['Chesterfield.001']} geometry={nodes.Mesh192_7.geometry} />
        <mesh castShadow receiveShadow  material={materials['marlboro.001']} geometry={nodes.Mesh192_8.geometry} />
        <mesh castShadow receiveShadow  material={materials['marlboro2.001']} geometry={nodes.Mesh192_9.geometry} />
        <mesh castShadow receiveShadow  material={materials['Pall Mall']} geometry={nodes.Mesh192_10.geometry} />
      </group>
      <group position={[5.81, 1, -12.88]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.01, 0.01, 0.01]}>
        <mesh castShadow receiveShadow  material={materials['Plastic_Shiny.001']} geometry={nodes.Mesh220.geometry} />
        <mesh castShadow receiveShadow  material={materials['17 - Default']} geometry={nodes.Mesh220_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Glass2.001']} geometry={nodes.Mesh220_2.geometry} />
        <mesh castShadow receiveShadow  material={materials['orange.003']} geometry={nodes.Mesh220_3.geometry} />
        <mesh castShadow receiveShadow  material={materials['Chrome_Slightly_Worn.001']} geometry={nodes.Mesh220_4.geometry} />
      </group>
    </group>
    </>
  )
}

useGLTF.preload(dcigs)
