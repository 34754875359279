/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import fries from './dfries.glb'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(fries)
  for (const property in materials) {
    materials[property].metalness = 0.2;
    materials[property].roughness = 0.4;
    //console.log(materials[property].metalness);
  }
  return (
    <group {...props} ref={group} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={[0.01, 0.01, 0.01]}>
        <mesh castShadow receiveShadow   material={materials.wire_141007058} geometry={nodes.FriedPotatoesPacket_obj_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.wire_224198087} geometry={nodes.FriedPotatoesPacket_obj_2.geometry} />
      </group>
    </group>
  )
}

useGLTF.preload(fries)
