/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import worker from './dworker.glb'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(worker)
  for (const property in materials) {
    materials[property].metalness = 0.1;
    materials[property].roughness = 0.7;
    //console.log(materials[property].metalness);
  }
  return (
    <group position={props.position} rotation={props.rotation} scale={props.scale} ref={group} {...props} dispose={null}>
      <group position={[0, 0, -0.05]} scale={[0.02, 0.02, 0.02]}>
        <mesh castShadow receiveShadow material={materials.Body} geometry={nodes.Mesh.geometry} />
        <mesh castShadow material={materials.Head} geometry={nodes.Mesh_1.geometry} />
        <mesh castShadow receiveShadow material={materials['Cap 1']} geometry={nodes.Cap.geometry} />
        <mesh castShadow receiveShadow material={materials['Cap 1']} geometry={nodes.Dress.geometry} />
      </group>
    </group>
  )
}

useGLTF.preload(worker)
