import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import graph from '../sagelabel/Graph_icon.png';
import location from '../sagelabel/Location_icon.png';
import heart from '../sagelabel/heart.png';
import video from '../sagelabel/Video-icon.png';
import wifi from '../sagelabel/Secure Wi-Fi_Icon.png';
import hs from '../sagelabel/download_button.png';
import ha from '../sagelabel/download_buttonA.png';
import { useTexture } from '@react-three/drei';
import signage from '../sagelabel/signage.png';
import wayfinding from './wayfinding.png';
import ass2 from '../ImageAssets/asessments.jpg';
import kiosk from '../sagelabel/Kiosk_icon.png';
import obutton from '../sagelabel/optimize_button.png';
import touchless from '../ImageAssets/touchless.jpg';
import tech from '../sagelabel/Tech_icon.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.05}
        offs2={-0.02}
        dot={1}
        text={'POP Displays.'}
        para={
          'With signage at your point of purchase, you can remind customers what their cart is missing, and encourage them to grab it now, or in the future. With 62% of shoppers saying they’d be more likely to look for a product they saw on POP signage in their next visit, you can create lasting impressions that equal future sales.'
        }
        position={[-0.7, 2.3, 0]}
        skew={0}
        wx={1.1}
        icon={graph}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        text={'Wayfinding.'}
        dot={1}
        offs={-0.03}
        para={
          'Touch-enabled digital signage allows your guests to not only locate products (where oh where are the batteries!?), but even provide product/price comparisons (Do I pick Energizer or Duracell?). Help your customers get the items they need quickly and efficiently, and free up your staff to perform other responsibilities.'
        }
        hidden={props.hidden}
        image={useTexture(wayfinding)}
        position={[0, 1.5, 0]}
        skew={0}
        wx={1.1}
        icon={location}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        offs2={0.02}
        hidden={props.hidden}
        text={'Promotions.'}
        dot={1}
        offs={-0.05}
        para={
          'Want to let your customers know there’s a BOGO deal on beach towels? Or $2 off any pint of ice cream? With the right content on bright, eye-catching screens, you can draw in new customers and encourage them, along with return customers, that in fact, 2 is better than 1.'
        }
        position={[3.2, 2, 0]}
        skew={0}
        linki={signage}
        linka={ha}
        url={
          'https://sagenetvirtual.com/SageNet_DigitalSignage_Value_WP_101014FINAL-2%20(1).pdf'
        }
        wx={1.1}
        icon={heart}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        text={'Video.'}
        position={[2.7, 1.3, 0]}
        hidden={props.hidden}
        skew={0}
        vidurl={
          'https://player.vimeo.com/video/501964130?autoplay=1&title=0&byline=0&portrait=0'
        }
        wx={1}
        icon={video}
        icolor={'#8AD2D2'}
      />
    </>
  );
}
function Sagesec(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.02}
        text={'Cyber Security.'}
        vidurl={
          'https://player.vimeo.com/video/501963814?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.2, 2, 0]}
        skew={0}
        wx={1.2}
        icon={video}
        icolor={'#72337F'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        para={
          'Assessments should be based on industry best-practices to ensure the best security posture for your store. Utilizing compliance frameworks like ISO 27001/27002, NIST 800-53, and PCI, your cyber partner can identify existing/potential gaps in your physical or online security. Those gaps are then triaged so any necessary remediation efforts can begin.'
        }
        text={'Assessments.'}
        image={useTexture(ass2)}
        position={[0.3, 1.4, 0]}
        skew={0}
        wx={1.1}
        icon={wifi}
        icolor={'#72337F'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Cyber For Kiosks.'}
        dot={3}
        offs2={-0.02}
        offs={-0.07}
        position={[2.7, 1.4, 0]}
        skew={0}
        para={
          'Any time a new device is connected to your network, you introduce new security risks. Make sure that your kiosks remain secure, that their screens only show the content you intend, and customers are protected from skimming or other methods of identity theft. Keep your customers’ info secure, and they’ll keep their brand loyalty.'
        }
        wx={1.4}
        icon={kiosk}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        index={props.index}
        linki={hs}
        linka={ha}
        offs2={-0.01}
        hidden={props.hidden}
        text={'Empowering Connectivity.'}
        dot={2}
        offs={-0.05}
        url={
          'https://sagenetvirtual.com/SageNet_6Paradigms6Tools_WP_NEW101620%20(5).pdf'
        }
        para={
          'The pandemic led to several paradigm shifts for Retailers: health concerns, financial uncertainty, the need for agility, and the use of tech to accelerate change. However, robust connectivity remained an enabling force. From security to digital experience, frictionless checkout to analytics, the future of retail is built on the power of the network.'
        }
        position={[-0.2, 2.2, 0]}
        skew={0}
        wx={1.9}
        icon={heart}
        icolor={'#76787B'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Back-up Connectivity.'}
        position={[0.5, 1.5, 0]}
        offs2={-0.02}
        offs={-0.08}
        dot={4}
        skew={0}
        wx={1.8}
        para={
          'Whether it’s media players, kiosks, or PoS systems, backup connectivity is essential for ensuring credit card transactions. It is also a requirement for PCI compliance. Even at 99% uptime, several hours of outages monthly are commonplace. Internet backup via an alternate technology (typically cellular or satellite) adds another connection that automatically kicks-in should your primary connection fail and reverts back when connectivity is restored.'
        }
        icon={tech}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Seamless Experience.'}
        offs={-0.05}
        offs2={-0.03}
        dot={3}
        para={
          'The best connections are the ones that customers and staff never notice. SageCONNECT makes sure every order comes through flawlessly, reading POS pricing, stock status, customer payment information, and purchasing history. Myriads of data are constantly swirling. This is where SageNet managed services devises a strategy using the right networks, the right hardware, and the right design for your c-store.'
        }
        position={[3.2, 2.5, 0]}
        skew={0}
        wx={1.7}
        icon={location}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        offs={-0.05}
        dot={3}
        vidurl={
          'https://player.vimeo.com/video/557676625?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[3.2, 1.5, 0]}
        skew={0}
        wx={1}
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Customer Experience.'}
        vidurl={
          'https://player.vimeo.com/video/501964005?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[0.2, 2.1, 0]}
        skew={0}
        wx={1.5}
        icon={video}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        vidurl={
          'https://player.vimeo.com/video/557676885?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[2.7, 1.3, 0]}
        skew={0}
        wx={0.9}
        icon={video}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Touchless Kiosk Interaction.'}
        image={useTexture(touchless)}
        offs={-0.13}
        offs2={-0.04}
        dot={4}
        linka={ha}
        linki={obutton}
        para={
          'Increase safety for customers and employees by pairing wireless mobile technology with your kiosk menu. Allow customers to control kiosk boards and place orders without touching the screen. Orders are placed directly from their mobile device, or by in-person staff without a physical exchange. Reduce customer wait times. Make visits smoother and safer.'
        }
        position={[0.5, 1.4, 0]}
        skew={0}
        url={'https://sagenetvirtual.com/SageNet_WiFi-WP_NEW092319.pdf'}
        wx={2.0}
        icon={kiosk}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Wi-Fi Security.'}
        offs={-0.08}
        offs2={-0.05}
        dot={2}
        position={[2.7, 2, 0]}
        skew={0}
        wx={1.2}
        para={
          'Firewall policies must block access to internal network segments while allowing guests access to the Internet. This prevents unauthorized users from accessing company data and helps prevent the spread of malware that may exist on visitor devices. Content filtering blocks access to inappropriate sites and materials that could damage the company’s brand or bring legal liability.'
        }
        icon={wifi}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function Forecourt(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
