/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import bank from './dzbank.glb'
import Screen from '../Screen'
import ez from './Videos/3d-bank-card.mp4'
import infovid from './Videos/banking-and-finance.mp4'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(bank)
  for (const property in materials) {
    materials[property].metalness = 0.1;
    materials[property].roughness = 0.3;
    //console.log(materials[property].metalness);
  }
  
  
  
 // console.log("bank");
 return (
  <group ref={group} {...props} dispose={null}>
          <Screen url={ez} pbs={[1.77, 1]} position={[-0.15,3.58,-10.95]} rotation={[0, 0, 0]} scale={[1.58, 1.58, 1]} />
          <Screen url={ez} pbs={[1.77, 1]} position={[2.55,3.58,-10.95]} rotation={[0, 0, 0]} scale={[1.58, 1.58, 1]} />
          <Screen url={infovid} pbs={[1.77, 1]} position={[2.12,3.48,-11.55]} rotation={[0, Math.PI, 0]} scale={[1.57, 1.57, 1]} />
          <Screen url={ez} pbs={[1.77, 1]} position={[5.35,3.58,-10.95]} rotation={[0, 0, 0]} scale={[1.58, 1.58, 1]} />
          <Screen url={infovid} pbs={[1.77, 1]} position={[-4.45,2.46,-0.5]} rotation={[0, Math.PI*0.5, 0]} scale={[1.58, 1.58, 1]} />
          <Screen url={ez} pbs={[1.77, 1]} position={[3.61,1.5,-3.96]} rotation={[-0.85, 0, 0]} scale={[0.21, 0.48, 1]} />
          <Screen url={ez} pbs={[1.77, 1]} position={[1.6,1.5,-3.96]} rotation={[-0.85, 0, 0]} scale={[0.21, 0.48, 1]} />
    <mesh castShadow receiveShadow geometry={nodes.Front_Door_Frame.geometry} material={nodes.Front_Door_Frame.material}>
      <mesh castShadow receiveShadow geometry={nodes.Front_Door_Glass.geometry} material={nodes.Front_Door_Glass.material} />
    </mesh>
    <mesh castShadow receiveShadow geometry={nodes.Wall_Brick.geometry} material={nodes.Wall_Brick.material} />
    <mesh castShadow receiveShadow geometry={nodes.Wall_Brick_Block_base.geometry} material={nodes.Wall_Brick_Block_base.material} />
    <mesh castShadow receiveShadow geometry={nodes.Left_Glass_Frame_1.geometry} material={nodes.Left_Glass_Frame_1.material}>
      <mesh castShadow receiveShadow geometry={nodes.Left_Glass_1.geometry} material={nodes.Left_Glass_1.material} />
    </mesh>
    <mesh castShadow receiveShadow geometry={nodes.Line1845.geometry} material={nodes.Line1845.material} />
    <mesh castShadow receiveShadow geometry={nodes.Line1845_1.geometry} material={nodes.Line1845_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Wall_Molding_Bottom.geometry} material={nodes.Wall_Molding_Bottom.material} />
    <mesh castShadow receiveShadow geometry={nodes.Front_Top_Glass_Frame.geometry} material={nodes.Front_Top_Glass_Frame.material}>
      <mesh  geometry={nodes.Glass_front_top.geometry} material={nodes.Glass_front_top.material} />
    </mesh>
    <mesh geometry={nodes.Front_Glass_Frame.geometry} material={nodes.Front_Glass_Frame.material}>
      <mesh  geometry={nodes.Front_Main_Glass.geometry} material={nodes.Front_Main_Glass.material} />
    </mesh>
    <mesh castShadow receiveShadow geometry={nodes.Wall_Molding_Front.geometry} material={nodes.Wall_Molding_Front.material} />
    <mesh castShadow receiveShadow geometry={nodes.Line1857.geometry} material={nodes.Line1857.material} />
    <mesh castShadow receiveShadow geometry={nodes.Line1857_1.geometry} material={nodes.Line1857_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Front_Left_Glass_Frame.geometry} material={nodes.Front_Left_Glass_Frame.material}>
      <mesh castShadow receiveShadow geometry={nodes.Front_Left_Glass.geometry} material={nodes.Front_Left_Glass.material} />
    </mesh>
    <mesh castShadow receiveShadow geometry={nodes.Left_Glass_Frame_2.geometry} material={nodes.Left_Glass_Frame_2.material}>
      <mesh castShadow receiveShadow geometry={nodes.Left_Glass_2.geometry} material={nodes.Left_Glass_2.material} />
    </mesh>
    <mesh  geometry={nodes.Left_Glass_Frame_3.geometry} material={nodes.Left_Glass_Frame_3.material}>
      <mesh  geometry={nodes['Glass_-_05'].geometry} material={nodes['Glass_-_05'].material} />
    </mesh>
    <mesh geometry={nodes.Right_glass_frame_2.geometry} material={nodes.Right_glass_frame_2.material}>
      <mesh  geometry={nodes.Right_Glass_2.geometry} material={nodes.Right_Glass_2.material} />
    </mesh>
    <mesh castShadow receiveShadow geometry={nodes.Line1888.geometry} material={nodes.Line1888.material} />
    <mesh castShadow receiveShadow geometry={nodes.Line1888_1.geometry} material={nodes.Line1888_1.material} />
    <mesh  geometry={nodes.Front_Right_Glass_frame.geometry} material={nodes.Front_Right_Glass_frame.material}>
      <mesh geometry={nodes.Front_Right_Glass.geometry} material={nodes.Front_Right_Glass.material} />
    </mesh>
    <mesh castShadow receiveShadow geometry={nodes.Wall_Molding_Top.geometry} material={nodes.Wall_Molding_Top.material} />
    <mesh castShadow receiveShadow geometry={nodes.Pointed_Roof_Bottom.geometry} material={nodes.Pointed_Roof_Bottom.material} />
    <mesh
      geometry={nodes.Back_glass_frame.geometry}
      material={nodes.Back_glass_frame.material}
      position={[0, 0, -4.48]}>
      <mesh geometry={nodes.Back_Glass.geometry} material={nodes.Back_Glass.material} />
    </mesh>
    <mesh geometry={nodes.Right_Glass_Frame_1.geometry} material={nodes.Right_Glass_Frame_1.material}>
      <mesh  geometry={nodes.Right_Glass_1.geometry} material={nodes.Right_Glass_1.material} />
    </mesh>
    <mesh castShadow receiveShadow geometry={nodes.Main_Roof.geometry} material={nodes.Main_Roof.material} />
    <mesh position={[0,0,0.1]} visible={true} castShadow receiveShadow geometry={nodes.Line1920.geometry} material={nodes.Line1920.material} />
    <mesh castShadow receiveShadow geometry={nodes.Line1920_1.geometry} material={nodes.Line1920_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Pointed_Roof.geometry} material={nodes.Pointed_Roof.material} />
    <mesh castShadow receiveShadow geometry={nodes.Patio_Columns_Brick.geometry} material={nodes.Patio_Columns_Brick.material}>
      <mesh castShadow receiveShadow geometry={nodes.Patio_Columns_Block_Base.geometry} material={nodes.Patio_Columns_Block_Base.material} />
      <mesh
        geometry={nodes.Patio_Columns_Molding_Bottom.geometry}
        material={nodes.Patio_Columns_Molding_Bottom.material}
      />
    </mesh>
    <mesh castShadow receiveShadow geometry={nodes.Patio_Roof_Edge.geometry} material={nodes.Patio_Roof_Edge.material} />
    <mesh castShadow receiveShadow geometry={nodes.Patio_Roof.geometry} material={nodes.Patio_Roof.material} />
    <mesh castShadow receiveShadow geometry={nodes.Text013.geometry} material={nodes.Text013.material} />
    <mesh castShadow receiveShadow geometry={nodes.Text013_1.geometry} material={nodes.Text013_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Text014.geometry} material={nodes.Text014.material} />
    <mesh castShadow receiveShadow geometry={nodes.Text014_1.geometry} material={nodes.Text014_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Floor.geometry} material={materials.Tiles} />
    <mesh castShadow receiveShadow geometry={nodes.Cube040_Cube035.geometry} material={nodes.Cube040_Cube035.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube040_Cube035_1.geometry} material={nodes.Cube040_Cube035_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube040_Cube035_2.geometry} material={nodes.Cube040_Cube035_2.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube040_Cube035_3.geometry} material={nodes.Cube040_Cube035_3.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube039_Cube032.geometry} material={nodes.Cube039_Cube032.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube039_Cube032_1.geometry} material={nodes.Cube039_Cube032_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube039_Cube032_2.geometry} material={nodes.Cube039_Cube032_2.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube039_Cube032_3.geometry} material={nodes.Cube039_Cube032_3.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube038_Cube031.geometry} material={nodes.Cube038_Cube031.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube038_Cube031_1.geometry} material={nodes.Cube038_Cube031_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube038_Cube031_2.geometry} material={nodes.Cube038_Cube031_2.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube038_Cube031_3.geometry} material={nodes.Cube038_Cube031_3.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube037_Cube030.geometry} material={nodes.Cube037_Cube030.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube037_Cube030_1.geometry} material={nodes.Cube037_Cube030_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube037_Cube030_2.geometry} material={nodes.Cube037_Cube030_2.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube037_Cube030_3.geometry} material={nodes.Cube037_Cube030_3.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube033.geometry} material={nodes.Cube032_Cube033.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube033_1.geometry} material={nodes.Cube032_Cube033_1.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube033_2.geometry} material={nodes.Cube032_Cube033_2.material} />
    <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube033_3.geometry} material={nodes.Cube032_Cube033_3.material} />
    <group position={[2.56, 1.47, -7.27]}>
      <mesh castShadow receiveShadow geometry={nodes.Cube024_Cube001.geometry} material={materials.Blue_Color_Glass} />
      <mesh castShadow receiveShadow geometry={nodes.Cube024_Cube001_1.geometry} material={nodes.Cube024_Cube001_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube024_Cube001_2.geometry} material={nodes.Cube024_Cube001_2.material} />
    </group>
    <group position={[2.59, 3.59, -10.97]} scale={[2.76, 1.64, 0.08]}>
      <mesh castShadow receiveShadow geometry={nodes.Cube.geometry} material={nodes.Cube.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube_1.geometry} material={nodes.Cube_1.material} />
      <group position={[-1, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.Cube002.geometry} material={nodes.Cube002.material} />
        <mesh castShadow receiveShadow geometry={nodes.Cube002_1.geometry} material={nodes.Cube002_1.material} />
      </group>
      <group position={[1, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.Cube001.geometry} material={nodes.Cube001.material} />
        <mesh castShadow receiveShadow geometry={nodes.Cube001_1.geometry} material={nodes.Cube001_1.material} />
      </group>
    </group>
    <group position={[0, -0.01, 0]}>
      <mesh castShadow receiveShadow geometry={nodes.Plane.geometry} material={materials.Street} />
      <mesh castShadow receiveShadow geometry={nodes.Plane_1.geometry} material={materials['parking spots']} />
    </group>
    <group position={[12.22, 0, 5.88]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.01, 0.01, 0.01]}>
      <mesh castShadow receiveShadow geometry={nodes.Plane002.geometry} material={nodes.Plane002.material} />
      <mesh castShadow receiveShadow geometry={nodes.Plane002_1.geometry} material={materials['Crubside_Screen.001']} />
    </group>
    <group position={[12.22, 0, 3.22]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.01, 0.01, 0.01]}>
      <mesh castShadow receiveShadow geometry={nodes.Plane001.geometry} material={nodes.Plane001.material} />
      <mesh castShadow receiveShadow geometry={nodes.Plane001_1.geometry} material={materials['Crubside_Screen.002']} />
    </group>
    <group position={[12.22, 0, 0.78]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.01, 0.01, 0.01]}>
      <mesh castShadow receiveShadow geometry={nodes.Plane003.geometry} material={nodes.Plane003.material} />
      <mesh castShadow receiveShadow geometry={nodes.Plane003_1.geometry} material={materials['Crubside_Screen.003']} />
    </group>
    <group position={[-3.86, 0, -0.58]} rotation={[0, Math.PI / 2, 0]} scale={[2,0.5,1]}>
      <mesh castShadow receiveShadow geometry={nodes.Cube003.geometry} material={nodes.Cube003.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube003_1.geometry} material={nodes.Cube003_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube003_2.geometry} material={nodes.Cube003_2.material} />
    </group>
    <group position={[-4.58, 2.46, -0.51]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.08, 0.08, 0.08]}>
      <mesh castShadow receiveShadow geometry={nodes.F6100_32.geometry} material={nodes.F6100_32.material} />
      <mesh castShadow receiveShadow geometry={nodes.F6100_32_1.geometry} material={nodes.F6100_32_1.material} />
    </group>
    <group position={[-2.4, 0, 0]} scale={[2.58, 2.96, 2.96]}>
      <mesh castShadow receiveShadow geometry={nodes.Cube004.geometry} material={nodes.Cube004.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube004_1.geometry} material={nodes.Cube004_1.material} />
    </group>
    <group position={[-2.14, 0, 0.72]} rotation={[Math.PI / 2, 0, 0]} scale={[0.04, 0.04, 0.04]}>
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair_1.geometry} material={nodes.Office_Chair_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair_2.geometry} material={nodes.Office_Chair_2.material} />
    </group>
    <group position={[-2.14, 0, -2.06]} rotation={[Math.PI / 2, 0, -1.02]} scale={[0.04, 0.04, 0.04]}>
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair001_1.geometry} material={nodes.Office_Chair001_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair001_2.geometry} material={nodes.Office_Chair001_2.material} />
    </group>
    <group position={[-4.28, 0, -15.22]} rotation={[Math.PI / 2, 0, -1.57]} scale={[0.04, 0.04, 0.04]}>
      <mesh castShadow receiveShadow geometry={nodes['refrigerator(obj)'].geometry} material={nodes['refrigerator(obj)'].material} />
      <mesh castShadow receiveShadow geometry={nodes['refrigerator(obj)_1'].geometry} material={nodes['refrigerator(obj)_1'].material} />
    </group>
    <group position={[9.9, 0, -14.23]} rotation={[Math.PI / 2, 0, 0]} scale={[0.03, 0.03, 0.03]}>
      <mesh castShadow receiveShadow geometry={nodes.Box003_1.geometry} material={nodes.Box003_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Box003_2.geometry} material={nodes.Box003_2.material} />
    </group>
    <group position={[9.9, 0, -14.51]} rotation={[Math.PI / 2, 0, 0]} scale={[0.03, 0.03, 0.03]}>
      <mesh castShadow receiveShadow geometry={nodes.Box005_1.geometry} material={nodes.Box005_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Box005_2.geometry} material={nodes.Box005_2.material} />
    </group>
    <group position={[0.82, 0, -15.12]} rotation={[Math.PI / 2, 0, Math.PI / 10]} scale={[0.04, 0.04, 0.04]}>
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair002.geometry} material={nodes.Office_Chair002.material} />
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair002_1.geometry} material={nodes.Office_Chair002_1.material} />
    </group>
    <group position={[2.62, 0, -16.82]} rotation={[Math.PI / 2, 0, -1.02]} scale={[0.04, 0.04, 0.04]}>
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair003.geometry} material={nodes.Office_Chair003.material} />
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair003_1.geometry} material={nodes.Office_Chair003_1.material} />
    </group>
    <group position={[-1.36, 0, -16.56]} rotation={[Math.PI / 2, 0, -3.1]} scale={[0.04, 0.04, 0.04]}>
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair004.geometry} material={nodes.Office_Chair004.material} />
      <mesh castShadow receiveShadow geometry={nodes.Office_Chair004_1.geometry} material={nodes.Office_Chair004_1.material} />
    </group>
    <group position={[-4.32, 0, -13.48]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.03, 0.03]}>
      <mesh castShadow receiveShadow geometry={nodes.CokeMachine_1.geometry} material={nodes.CokeMachine_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.CokeMachine_2.geometry} material={materials.CokeMachine} />
    </group>
    <group position={[2.12, 3.48, -11.53]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.76, 1.64, 0.08]}>
      <mesh castShadow receiveShadow geometry={nodes.Cube007.geometry} material={nodes.Cube007.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube007_1.geometry} material={nodes.Cube007_1.material} />
    </group>
    <group position={[2.54, 0, -8.14]} rotation={[1.55, 0, 0]} scale={[1.46, 1.46, 1.46]}>
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh.geometry} material={nodes.Man_Mesh.material} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh_1.geometry} material={nodes.Man_Mesh_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh_2.geometry} material={materials.Shirt} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh_3.geometry} material={nodes.Man_Mesh_3.material} />
    </group>
    <mesh
      geometry={nodes.BOH_Table.geometry}
      material={nodes.BOH_Table.material}
      position={[0.82, 0, -16.64]}
      rotation={[0, Math.PI / 2, 0]}
      scale={[0.54, 0.03, 0.54]}
    />
    <group position={[-10.08, 0.02, -0.94]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.01, 0.01, 0.01]}>
      <mesh castShadow receiveShadow geometry={nodes.Bank009.geometry} material={nodes.Bank009.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_1.geometry} material={nodes.Bank009_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_2.geometry} material={nodes.Bank009_2.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_3.geometry} material={nodes.Bank009_3.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_4.geometry} material={nodes.Bank009_4.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_5.geometry} material={nodes.Bank009_5.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_6.geometry} material={nodes.Bank009_6.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_7.geometry} material={nodes.Bank009_7.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_8.geometry} material={nodes.Bank009_8.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_9.geometry} material={nodes.Bank009_9.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_10.geometry} material={nodes.Bank009_10.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank009_11.geometry} material={nodes.Bank009_11.material} />
    </group>
    <group position={[-9.97, 0.02, 1.19]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0.01, 0.01]}>
      <mesh castShadow receiveShadow geometry={nodes.Bank001.geometry} material={nodes.Bank001.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_1.geometry} material={nodes.Bank001_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_2.geometry} material={nodes.Bank001_2.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_3.geometry} material={nodes.Bank001_3.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_4.geometry} material={nodes.Bank001_4.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_5.geometry} material={nodes.Bank001_5.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_6.geometry} material={nodes.Bank001_6.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_7.geometry} material={nodes.Bank001_7.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_8.geometry} material={nodes.Bank001_8.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_9.geometry} material={nodes.Bank001_9.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_10.geometry} material={nodes.Bank001_10.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank001_11.geometry} material={nodes.Bank001_11.material} />
    </group>
    <group position={[-15.65, 0.02, -0.94]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.01, 0.01, 0.01]}>
      <mesh castShadow receiveShadow geometry={nodes.Bank002.geometry} material={nodes.Bank002.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_1.geometry} material={nodes.Bank002_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_2.geometry} material={nodes.Bank002_2.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_3.geometry} material={nodes.Bank002_3.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_4.geometry} material={nodes.Bank002_4.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_5.geometry} material={nodes.Bank002_5.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_6.geometry} material={nodes.Bank002_6.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_7.geometry} material={nodes.Bank002_7.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_8.geometry} material={nodes.Bank002_8.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_9.geometry} material={nodes.Bank002_9.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_10.geometry} material={nodes.Bank002_10.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank002_11.geometry} material={nodes.Bank002_11.material} />
    </group>
    <group position={[-15.55, 0.02, 1.19]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0.01, 0.01]}>
      <mesh castShadow receiveShadow geometry={nodes.Bank004.geometry} material={nodes.Bank004.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_1.geometry} material={nodes.Bank004_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_2.geometry} material={nodes.Bank004_2.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_3.geometry} material={nodes.Bank004_3.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_4.geometry} material={nodes.Bank004_4.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_5.geometry} material={nodes.Bank004_5.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_6.geometry} material={nodes.Bank004_6.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_7.geometry} material={nodes.Bank004_7.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_8.geometry} material={nodes.Bank004_8.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_9.geometry} material={nodes.Bank004_9.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_10.geometry} material={nodes.Bank004_10.material} />
      <mesh castShadow receiveShadow geometry={nodes.Bank004_11.geometry} material={nodes.Bank004_11.material} />
    </group>
    <group position={[1.59, 0.77, -3.99]} rotation={[0, Math.PI / 2, 0]}>
      <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube001.geometry} material={nodes.Cube032_Cube001.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube001_1.geometry} material={nodes.Cube032_Cube001_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube001_2.geometry} material={nodes.Cube032_Cube001_2.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube001_3.geometry} material={nodes.Cube032_Cube001_3.material} />
    </group>
    <group position={[3.62, 0.77, -3.99]} rotation={[0, Math.PI / 2, 0]}>
      <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube002.geometry} material={nodes.Cube032_Cube002.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube002_1.geometry} material={nodes.Cube032_Cube002_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube002_2.geometry} material={nodes.Cube032_Cube002_2.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube032_Cube002_3.geometry} material={nodes.Cube032_Cube002_3.material} />
    </group>
    <group position={[3.11, 0, -11.18]} scale={[26.5, 9.9, 1.08]}>
      <mesh castShadow receiveShadow geometry={nodes.Cube008.geometry} material={nodes.Cube008.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube008_1.geometry} material={nodes.Cube008_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube008_2.geometry} material={nodes.Cube008_2.material} />
      <mesh castShadow receiveShadow geometry={nodes.Cube008_3.geometry} material={materials.Material} />
      <mesh
        geometry={nodes['Door-nob001'].geometry}
        material={nodes['Door-nob001'].material}
        position={[0.22, 0.14, -0.29]}
        rotation={[0, 1.57, 0]}
        scale={[0.41, 0.04, 0.02]}
      />
      <mesh
        geometry={nodes['Door-nob002'].geometry}
        material={materials['metal_dull.001']}
        position={[0.22, 0.14, 0.31]}
        rotation={[0, 1.57, 0]}
        scale={[0.41, 0.04, 0.02]}
      />
    </group>
    <group position={[-1.83, 0, -8.14]} rotation={[1.55, 0, 0]} scale={[1.46, 1.46, 1.46]}>
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh001.geometry} material={nodes.Man_Mesh001.material} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh001_1.geometry} material={nodes.Man_Mesh001_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh001_2.geometry} material={materials['Shirt.001']} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh001_3.geometry} material={nodes.Man_Mesh001_3.material} />
    </group>
    <group position={[7.16, 0, -8.14]} rotation={[1.55, 0, 0]} scale={[1.46, 1.46, 1.46]}>
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh002.geometry} material={nodes.Man_Mesh002.material} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh002_1.geometry} material={nodes.Man_Mesh002_1.material} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh002_2.geometry} material={materials['Shirt.002']} />
      <mesh castShadow receiveShadow geometry={nodes.Man_Mesh002_3.geometry} material={nodes.Man_Mesh002_3.material} />
    </group>
    <group position={[0.34, 4.69, -11.04]} rotation={[Math.PI / 2, 0, 0]}>
      <mesh castShadow receiveShadow geometry={nodes.Banking.geometry} material={nodes.Banking.material} />
      <mesh castShadow receiveShadow geometry={nodes.Banking_1.geometry} material={nodes.Banking_1.material} />
    </group>
  </group>
)
}

useGLTF.preload(bank)
