import React, { Suspense } from 'react';
import TextUI from '../TextUI';
import graph from '../sagelabel/Graph_icon.png';
import video from '../sagelabel/Video-icon.png';
import wifi from '../sagelabel/Secure Wi-Fi_Icon.png';
import ha from '../sagelabel/download_buttonA.png';
import { useTexture } from '@react-three/drei';
import location from '../sagelabel/Location_icon.png';
import skim from '../sagelabel/Skimming_icon.png';
import _skimming from '../sagelabel/skimming_button.png';
import SkimUI from '../SkimUI';
import seven from '../ImageAssets/724.png';
import network from '../sagelabel/Network_icon.png';
import screen from '../sagelabel/Screen_icon.png';
import portal from '../ImageAssets/Captive Portal.png';
import pcib from '../sagelabel/pci_button.png';
import pc from '../sagelabel/PCI_icon.png';
import takeit from '../ImageAssets/takeit.png';

function Sageview(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.08}
        offs2={-0.02}
        dot={2}
        text={'Curbside Clarity.'}
        para={
          'The pandemic forced consumers to shift their shopping habits, and bank and credit union customers were not immune. Shifting even more branch traffic outside the four walls, curbside service offers customers drive-thru convenience with personal interaction. Digital signage at the curb can provide the customer with real-time information and instructions, reducing confusion and lowering perceived wait-times.'
        }
        position={[-0.7, 2.3, 0]}
        skew={0}
        wx={1.4}
        icon={graph}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        index={props.index}
        offs2={0.02}
        hidden={props.hidden}
        text={'Queueing at Curb.'}
        dot={2}
        offs={-0.05}
        para={
          '                                                                                                       Take advantage of your new “street side” marketing venue. Bright, weatherproof digital signage outdoors or in your windows can reach customers on the street or in the parking lot. Curbside digital signage solutions can help with queueing inside and out. Customer signage can keep the customer informed of the transaction status while providing entertainment and promotional content. Employee- facing signage can alert personnel of customer arrival, location, wait time and transaction information. All while keeping the customer safe and comfortable.'
        }
        position={[3.2, 2, 0]}
        skew={0}
        wx={1.4}
        icon={graph}
        icolor={'#8AD2D2'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        vidurl={
          'https://player.vimeo.com/video/557675860?autoplay=1&title=0&byline=0&portrait=0'
        }
        position={[-0.5, 1.6, 0]}
        skew={0}
        wx={0.9}
        icon={video}
        icolor={'#8AD2D2'}
      />
    </>
  );
}
function Sagesec(props) {
  const stext =
    'Protect your guests and business from theft. Skimming occurs when fake card readers, cameras, or pin pads are used over your equipment, such as outdoor ATMs, and card numbers and pins are stolen for later, malicious use. Your customer loyalty is built on a positive relationship with your brand. Keep their money safe and your customer-base secure.';
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Secure Wi-Fi.'}
        offs2={-0.03}
        offs={-0.05}
        para={
          'Guest Wi-Fi provides another avenue for promotions and building customer satisfaction. It also opens you up to numerous risks. Without proper restrictions, guests can access questionable sites, accidentally or purposely inject malware into your network, or even access proprietary info on your corporate network. Build trust with your customers by keeping their data safe.'
        }
        position={[0, 2.5, 0]}
        skew={0}
        wx={1.3}
        icon={wifi}
        icolor={'#72337F'}
      />
      <SkimUI
        index={props.index}
        para={stext}
        text={'Skimming.'}
        offs2={0.02}
        hide={props.hide}
        hidden={props.hidden}
        position={[0.2, 1.5, 0]}
        skew={0}
        wx={1}
        icon={skim}
        icolor={'#72337F'}
        linki={_skimming}
        linka={ha}
        vidurl={
          'https://player.vimeo.com/video/502220475?autoplay=1&title=0&byline=0&portrait=0'
        }
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        linki={pcib}
        offs={-0.02}
        linka={ha}
        url={'https://sagenetvirtual.com/SageNet_PCI_DS_091418.pdf'}
        text={'PCI-Compliance.'}
        para={
          'Your store’s profits rely on the ability to accept credit card payments. Mandated by card issuers, PCI DSS requires all merchants with internal systems that store, process or transmit cardholder data to comply with key data protection measures and submit to annual security audits. Stay secure so your consumers can be comfortable shopping with you.'
        }
        position={[2.7, 1.3, 0]}
        skew={0}
        wx={1.3}
        icon={pc}
        icolor={'#72337F'}
      />
    </>
  );
}

function Sageconnect(props) {
  const ctext =
    'Cash only makes up 65pk of branch transactions occurring outside branch walls, you need connectivity certainty at the curb and drive-thru. Customers now equate connectivity with your brand, online, in-branch or outside. With the shift to digital signage and curbside services, fast, reliable, secure connectivity is a financial institution’s lifeline. High-speed broadband with automatic failover and 24/7 monitoring and field service ensures always-on connectivity. ';
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Network Operations Center.'}
        image={useTexture(seven)}
        offs={-0.07}
        dot={1}
        para={
          'Someone to watch over me. Whether in-branch, at the curb, or at the drive-thru, the network is the backbone of your branch and its supporting technologies. The support of a 24/7 Network Operations Center (NOC) staffed by highly-trained personnel is more than a luxury, it’s a necessity. Our engineers utilize advanced tools and systems to analyze, troubleshoot, remediate and optimize network performance.'
        }
        position={[-0.5, 1.5, 0]}
        skew={0}
        wx={1.9}
        icon={network}
        icolor={'#76787B'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs={-0.1}
        text={'Connectivity Certainty.'}
        offs2={-0.05}
        para={ctext}
        position={[3.5, 2.2, 0]}
        skew={0}
        wx={1.7}
        icon={network}
        icolor={'#76787B'}
      />

      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Video.'}
        position={[3.2, 1.5, 0]}
        skew={0}
        wx={1.1}
        vidurl={
          'https://player.vimeo.com/video/501964524?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#76787B'}
      />
    </>
  );
}

function Sageiw(props) {
  return (
    <>
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'Captive Portal.'}
        dot={3}
        offs={-0.05}
        offs2={-0.02}
        para={
          'Captive portals present Wi-Fi users with a web page that requires some action before network access is granted. At the curb, it’s a powerful marketing tool as well as a network access mechanism, presenting the user with messaging based on location, time of day, or loyalty info while capturing valuable data to help personalize the customer experience.'
        }
        position={[0.3, 2.4, 0]}
        skew={0}
        wx={1.3}
        icon={screen}
        icolor={'#E79F37'}
        image={useTexture(portal)}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        dot={3}
        offs={-0.08}
        image={useTexture(takeit)}
        text={'Take It Outside.'}
        para={
          'The pandemic transformed business. Setting up curbside service options means untethering workers and customers with robust, secure Wi-Fi. Empower employees with wireless headsets, tablets, and printers to process orders. Outdoor access points allow customers to begin and complete transactions, all from the convenience of their car.'
        }
        position={[0, 1.4, 0]}
        skew={0}
        offs2={-0.04}
        wx={1.3}
        icon={location}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        text={'WPA Encryption.'}
        position={[3, 2.3, 0]}
        skew={0}
        wx={1.3}
        vidurl={
          'https://player.vimeo.com/video/501963786?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#E79F37'}
      />
      <TextUI
        hide={props.hide}
        hidden={props.hidden}
        index={props.index}
        offs2={-0.07}
        text={'The 3 Pillars Of IoT.'}
        position={[2.8, 1.6, 0]}
        skew={0}
        wx={1.5}
        vidurl={
          'https://player.vimeo.com/video/501963975?autoplay=1&title=0&byline=0&portrait=0'
        }
        icon={video}
        icolor={'#E79F37'}
      />
    </>
  );
}

function GetComponent(props) {
  switch (props.index) {
    case 1:
      return <Sagesec {...props} />;
    case 2:
      return <Sageconnect {...props} />;
    case 3:
      return <Sageiw {...props} />;
    default:
      return <Sageview {...props} />;
  }
}

export default function POP(props) {
  return (
    <Suspense fallback={null}>
      <GetComponent {...props} />
    </Suspense>
  );
}
