/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import qsr from './dqsr.glb'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(qsr)
  for (const property in materials) {
    materials[property].metalness = 0.4;
    materials[property].roughness = 0.7;
    //console.log(materials[property].metalness);
  }
  materials.Glass.transparent = true;
  materials.Glass.color.set("#23acc4");
  materials.Glass.metalness = 0.1;
  materials.Glass.roughness = 0.1;
  materials.Glass.opacity = 0.1;
  materials.White.roughness = 0.5;
  materials.White.metalness = 0.2;
  materials.Metal.metalness = 1;
  materials['Drive-thru_Screen'].emissive.set("rgb(70,70,70)");
  
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.exterior_door_frame.geometry} rotation={[0, 1.57, 0]} />
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.door3_2_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.door3_2_pivot001_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.door3_2_pivot001_2.geometry} />
      </group>
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.door2_2_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.door2_2_pivot001_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.door2_2_pivot001_2.geometry} />
      </group>
      <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.Window_Shades.geometry} rotation={[0, 1.57, 0]} />
      <group position={[-938.45, 131.7, -258.83]} rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.back_door_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.back_door_pivot001_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.back_door_pivot001_2.geometry} />
      </group>
      <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.roof.geometry} rotation={[0, 1.57, 0]} />
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.exterior_walls2_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.exterior_walls2_pivot001_1.geometry} />
      </group>
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.door1_1_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.door1_1_pivot001_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.door1_1_pivot001_2.geometry} />
      </group>
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials.Brown} geometry={nodes.interior_parts_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.interior_parts_pivot001_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.interior_parts_pivot001_2.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.interior_parts_pivot001_3.geometry} />
      </group>
      <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.exterior_lighting.geometry} rotation={[0, 1.57, 0]} />
      <group position={[-71.69, 478.5, -35.89]} rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.air_conditioning1_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.air_conditioning1_pivot001_1.geometry} />
      </group>
      <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.roof_details.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.exterior_Arrow_sign.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.exterior_texts.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.overlap.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.overlap_fixing.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow 
        material={materials.White}
        geometry={nodes.Interior_Ceiling.geometry}
        position={[0, 43.38, 0]}
        rotation={[0, 1.57, 0]}
      />
      <mesh castShadow receiveShadow  material={materials.Tiles} geometry={nodes.Interior_floor.geometry} rotation={[0, 1.57, 0]} />
      <group position={[409.29, 100.29, -326.2]} rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot001_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot001_2.geometry} />
      </group>
      <mesh  material={materials.Glass} geometry={nodes.windows.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Interior_floor_molding.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.Interior_walls.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.window_Frames.geometry} rotation={[0, 1.57, 0]} />
      <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.fencing.geometry} rotation={[0, 1.57, 0]} />
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.front_door2_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.front_door2_pivot001_1.geometry} />
        <mesh   material={materials.Glass} geometry={nodes.front_door2_pivot001_2.geometry} />
      </group>
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.front_door1_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.front_door1_pivot001_1.geometry} />
        <mesh   material={materials.Glass} geometry={nodes.front_door1_pivot001_2.geometry} />
      </group>
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.exterior_walls3_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.exterior_walls3_pivot001_1.geometry} />
      </group>
      <group rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.exterior_walls1_pivot001.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.exterior_walls1_pivot001_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.exterior_walls1_pivot001_2.geometry} />
      </group>
      <group position={[-531.57, 478.5, -35.89]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.air_conditioning1_pivot002.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.air_conditioning1_pivot002_1.geometry} />
      </group>
      <group position={[571.79, 100.29, -326.2]} rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot002.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot002_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot002_2.geometry} />
      </group>
      <group position={[723.85, 100.29, -326.2]} rotation={[0, 1.57, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot003.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot003_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot003_2.geometry} />
      </group>
      <group position={[838.49, 100.29, -241.12]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot004.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot004_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot004_2.geometry} />
      </group>
      <group position={[838.49, 100.29, -83.26]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot005.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot005_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot005_2.geometry} />
      </group>
      <group position={[838.49, 100.29, 75.66]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot006.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot006_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot006_2.geometry} />
      </group>
      <group position={[838.49, 100.29, 233.92]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot007.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot007_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot007_2.geometry} />
      </group>
      <group position={[423.22, 100.29, 300]} rotation={[0, Math.PI / 2, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot008.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot008_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot008_2.geometry} />
      </group>
      <group position={[577.06, 100.29, 300]} rotation={[0, Math.PI / 2, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot009.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot009_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot009_2.geometry} />
      </group>
      <group position={[710, 100.29, 300]} rotation={[0, Math.PI / 2, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot010.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot010_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot010_2.geometry} />
      </group>
      <group position={[-370.96, 31.78, -236.31]}>
        <mesh castShadow receiveShadow  material={materials.sreet} geometry={nodes.Plane.geometry} />
        <mesh castShadow receiveShadow  material={materials['Parking ']} geometry={nodes.Plane_1.geometry} />
      </group>
      <group position={[-11.93, 32.11, -1.46]} scale={[35.64, 0.14, 18.72]}>
        <mesh castShadow receiveShadow  material={materials.Curb} geometry={nodes.Cube011.geometry} />
        <mesh castShadow receiveShadow  material={materials.SideWalk} geometry={nodes.Cube011_1.geometry} />
      </group>
      <group position={[-185.38, 210.91, 17.83]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[5, 5, 5]}>
        <mesh castShadow receiveShadow  material={materials.Screen} geometry={nodes.F6100_32.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.F6100_32_1.geometry} />
      </group>
      <group position={[489.14, 139.7, -557.54]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Plane002.geometry} />
        <mesh castShadow receiveShadow  material={materials['Crubside_Screen.001']} geometry={nodes.Plane002_1.geometry} />
      </group>
      <group position={[328.57, 139.7, -557.54]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Plane003.geometry} />
        <mesh castShadow receiveShadow  material={materials['Crubside_Screen.002']} geometry={nodes.Plane003_1.geometry} />
      </group>
      <group position={[163.96, 139.7, -557.54]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Plane004.geometry} />
        <mesh castShadow receiveShadow  material={materials['Crubside_Screen.003']} geometry={nodes.Plane004_1.geometry} />
      </group>
      <group position={[-938.13, 145.29, 463.91]} rotation={[Math.PI / 2, 0, -2.49]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Plane005.geometry} />
        <mesh castShadow receiveShadow  material={materials['Drive-thru_Screen']} geometry={nodes.Plane005_1.geometry} />
      </group>
      <group position={[259.74, 147.88, 271.33]} rotation={[Math.PI / 2, 0, Math.PI / 2]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Plane006.geometry} />
        <mesh castShadow receiveShadow  material={materials['Screen_Self-serve']} geometry={nodes.Plane006_1.geometry} />
      </group>
      <group position={[259.74, 147.88, 160.49]} rotation={[Math.PI / 2, 0, Math.PI / 2]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Plane010.geometry} />
        <mesh castShadow receiveShadow  material={materials['Screen_Self-serve']} geometry={nodes.Plane010_1.geometry} />
      </group>
      <group position={[259.74, 147.88, 57.87]} rotation={[Math.PI / 2, 0, Math.PI / 2]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Plane011.geometry} />
        <mesh castShadow receiveShadow  material={materials['Screen_Self-serve']} geometry={nodes.Plane011_1.geometry} />
      </group>
      <group visible={true} position={[22.71, 252.89, 130.36]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[3, 3, 3]}>
        <mesh castShadow receiveShadow  material={materials.Menu_Screen} geometry={nodes.F6100_32002.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.F6100_32002_1.geometry} />
        <group position={[-36.47, 0, 0]}>
          <mesh castShadow receiveShadow  material={materials.Menu_Screen} geometry={nodes.F6100_32003.geometry} />
          <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.F6100_32003_1.geometry} />
        </group>
        <group position={[36.47, 0, 0]}>
          <mesh castShadow receiveShadow  material={materials.Menu_Screen_promo} geometry={nodes.F6100_32004.geometry} />
          <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.F6100_32004_1.geometry} />
        </group>
      </group>
      <group position={[423.22, 100.29, 91.97]} rotation={[0, Math.PI / 2, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot011.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot011_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot011_2.geometry} />
      </group>
      <group position={[423.22, 100.29, -127.08]} rotation={[0, Math.PI / 2, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot012.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot012_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot012_2.geometry} />
      </group>
      <group position={[641.81, 100.29, -127.08]} rotation={[0, Math.PI / 2, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot013.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot013_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot013_2.geometry} />
      </group>
      <group position={[641.81, 100.29, 93.19]} rotation={[0, Math.PI / 2, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.table2_pivot014.geometry} />
        <mesh castShadow receiveShadow  material={materials.White} geometry={nodes.table2_pivot014_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.table2_pivot014_2.geometry} />
      </group>
      <mesh castShadow receiveShadow 
        material={materials['Main Color']}
        geometry={nodes.BreadBox.geometry}
        position={[-509.59, 144.77, -347.99]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <group position={[-901.09, 98.38, 82.81]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Cube5_4.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Cube5_4_1.geometry} />
      </group>
      <mesh castShadow receiveShadow 
        material={materials.Metal}
        geometry={nodes.SlopSink.geometry}
        position={[-902.14, 105.52, -61.93]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh castShadow receiveShadow 
        material={materials.Metal}
        geometry={nodes.Pot000.geometry}
        position={[-629.99, 153.51, 321.12]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh castShadow receiveShadow 
        material={materials.Metal}
        geometry={nodes.pan.geometry}
        position={[-563.17, 132.88, -38.78]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <group position={[-600.81, 99.09, 7.2]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Cube41.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Cube41_1.geometry} />
      </group>
      <mesh castShadow receiveShadow 
        material={materials.Metal}
        geometry={nodes.Counter_2.geometry}
        position={[-626.39, 95.89, -46.75]}
        rotation={[Math.PI / 2, 0, Math.PI]}
      />
      <group position={[-675.34, 261.45, -335.03]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.kulp_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.kulp_1_1.geometry} />
      </group>
      <mesh castShadow receiveShadow 
        material={materials.Metal}
        geometry={nodes['Kitchen-Table'].geometry}
        position={[-498.7, 95.99, -333.75]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <group position={[-408.6, 134.59, 250.77]} rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh castShadow receiveShadow  material={materials.Red} geometry={nodes.metalgovde.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.metalgovde_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.metalgovde_2.geometry} />
      </group>
      <group position={[-595.53, 109.57, 308.1]} rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Cube2_31.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Cube2_31_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Red} geometry={nodes.Cube2_31_2.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.Cube2_31_3.geometry} />
      </group>
      <group position={[-815.86, 107.18, 299.92]} rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Cube3_18.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Cube3_18_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.Cube3_18_2.geometry} />
        <mesh castShadow receiveShadow  material={materials.Red} geometry={nodes.Cube3_18_3.geometry} />
      </group>
      <group position={[-487.44, 107.2, 307.36]} rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.cylinder2_3.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.cylinder2_3_1.geometry} />
        <mesh castShadow receiveShadow  material={materials['Main Color']} geometry={nodes.cylinder2_3_2.geometry} />
        <mesh castShadow receiveShadow  material={materials.Red} geometry={nodes.cylinder2_3_3.geometry} />
      </group>
      <group position={[-697.82, 109.44, 308]} rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Cube4_18.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Cube4_18_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Red} geometry={nodes.Cube4_18_2.geometry} />
      </group>
      <mesh castShadow receiveShadow 
        material={materials.Metal}
        geometry={nodes.shelves_3.geometry}
        position={[-467.84, 200.34, -344.46]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <group position={[-485.92, 114.84, -22.78]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Cube2_51.geometry} />
        <mesh castShadow receiveShadow  material={materials.Brown} geometry={nodes.Cube2_51_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Meat} geometry={nodes.Cube2_51_2.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Cube2_51_3.geometry} />
      </group>
      <group position={[-350.41, 54.97, -420.06]} rotation={[Math.PI / 2, 0, 0.2]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Cylinder3_11.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Cylinder3_11_1.geometry} />
      </group>
      <group position={[-667.35, 352.02, 304.34]} rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Cube_125.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Cube_125_1.geometry} />
      </group>
      <group position={[-675.23, 48.77, -328.68]} rotation={[Math.PI / 2, 0, 0]} scale={[2, 1.72, 2.24]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes['refrigerator(obj)001'].geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes['refrigerator(obj)001_1'].geometry} />
      </group>
      <mesh castShadow receiveShadow 
        material={materials.Metal}
        geometry={nodes.Pot001.geometry}
        position={[-483.58, 149.14, 321.12]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh castShadow receiveShadow 
        material={materials.Metal}
        geometry={nodes.Pot002.geometry}
        position={[-559.88, 153.51, 290.28]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <group position={[-88.82, 40.3, -437.8]} rotation={[Math.PI / 2, 0, -1.58]} scale={[1.76, 1.76, 1.76]}>
        <mesh castShadow receiveShadow  material={materials.lockers} geometry={nodes.Box003001.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Box003001_1.geometry} />
      </group>
      <group position={[-106.55, 40.3, -437.8]} rotation={[Math.PI / 2, 0, -1.58]} scale={[1.76, 1.76, 1.76]}>
        <mesh castShadow receiveShadow  material={materials.lockers} geometry={nodes.Box005001.geometry} />
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.Box005001_1.geometry} />
      </group>
      <group position={[18.59, 39.17, -330.91]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.ChamferBox007001.geometry} />
        <mesh castShadow receiveShadow  material={materials.Brown} geometry={nodes.ChamferBox007001_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Hanger000.geometry} position={[0.13, 46.12, -193.5]} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Hanger001.geometry} position={[2.98, 17.84, -195.51]} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Hanger002.geometry} position={[0.13, -11.01, -193.5]} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Hanger003.geometry} position={[0.13, -39.58, -193.5]} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.Hanger004.geometry} position={[3.17, -68.46, -193.82]} />
        <mesh castShadow receiveShadow  material={materials['Shirt.004']} geometry={nodes.Shirt000.geometry} position={[-0.17, 49.01, -141.52]} />
        <mesh castShadow receiveShadow  material={materials['Shirt.003']} geometry={nodes.Shirt001.geometry} position={[0.18, 20.81, -141.39]} />
        <mesh castShadow receiveShadow  material={materials['Shirt.002']} geometry={nodes.Shirt002.geometry} position={[0.13, -7.63, -140.64]} />
        <mesh castShadow receiveShadow 
          material={materials['Shirt.001']}
          geometry={nodes.Shirt003.geometry}
          position={[-0.43, -36.08, -139.55]}
        />
        <mesh castShadow receiveShadow 
          material={materials['Shirt.000']}
          geometry={nodes.Shirt004.geometry}
          position={[-1.23, -64.43, -138.37]}
        />
      </group>
      <group position={[-6.55, 115.68, 241.15]} rotation={[Math.PI, -1.35, Math.PI]} scale={[78.51, 78.51, 78.51]}>
        <mesh castShadow receiveShadow  material={materials.Black} geometry={nodes.POS_Terminal_1.geometry} />
        <mesh castShadow receiveShadow  material={materials.Mini_Display} geometry={nodes.POS_Terminal_2.geometry} />
        <mesh castShadow receiveShadow  material={materials.Paper} geometry={nodes.POS_Terminal_3.geometry} />
        <mesh castShadow receiveShadow  material={materials.Metal} geometry={nodes.POS_Terminal_4.geometry} />
        <mesh castShadow receiveShadow  material={materials.Display} geometry={nodes.POS_Terminal_5.geometry} />
      </group>
    </group>
  )
}

useGLTF.preload(qsr)
